import pageLayoutDefault from '@/components/page/layout/page-layout-default.vue'
import pageLayoutPersonal from '@/components/page/layout/page-layout-personal.vue'
import loader from '@/components/layout/layout-loader.vue'
import loaderItem from '@/components/layout/layout-loader-item.vue'
import pageLayoutMenu from '@/components/page/layout/page-layout-menu.vue'
import layoutProductCard from '@/components/layout/product/layout-product-card'
import uiButton from '@/components/ui/ui-button'
import layoutFooter from '@/components/layout/layout-footer'
import layoutHeaderButton from '@/components/layout/layout-header-button'
import layoutFooterButton from '@/components/layout/layout-footer-button'
import layoutGlobalMenuRow from '@/components/layout/global-menu/layout-global-menu-row'
import layoutGlobalMenuColumn from '@/components/layout/global-menu/layout-global-menu-column'
import layoutGlobalMenu from '@/components/layout/global-menu/layout-global-menu'
import layoutGlobalMenuMobile from '@/components/layout/global-menu/layout-global-menu-mobile'
import layoutContactBlockRow from '@/components/layout/layout-contact-block-row'
import uiTextureCheckbox from '@/components/ui/ui-texture-checkbox'
import uiRichColorChoicer from '@/components/ui/ui-rich-color-choicer'
import uiRichSelect from '@/components/ui/ui-rich-select'
import uiAuthView from '@/components/ui/ui-auth-view'

const components = Vue => {
  Vue.component('page-layout-default', pageLayoutDefault)
  Vue.component('page-layout-personal', pageLayoutPersonal)
  Vue.component('layout-loader', loader)
  Vue.component('layout-loader-item', loaderItem)
  Vue.component('page-layout-menu', pageLayoutMenu)
  Vue.component('layout-product-card', layoutProductCard)
  Vue.component('ui-button', uiButton)
  Vue.component('layout-footer', layoutFooter)
  Vue.component('layout-header-button', layoutHeaderButton)
  Vue.component('layout-footer-button', layoutFooterButton)
  Vue.component('layout-global-menu-row', layoutGlobalMenuRow)
  Vue.component('layout-global-menu-column', layoutGlobalMenuColumn)
  Vue.component('layout-global-menu', layoutGlobalMenu)
  Vue.component('layout-global-menu-mobile', layoutGlobalMenuMobile)
  Vue.component('layout-contact-block-row', layoutContactBlockRow)
  Vue.component('ui-texture-checkbox', uiTextureCheckbox)
  Vue.component('ui-rich-color-choicer', uiRichColorChoicer)
  Vue.component('ui-rich-select', uiRichSelect)
  Vue.component('ui-auth-view', uiAuthView)
}
export default components
