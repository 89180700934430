<template>
  <transition v-if="width<768" enter-active-class="animated fadeInDown" leave-active-class="animated fadeOutUp">
    <div v-click-outside="close" v-if="opened" class="layout-global-menu-mobile abs">
      <div class="container">
        <div class="header">
          <div class="middle-title">
            <div class="tabs">
              <div :class="[{ active: 0 === current }]" class="tab xs-title" @click="current = 0">
                {{  this.$store.state.langs.SHOP }}
              </div>
              <div :class="[{ active: 1 === current }]" class="tab xs-title" @click="current = 1">
                {{  this.$store.state.langs.ABOUT }}
              </div>
            </div>
          </div>
          <ui-lang-choice class="lang" :titleshow="true"></ui-lang-choice>

          <span class="link_favorite">
            <layout-header-button type="favorite" :href="sitedir+this.$store.state.langs.LINK_FAVORITE" :unhref="true" :count="countfavorite" icon="heart.svg"></layout-header-button>
          </span>

          <div class="close" @click="close">
            <img src="@/assets/icons/close.svg" alt="close" />
          </div>
        </div>
        <div class="columns">
          <div v-show="0 === current" class="tab-content shopstab">
            <div class="left">
              <div class="menu abs">
                <vuescroll :ops="ops">
                  <ul>
                    <li class="menuCatalog" v-for="(menucatalog, index) in menuCatalog" :key="index">
                    <span v-if="menucatalog.SUB" class="title" @click="openSub('sub'+index)">
                      <span class="imglink">
                        <img :src="menucatalog.IMAGES_LINK" :alt="menucatalog.NAME"/>
                      </span>
                      <span>
                        {{ menucatalog.NAME }}
                      </span>
                    </span>
                    <a class="title" v-else-if="menucatalog.UF_LINK" :title="menucatalog.NAME" :href="sitedir+menucatalog.UF_LINK">
                      <span class="imglink">
                        <img :src="menucatalog.IMAGES_LINK" :alt="menucatalog.NAME"/>
                      </span>
                      <span>
                        {{ menucatalog.NAME }}
                      </span>
                    </a>
                    <img v-if="menucatalog.SUB" @click="openSub('sub'+index)" :src="require(`@/assets/icons/next.svg`)" alt="next" />
                    <div class="sub" :class="'sub'+index">
                      <vuescroll :ops="ops">
                        <div class="subhead" :style="'background: url('+menucatalog.IMAGES_SECTION+')' ">
                          <div class="subhead-close" @click="closeSub">
                            <img class="menuprev" :src="require(`@/assets/icons/next.svg`)" alt="prev" />
                            <span>
                              {{ langmenu }}
                            </span>
                          </div>
                          <div v-if="menucatalog.UF_LINK" class="subhead-bottom">
                            <a :href="url(menucatalog.UF_LINK)"> All {{ menucatalog.NAME }}</a>
                          </div>
                          <div v-else class="subhead-bottom">{{ menucatalog.NAME }}</div>
                        </div>
                        <div class="subcontent">
                          <ul>
                            <li class="menuSub" v-for="(menusub) in menucatalog.SUB">
                              <a v-if="menusub.UF_LINK && menusub.UF_LINK!=''" class="menuSub-title"
                                 :href="sitedir+menusub.UF_LINK" :title="menusub.NAME">
                                {{menusub.NAME}}
                              </a>
                              <span v-else class="menuSub-title">{{menusub.NAME}}</span>
                              <a class="menuSub-link" :href="sitedir+subelement.PROPERTY_LINK_VALUE" :title="subelement.NAME" v-for="(subelement) in menusub.ELEMENT">
                                {{subelement.NAME}}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </vuescroll>
                    </div>
                    </li>
                  </ul>
                </vuescroll>
              </div>
            </div>
          </div>
          <div v-show="1 === current" class="tab-content abouttab">
            <div class="left">
              <div class="menu abs">
                <vuescroll :ops="ops">
                  <layout-global-menu-row
                          v-for="(menu, index) in menus"
                          :key="index"
                  >
                    <layout-global-menu-column
                            v-for="(items, index) in menu.SUB"
                            :key="index"
                            :full-width="menu.SUB.length==1"
                    >
                      <a v-if="items.UF_LINK" :title="items.UF_NAME_LINK" slot="title" :href="url(items.UF_LINK)">{{ items.UF_NAME_LINK }}</a>
                      <span v-else :title="items.UF_NAME_LINK" slot="title">{{ items.UF_NAME_LINK }}</span>
                      <a title="" v-for="(item, index) in items.ELEMENTS"
                         :key="index"
                         :href="url(item.PROPERTY_LINK_VALUE)">
                        {{ item.NAME }}
                      </a>
                    </layout-global-menu-column>
                  </layout-global-menu-row>
                  <slot name="menu"></slot>
                </vuescroll>
              </div>
            </div>
            <div class="right">
              <div class="contacts">
                <layout-contact-block-row no-row name="phone">
                  <a title="" :href="'tel:'+this.$store.state.langs.PHONE">
                    <b>
                      {{ this.$store.state.langs.PHONE }}
                    </b>
                  </a>
                </layout-contact-block-row>
                <layout-contact-block-row no-row name="email">
                  <b>
                    <a title="" :href="this.$store.state.langs.EMAIL">
                      {{ this.$store.state.langs.EMAIL }}
                    </a>
                  </b>
                </layout-contact-block-row>
                <layout-contact-block-row no-row name="address">
                  <b>
                    {{ this.$store.state.langs.ADRESS }}
                  </b>
                  <div v-html="this.$store.state.langs.ADRESS_DOP"></div>
                </layout-contact-block-row>
              </div>
              <div class="socials">
                <a title="" :href="this.$store.state.langs.LINK_WHATSAPP" v-if="this.$store.state.langs.LINK_WHATSAPP && this.$store.state.langs.LINK_WHATSAPP != '' && this.$store.state.langs.LINK_WHATSAPP != '/'"><img src="/images/icons/whatsapp.svg" alt="whatsapp"></a>
                <a title="" :href="this.$store.state.langs.LINK_MESSENGER" v-if="this.$store.state.langs.LINK_MESSENGER && this.$store.state.langs.LINK_MESSENGER != '' && this.$store.state.langs.LINK_MESSENGER != '/'"><img src="/images/icons/messenger.svg" alt="messenger"></a>
                <a title="" :href="this.$store.state.langs.LINK_INSTAGRAN" v-if="this.$store.state.langs.LINK_INSTAGRAN && this.$store.state.langs.LINK_INSTAGRAN != '' && this.$store.state.langs.LINK_INSTAGRAN != '/'"><img src="/images/icons/instagram.svg" alt="instagram"></a>
                <a title="" :href="this.$store.state.langs.LINK_TELEGRAM" v-if="this.$store.state.langs.LINK_TELEGRAM && this.$store.state.langs.LINK_TELEGRAM != '' && this.$store.state.langs.LINK_TELEGRAM != '/'"><img src="/images/icons/telegram.svg" alt="telegram"></a>
                <a title="" :href="this.$store.state.langs.LINK_FACEBOOK" v-if="this.$store.state.langs.LINK_FACEBOOK && this.$store.state.langs.LINK_FACEBOOK != '' && this.$store.state.langs.LINK_FACEBOOK != '/'"><img src="/images/icons/facebook.svg" alt="Facebook"></a>
                <a title="" :href="this.$store.state.langs.LINK_PINTRREST" v-if="this.$store.state.langs.LINK_PINTRREST && this.$store.state.langs.LINK_PINTRREST != '' && this.$store.state.langs.LINK_PINTRREST != '/'"><img src="/images/icons/pinterest.svg" alt="Pinterest"></a>
                <a title="" :href="this.$store.state.langs.LINK_YOUTUBE" v-if="this.$store.state.langs.LINK_YOUTUBE && this.$store.state.langs.LINK_YOUTUBE != '' && this.$store.state.langs.LINK_YOUTUBE != '/'"><img src="/images/icons/youtube.svg" alt="YouTube"></a>
              </div>
              <ui-feedback>
                <template slot="button">
                  <ui-button fill>
                    {{ this.$store.state.langs.NAME_LINK_CONTACTS }}
                  </ui-button>
                </template>
              </ui-feedback>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
    import vuescroll from 'vuescroll/dist/vuescroll-native'
    import uiLangChoice from '@/components/ui/ui-lang-choice'
    import ClickOutside from 'vue-click-outside'
    import layoutGlobalMenuRow from '@/components/layout/global-menu/layout-global-menu-row'
    import layoutGlobalMenuColumn from '@/components/layout/global-menu/layout-global-menu-column'
    import layoutContactBlockRow from '@/components/layout/layout-contact-block-row'
    import uiButton from '@/components/ui/ui-button'
    import uiFeedback from '@/components/ui/ui-feedback'
    export default {
        components: {
            vuescroll,
            uiLangChoice,
            ClickOutside,
            layoutGlobalMenuRow,
            layoutGlobalMenuColumn,
            layoutContactBlockRow,
            uiButton,
            uiFeedback
        },
        props: {},
        data() {
            return {
                current: 0,
                sitedir: '',
                siteid: '',
                langmenu: '',
                opened: false,
                ops: {
                    vuescroll: {},
                    scrollPanel: {},
                    rail: {
                        background: '#000',
                        opacity: 0.1,
                        size: '3px',
                        specifyBorderRadius: '0px'
                    },
                    bar: {
                        background: '#000',
                        opacity: 1,
                        size: '3px'
                    }
                },
                menus: {},
                menuCatalog: {},
                countfavorite: 0,
                width: window.innerWidth
            }
        },
        created() {
            this.$bus.$on('top-menus', (data) => {
                this.menuCatalog = data;
            });
            if(this.width<768) {
                this.menusRequest();
            }

            //this.menuCatalog = responseMenu.data
            //this.menuCatalog = this.globalmenutop;
           // this.menuTop()
            this.$bus.$on('global-menu-mobile', (status = true) => {
                this.opened = status
                document.body.style.overflow = 'hidden'
            })
            this.langmenu = this.$store.state.langs.MENU;
            this.siteid = this.$store.state.langs.SITE_ID;
            var cookieFavorite = this.$cookie.get('favorites');
            if( cookieFavorite ){
                var favoriteJson = JSON.parse(cookieFavorite);
                if( favoriteJson[this.siteid] ){
                    for (var key in favoriteJson[this.siteid]) {
                        this.countfavorite = this.countfavorite + favoriteJson[this.siteid][key].length;
                    }
                }
            }
            this.$bus.$on('favorite', (cuntFavorites) => {
                this.countfavorite = cuntFavorites
            });
            window.addEventListener('resize', this.updateWidth);
        },
        methods: {
            async menusRequest() {
                const responseMenus  = (
                    await this.$axios({
                        url: '/ajax/global-menu.php',
                        method: 'post',
                        params: {
                            clear_cache: this.getGet('clear_cache'),
                            SITE_ID: this.$store.state.langs.SITE_ID
                        }
                    })
                )
                if (responseMenus) {
                    this.menus = responseMenus.data
                } else {
                    console.log('responseMenus')
                }
            },
            /* async menuTop() {
                const responseMenu  = (
                    await this.$axios({
                        url: '/ajax/menu.php',
                        method: 'post',
                        params: {
                            clear_cache: this.getGet('clear_cache'),
                            SITE_ID: this.$store.state.langs.SITE_ID
                        }
                    })
                )
                if (responseMenu) {
                    this.menuCatalog = responseMenu.data
                } else {
                    console.log('menuTop')
                }
            },*/
            getGet(name) {
                var searchString = new URLSearchParams(window.location.search);
                var res = searchString.get(name);
                return res;
            },
            close() {
                if( this.opened ) {
                    this.opened = false
                    document.body.style.overflow = 'auto'
                    this.$bus.$emit('menuOpened')
                }
            },
            url(el){
                return this.$store.state.langs.SITE_DIR + el
            },
            openSub(el){
                this.$el.querySelector('.'+el).classList.add("active");
            },
            closeSub(){
                this.$el.querySelectorAll('.sub').forEach(n => n.classList.remove('active'));
            },
            updateWidth() {
                this.width = window.innerWidth;
            }
        },
        mounted () {
            this.sitedir = this.$store.state.langs.SITE_DIR;
            this.popupItem = this.$el;
        },
        directives: {
            ClickOutside
        }
    }
</script>

<style lang="scss" scoped>
  .layout-global-menu-mobile {
    position: fixed;
    z-index: 501;
    background: #fff;
    overflow: hidden;
    display: flex;
    padding-top: 30px;
    height: 100%;
    top: 0;
    .link_favorite{
      margin-right: 8px;
    }
  }
  .tabs {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    font-size: 21px;
    .tab {
      margin-right: 1.5em;
      transition: 0.5s;
      opacity: 0.3;
      cursor: pointer;
      &.active {
        opacity: 1;
      }
    }
  }
  .container {
    display: flex;
    flex-direction: column;
  }
  .columns {
    flex-grow: 1;
    display: block;
    overflow: scroll;
    grid-template-columns: auto 21em;
    grid-gap: 0 7em;
    & > * {
      overflow: hidden;
      position: relative;
    }
  }
  .menu {
    overflow: scroll;
    position: relative;
    left: auto;
    top: auto;
    width: auto;
    height: auto;
  }
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 2em;
    grid-column: span 2;
    .close {
      margin-left: 1em;
      cursor: pointer;
    }
  }
  .contacts {
    & > * {
      margin-bottom: 2em !important;
    }
  }
  .socials {
    margin-bottom: 2em;
    & > * {
      margin-right: 3em;
    }
    img {
      width: 15px;
      height: 15px;
    }
  }
  .button {
    .ui-button {
      width: 15em;
    }
  }
  .lang {
      display: flex;
    }
  .menuCatalog{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    height: 58px;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
  .title{
    font-size: 15px;
    text-transform: uppercase;
    display: flex;
    letter-spacing: 0.1em;
    width: 100%;
    .imglink{
      width: 65px;
      text-align: center;
    }
  }
  .sub{
    display: block;
    position: fixed;
    top: 0;
    left: 100%;
    right: 0;
    height: 100%;
    background: #fff;
    z-index: 5;
    transition: left .4s linear;
  }
  .sub.active{
    left: 0;
  }
  .subhead{
    height: 240px;
    width: 100%;
    padding: 40px 15px;
    font-size: 14px;
    text-transform: uppercase;
    position: relative;
    .subhead-bottom{
      position: absolute;
      bottom: 15px;
      left: 0;
      padding: 0 15px;
      font-size: 21px;
      text-transform: uppercase;
    }
    .subhead-close{
      display: flex;
      align-items: center;
      img{
        transform: rotate(180deg);
        margin-right: 10px;
      }
    }
  }
  .subcontent{
    padding: 10px 15px;
    .menuSub{
      list-style: none;
      margin-bottom: 40px;
      .menuSub-title{
        font-size: 15px;
        text-transform: uppercase;
      }
      .menuSub-link{
        display: block;
        margin: 5px 0;
        font-size: 15px;
        opacity: 0.7;
        &:hover{
          opacity: 1;
        }
      }
    }
  }
  .close {
      position: absolute;
      top: -20px;
      right: 10px;
    z-index: 10;
    }
  .header {
      justify-content: space-between;
    }

  @media screen and (max-width: 767px) {
    .menuSub-title {
      margin-bottom: 10px;
      display: inline-block;
    }
    .subcontent .menuSub .menuSub-link{
      margin-left: 10px;
    }
  }
</style>
