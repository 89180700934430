<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform" xmlns:v-touch="http://www.w3.org/1999/xhtml">
  <div class="layout-product-gallery">
    <div class="hidden abs">
      <link itemprop="image" :href="imgdetail" />
      <img itemscope="image" :src="imgdetail" :alt="code" />
      <img
              v-if="index"
              v-for="(photo, index) in morephoto"
              :key="index"
              :src="photo.SRC" :alt="code+'-mini-'+index" />
      <link
              v-if="index"
              v-for="(photo, index) in morephoto"
              :key="index"
              :src="photo.SRC"
              itemprop="image" :href="photo.SRC" />
    </div>
    <div class="gallery">
      <div class="minies">
        <div class="vuebar-element" v-bar>
            <div>
              <div v-if="morephoto.length>1"
                      v-for="(mini, index) in morephoto"
                      :key="index"
                      :class="[{ active: index === current }]"
                      class="mini"
                      @click="current = index"
              >
                <div class="dot"></div>
              </div>
            </div>
        </div>
      </div>

      <div class="photo">
        <div
          :style="{
            transform: `translate3d(calc(100% * ${current} * -1),0,0)`
          }"
          class="previews"
          v-touch:swipe="swipeHandler"
        >
          <div :key="0" class="preview">
            <img class="abs" :src="imgdetail" :alt="code+'_0'" />
          </div>
          <div v-for="(item, index) in morephoto" :key="index+1" class="preview">
            <img class="abs" :src="item.SRC" :alt="code+'_'+index+1" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    components: {},
    props: {
        code: "",
        imgdetail: "",
        morephoto: []
    },
  data() {
    return {
      current: 0,
      //items: [],
        window: {
            width: 0,
            height: 0
        }
    }
  },
  mounted() {
    //this.init()
    this.initSwiper()
  },
  methods: {
    init() {
      //const items = this.$el.querySelectorAll('.hidden > *');
      //this.items = items
    },
    initSwiper() {
      const Hammer = require('hammerjs')
      const stage = this.$el
      const hammertime = new Hammer(stage)
      hammertime.on('swipeleft', ev => {
        this.next()
      })
      hammertime.on('swiperight', ev => {
        this.prev()
      })
    },
    next() {
      if (this.current !== this.morephoto.length - 1) {
        this.current++
      } else {
        this.current = 0
      }
    },
    prev() {
      if (this.current !== 0) {
        this.current--
      } else {
        this.current = this.morephoto.length - 1
      }
    },
    handleResize() {
        this.window.width = window.innerWidth;
        this.window.height = window.innerHeight;
    },
    swipeHandler (direction) {
        if(direction == "left"){
            this.next()
        }
        if(direction == "right"){
            this.prev()
        }
        // May be left / right / top / bottom
    },
  },
  created() {
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
  },
  destroyed() {
      window.removeEventListener('resize', this.handleResize);
  },
  watch:{
      morephoto(){
          this.current = 0;
      }
  }
}
</script>

<style lang="scss" scoped>
.layout-product-gallery {
  position: relative;
}
.hidden {
  display: none;
}
.gallery {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
}
.minies{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 8px;
  height: 15px;
  padding: 5px 0;
  z-index: 10;
}
.mini {
  height: 10px;
  width: 13px;
  display: inline-block;
  margin-bottom: 0;
  flex-flow: wrap;
  --height: 7em;
  position: relative;
  transition: 0.5s;
  cursor: pointer;
  opacity: 0.4;
  img {
    object-fit: contain;
  }
  &.active {
    opacity: 1;
  }
}
.photo {
  --height: 18em;
  overflow: hidden;
  height: var(--height);
}
.previews {
  transition: 1s;
  display: grid;
  grid-auto-columns: 100%;
  grid-auto-flow: column;
}
.preview {
  position: relative;
  height: var(--height);
  img {
    object-fit: contain;
  }
}
.bgmask{
  position: absolute;
  background: rgba(0,0,0,0.1);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.dot{
  width: 4px;
  height: 4px;
  background: black;
  border-radius: 50%;
  margin: 0 4px;
  transition: .4s;
  cursor: pointer;
}
.vb-content{
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.vuebar-element {
  width: 100%;
  max-width: 250px;
}
.vb > .vb-dragger {
  z-index: 5;
  width: 12px;
  right: 0;
}

.vb > .vb-dragger > .vb-dragger-styler {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: rotate3d(0,0,0,0);
  transform: rotate3d(0,0,0,0);
  -webkit-transition:
          background-color 100ms ease-out,
          margin 100ms ease-out,
          height 100ms ease-out;
  transition:
          background-color 100ms ease-out,
          margin 100ms ease-out,
          height 100ms ease-out;
  background-color: rgba(129, 129, 129, 0.1);
  margin: 5px 5px 5px 0;
  border-radius: 20px;
  height: calc(100% - 10px);
  display: block;
}

.vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(129, 129, 129,.3);
}

.vb > .vb-dragger:hover > .vb-dragger-styler {
  background-color: rgba(129, 129, 129,.5);
  margin: 0px;
  height: 100%;
}

.vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(129, 129, 129, 0);
  margin: 0px;
  height: 100%;
}

.vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(129, 129, 129,.5);
}
@media screen and (max-width: 767px) {
  .photo {
    --height: 12em;
  }
}
</style>