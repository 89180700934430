<template>
  <transition name="slide-fade">
    <div v-if="value" class="layout-content-window abs">
      <div class="abs" @click="close"></div>
      <div class="window">
        <div class="header">
          <div class="small-title">{{ title }}</div>
          <div class="close" @click="close">
            <img src="@/assets/icons/close.svg" alt="close" />
          </div>
        </div>
        <div class="content">

          <div class="content-wrapper">
            <vuescroll :ops="ops">
              <div class="colors">
                <div class="item" :class="[{ active: activeProduct(offerIdCurrent, product) }]" :title="product.NAME" v-for="(product) in productDop">
                  <div class="color">
                    <img v-if="product.DETAIL_PICTURE && product.DETAIL_PICTURE.SRC" v-lazy="product.DETAIL_PICTURE.SRC" :alt="product.NAME" />
                  </div>
                  <template v-if="offer.PROPERTIES.SIZE && offer.PROPERTIES.SIZE.VALUE" v-for="(offer, i) in product.OFFERS">
                    <label v-if="selected_size==offer.PROPERTIES.SIZE.VALUE">
                      <input v-model="offerIdCurrent" type="radio" :name="selected_size" @click="" :value="offer.ID" :id="'offer'+i">
                      <div class="small-text">{{ offer.NAME }}</div>
                    </label>
                    <div class="price"  v-if="selected_size==offer.PROPERTIES.SIZE.VALUE" v-html="formatPrice(offer.MIN_PRICE.DISCOUNT_VALUE)"></div>
                  </template>

                  <template v-else>
                    <label>
                      <input v-model="offerIdCurrent" type="radio" :name="selected_size" @click="" :value="product.OFFERS[0].ID" :id="'offer'+product.OFFERS[0].ID">
                      <div class="small-text">{{ product.OFFERS[0].NAME }}</div>
                    </label>
                    <div class="price" v-html="formatPrice(product.OFFERS[0].MIN_PRICE.DISCOUNT_VALUE)"></div>
                  </template>
                </div>
              </div>
            </vuescroll>
          </div>

          <!--  -->
          <ui-button fill @click="apply">{{ this.$store.state.langs.APPLY }}</ui-button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
    import vuescroll from 'vuescroll/dist/vuescroll-native'
export default {
    components: {
        vuescroll
    },
  props: {
    title: {
      type: String,
      default() {
        return ''
      }
    },
    value: {
      type: Boolean,
      default() {
        return false
      }
    },
      offerIdCurrent: null,
      selected_size: null,
      propJs: ""
  },
  data() {
      return {
          loading: false,
          productDop: this.$parent.eventProductDop(),
          current: {}
      }
  },
  methods: {
    close() {
      this.loaderView = false;
      this.$emit('input', false)
    },
    apply() {
      this.$parent.currentItemDop(this.propJs, this.offerIdCurrent);
      this.$emit('input', false)
    },
    formatPrice(price) {
        if(price) {
            return this.$store.state.langs.CURRENCY_SING + " " +Math.ceil(Number(price)).toLocaleString();
        }
    },
      activeProduct(id, product) {
          if(id) {
              return product.OFFERS.some(c => c.ID == id);
          }else{
              return false
          }
      }
  },
  created(){},
  mounted(){},
  computed: {
        //foundColor() {
        //  return this.materialsOrigin.find(c => c.ID === this.current.ID)
        //}
    },
  watch: {
      value: function (newValue, oldValue) {
          if(newValue){
              this.loaderView = true;
              //this.requestProducts();
          }else{
              //this.products = {};
          }
      }
  }
}
</script>

<style lang="scss" scoped>
.layout-content-window {
  position: fixed;
  z-index: 999;
}
.window {
  background: #ffffff;
  box-shadow: -170px 4px 124px rgba(0, 0, 0, 0.08);
  width: 32em;
  max-width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: 2.5em;
  display: flex;
  flex-direction: column;
  & > .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    & > .ui-button {
      margin-top: auto;
      align-self: flex-start;
      width: 190px;
    }
  }
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .close {
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      transform: rotate(90deg);
    }
  }
}

.content-wrapper {
  margin-top: 1em;
  height: 35em;
  .colors {
    display: grid;
    /*grid-template-columns: repeat(4, 1fr);*/
    grid-template-columns: repeat(auto-fit, 12em);
    grid-gap: 3em;
    .item{
      padding: 5px 20px 30px;
      &.active{
        border: 1px solid rgba(0,0,0,0.1);
        box-sizing: border-box;
      }
      .color{
        height: 7em;
      }
      label{
        display: grid;
        grid-template-columns: min-content auto;
        grid-gap: 1em;
        align-items: center;
      }
      .price{
        padding-left: 33px;
      }
    }
  }
}

.slide-fade-enter-active {
  transition: all 0.2s cubic-bezier(1, 0.8, 0.5, 1);
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
@media screen and (max-width: 767px) {
  .window {
    width: 100%;
    padding: 2.5em 10px;
  }
  .content-wrapper
    .colors {
      grid-gap: 1em;
  }
}
</style>
