<template>
  <transition name="slide-fade">
    <div v-if="value" class="layout-filter-window abs">
      <div class="abs" @click="close"></div>
      <div class="window">
        <div class="header">
          <div class="small-title">{{ title }}</div>
          <div class="close" @click="close">
            <img src="@/assets/icons/close.svg" alt="close" />
          </div>
        </div>
        <div class="content">

          <div class="current-color">
            <div class="big-color color" v-viewer="viewerOptions">
                  <img :data-href="current.DETAIL_PICTURE.SRC" :title="current.NAME_OFFER" :alt="current.NAME_OFFER" :src="current.DETAIL_PICTURE_SMALL.SRC" />
            </div>
            <div class="current-content">
              <div class="xxs-title">{{ current.NAME }}</div>
              <div class="xs-title">{{ current.NAME_OFFER }}</div>
              <!--<div class="caption">Features: {{ foundColor.tags.join(', ') }}</div>-->
              <div class="caption">{{ current.CATEGORY }} {{ current.SOSTAV_TKANI }}</div>
            </div>
          </div>

          <layout-loader class="abs" v-show="loading"/>
          <div class="filters-wrapper">
            <div class="xxs-title">
              {{ this.$store.state.langs.FILTERES_BY }}
            </div>
            <div class="filters">
              {{ /* Моя заготовка взята с layout category page */ }}
              <!--<multiselects
                      select-Label=" "
                      selected-Label=" "
                      deselect-Label=" "
                      v-model="value[item.ID]"
                      :options="item.VALUES"
                      :searchable="false"
                      :custom-label="customLabel"
                      :multiple="true"
                      :close-on-select="false"
                      :show-labels="true"
                      :placeholder="item.NAME"
                      track-by="VALUE"
                      @remove=onRemove($event)
              >
                  <label slot="option" slot-scope="scope" :for="scope.option.CONTROL_ID" class="">
                      <input
                              :disabled="scope.option.DISABLED ? true : false"
                              :checked="scope.option.CHECKED ? true : false"
                              :data-code="item.CODE"
                              :data-control_id="scope.option.CONTROL_ID"
                              :data-url_id="scope.option.URL_ID"
                              :id="scope.option.CONTROL_ID"
                              :name="scope.option.CONTROL_NAME"
                              type="checkbox"
                              class="checkbox-event"
                              :value="scope.option.HTML_VALUE"
                              v-on:change="filterCheck('product')"
                      >
                      <span class="small-text" v-html="scope.option.VALUE"></span>
                  </label>
              </multiselects>-->

              <select v-model="selected_color" @change="onChangeSelectedColor()">
                <option :value="this.$store.state.langs.COLOR">{{ this.$store.state.langs.COLOR }}</option>
                <option class="colorItem" v-for="(prop) in allPropertiesArr" :value="prop">{{ prop }}</option>
              </select>
              <select v-model="selected_typefeatures" @change="onChangeSelectedColor()">
                <option :value="this.$store.state.langs.TYPE_FEATURES">{{ this.$store.state.langs.TYPE_FEATURES }}</option>
                <option class="typeItem" v-for="(tag) in allTagsArr" :value="tag">{{ tag }}</option>
              </select>
            </div>
          </div>
          <div class="colors-wrapper">
            <div class="xxs-title">{{ this.$store.state.langs.MAKE_YOR_CHOICE }}</div>
            <div class="colors-scroll-area">
              <vuescroll :ops="ops">
                <div class="colors">
                  <layout-loader class="abs" v-show="loaderView"/>
                  <div :title="material.CATEGORY+' '+material.NAME_OFFER" v-for="(material) in contentMaterial" @click="currentItem(material)">
                    <div class="color">
                      <img v-lazy="material.DETAIL_PICTURE.SRC" :alt="material.NAME" />
                    </div>
                    <div class="subTitle">{{ material.NAME_OFFER }}</div>
                  </div>
                </div>
              </vuescroll>
            </div>
          </div>
          <ui-button fill @click="apply">{{ this.$store.state.langs.APPLY }}</ui-button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
    import vuescroll from 'vuescroll/dist/vuescroll-native'
export default {
    components: {
        vuescroll
    },
  props: {
    current: {},
    allPropertiesArr: [],
    allTagsArr: [],
    selected_size: {
      type: String,
      default() {
        return ''
      }
    },
    title: {
      type: String,
      default() {
        return ''
      }
    },
    value: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
      return {
          viewerOptions: {
              movable: false,
              rotatable: false,
              scalable: false,
              url: 'data-href',
              title: [1, (image, imageData) => `${image.alt}`]
          },
          index: null,
          loadingColors: false,
          selected_color: this.$store.state.langs.COLOR,
          selected_typefeatures: this.$store.state.langs.TYPE_FEATURES,
          loaderView: false,
          contentMaterial: {}
      }
  },
  methods: {
    close() {
      this.loaderView = false;
      this.$emit('input', false)
    },
    apply() {
      this.$parent.currentItem(this.current);
      this.$parent.apply();
      this.$emit('input', false)
    },
    onChangeSelectedColor(){
          //Выбор цвета или тип ткани
          const colorArr = [];
          let flagMaterial=true;
          for (let item of this.$parent.material()) {
              if(item.COLOR == this.selected_color || item.TIP_TKANI == this.selected_typefeatures){
                  colorArr.push(item);
                  flagMaterial=false;
              }
          }
          this.contentMaterial = colorArr;
          if(flagMaterial){
              this.contentMaterial = this.$parent.material();
          }
      },
    currentItem(currentItem){
        this.current = currentItem;
    }
  },
  created(){},
  mounted(){},
  computed: {
      //foundColor() {
      //  return this.materialsOrigin.find(c => c.ID === this.current.ID)
      //}
  },
  watch: {
      value: function (newValue, oldValue) {
          if(newValue){
              this.loaderView = true;
              setTimeout(() => {
                  this.contentMaterial = this.$parent.material();
                  this.loaderView = false;
                  }, 500
              );
          }else{
              this.contentMaterial = {};
          }
      }
  }
}
</script>
<style lang="scss" scoped>
.layout-filter-window {
  position: fixed;
  z-index: 999;
}
.window {
  background: #ffffff;
  box-shadow: -170px 4px 124px rgba(0, 0, 0, 0.08);
  width: 30em;
  max-width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: 2.5em;
  display: flex;
  flex-direction: column;
  & > .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    & > .ui-button {
      margin-top: auto;
      align-self: flex-start;
      width: 190px;
    }
  }
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .close {
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      transform: rotate(90deg);
    }
  }
}

.color {
  width: 2em;
  height: 2em;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  /*margin-right: 0.5em;*/
  cursor: pointer;
  transition: 0.3s;
  &.active {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 1);
  }
  &:hover {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.3);
  }
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
    border-radius: inherit;
  }
}
.colors .subTitle{
  text-align: center;
  margin: 5px 0;
}
.filters-wrapper {
  margin-top: 2em;
}
.filters {
  margin-top: 0.5em;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.5em;
}
.colors-wrapper {
  margin-top: 3em;
}
.colors-scroll-area {
  margin-top: 1em;
  height: 21em;
  .colors {
    display: grid;
    /*grid-template-columns: repeat(4, 1fr);*/
    grid-template-columns: repeat(auto-fit, minmax(98px, 1fr));
    grid-gap: 1em;
  }
  .color {
    width: 4.5em;
    height: 4.5em;
  }
}
.big-color {
  width: 6.8em;
  height: 6.8em;
  border: 5px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.92);
}
.current-color {
  display: flex;
  align-items: center;
  margin-top: 2em;
  .big-color {
    margin-right: 1em;
    margin-left: 0;
  }
}
.current-content {
  width: 12.5em;
  max-width: 100%;
  .caption {
    margin-top: 1em;
    opacity: 0.4;
  }
}
.slide-fade-enter-active {
  transition: all 0.2s cubic-bezier(1, 0.8, 0.5, 1);
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
@media screen and (max-width: 767px) {
  .window {
    width: 100%;
  }
}
</style>