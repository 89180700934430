<template>
  <div v-if="value" class="layout-product-card-preview abs">
    <div class="overlay abs" @click="close"></div>
    <div class="window">
      <div class="container">
        <div class="buttons">
          <div class="open" @click="open(product.DETAIL_PAGE_URL)"><img src="@/assets/icons/out.svg" alt="out" /></div>
          <div class="close" @click="close"><img src="@/assets/icons/close.svg" alt="close" /></div>
        </div>

        <layout-product-data
                :product=product
                :propdetails=product.PROPERTY_DETAIL
                :sale="(product.hasOwnProperty('MIN_PRICE') && product.MIN_PRICE.hasOwnProperty('DISCOUNT_DIFF_PERCENT')) ? product.MIN_PRICE.DISCOUNT_DIFF_PERCENT : ''"
                stars="4"
        >
        </layout-product-data>
      </div>
    </div>
  </div>
</template>

<script>
import layoutProductData from '@/components/layout/product/layout-product-data'
export default {
  components: {
    layoutProductData
  },
  props: {
    product:{},
    value: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  methods: {
    close() {
      this.$emit('input', false)
    },
    open(url) {
        window.location = url
    },
  }
}
</script>

<style lang="scss" scoped>
.layout-product-card-preview {
  position: fixed;
  z-index: 502;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0em 15px;
  .layout-product-data {
    padding-bottom: 0;
  }
}
.overlay {
  background: rgba(0, 0, 0, 0.1);
}
.window {
  background: #fff;
  position: relative;
  z-index: 1;
  max-height: 90%;
  overflow: scroll;
  padding: 1.5em;
}
.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & > * {
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 1em;
    }
  }
}
@media screen and (max-width: 767px) {
  .window {
    padding: 1.3em 0;
    max-height: 80vh;
  }
}
</style>
