import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        basketCount: 0,
        compareCount: 0,
        favoriteCount: 0,
        langs: window.__langs__,
        lang: 0,
        languages: window.__languages__,
        admin: window.__admin__,
        authorized: window.__authorized__
    },
    mutations: {},
    actions: {},
    modules: {}
})
