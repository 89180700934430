<template>
  <div class="ui-right-block">
    <div class="visible" @click="opened=true">
      <div class="">
          <div class="xxx-title" v-html="titleXxx()"></div>
          <div class="xx-title">{{ title }}</div>
      </div>
      <div>
          <span class="b-next"> &rsaquo; </span>
      </div>
    </div>

    <layout-content-window
            v-model="opened"
            :title="title"
            :offerIdCurrent="offerIdCurrent"
            :propJs="propJs"
            :selected_size="selected_size"
    ></layout-content-window>

  </div>
</template>

<script>
import vuescroll from 'vuescroll/dist/vuescroll-native'
import layoutContentWindow from '@/components/layout/layout-content-window'
export default {
    components: {
        vuescroll,
        layoutContentWindow
    },
    props: {
        productDop: null,
        selected_size: null,
        title: "",
        offerIdCurrent: null,
        propJs: ""
    },
    data() {
        return {
          opened: false,
          ops: {
            vuescroll: {},
            scrollPanel: {},
            rail: {
              background: '#000',
              opacity: 0.1,
              size: '3px',
              specifyBorderRadius: '0px'
            },
            bar: {
              background: '#000',
              opacity: 1,
              size: '3px'
            }
          },
        }
    },
    methods: {
        titleXxx(){
            let name = "";
            if(this.productDop && this.offerIdCurrent) {
                this.productDop.find(product => {
                    product.OFFERS.find(offer => {
                        if( offer.NAME && offer.ID === this.offerIdCurrent ) {
                            name = offer.NAME;
                            return;
                        }
                    });
                });
            }
            return name;
        },
        eventProductDop(){
            return this.productDop;
        },
        currentItemDop(propJs,offerId) {
            this.$parent.currentItemDop(propJs, offerId);
        }
    }
}
</script>

<style lang="scss" scoped>
.ui-right-block {
    margin-top: 0.5em;
    margin-bottom: 2em;
}
.visible {
    display: flex;
    align-items: center;
    height: 50px;
    justify-content: space-between;
    padding: 0 10px 0 0;
    cursor: pointer;
}
.xxx-title{
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    /* width: 100px; */
    height: 25px;
}
.b-next{
    font-size: 23px;
    font-weight: bold;
}
</style>
