<template>
  <div class="ui-feedback">
    <div class="button" @click="open">
      <slot name="button"></slot>
    </div>

    <!--  -->
    <transition enter-active-class="animated zoomIn" leave-active-class="animated zoomOut">
      <div v-if="opened" class="feedback-popup abs">
        <notifications group="cartcheckout" position="top left" animation-name="v-fade-left" width="100%" />
        <layout-loader v-show="loading"/>
        <img src="@/assets/icons/close.svg" class="close" alt="close" @click="close" />
        <form v-if="!success" class="feedback-form" @submit.prevent="submit">
          <input autofocus class="feedback-input--name" name="name" v-model="form.name" :placeholder="this.$store.state.langs.ENTER_YOUR_NAME" type="text" required />
          <input autofocus class="feedback-input--phone" name="phone" v-model="form.phone" :placeholder="this.$store.state.langs.ENTER_YOUR_PHONE" type="phone" required />
          <textarea :placeholder="this.$store.state.langs.ENTER_YOUR_TEXT" name="question" v-model="form.question"></textarea>
          <ui-button class="sendButtonFeedback" type="submit">
            {{ this.$store.state.langs.SEND }}
          </ui-button>
        </form>
        <div v-if="success" class="feedback-form" v-html="this.$store.state.langs.SUCCESS"></div>
      </div>
    </transition>
  </div>
</template>

<script>
    import uiButton from '@/components/ui/ui-button'
export default {
  components: {
      uiButton
  },
  props: {

  },
  data() {
    return {
      loading:false,
      opened: false,
      success: false,
      form: {
          name: "",
          phone: "",
          question: "",
          url: location.pathname,
          header: "feedback",
          successType: "feedback",
          feadback: "feedback"
      }
    }
  },
  methods: {
    open() {
      this.opened = true
      document.body.style.overflow = 'hidden'
    },
    close() {
      this.opened = false
      document.body.style.overflow = 'auto'
    },
      submit() {
          this.loading = true;
          this.$axios({
              url: '/ajax/index.php', method: 'post', params: this.form
          }).then(response => {
              if (response.status == 200) {
                  if(response.data.success){
                      this.success = true;
                  }else{
                      this.$notify({
                          group: 'cartcheckout',
                          title: 'error',
                          text: 'error',
                          type: 'error'
                      });
                  }
              }else{
                  this.$notify({
                      group: 'cartcheckout',
                      title: 'error',
                      text: 'error',
                      type: 'error'
                  });
              }
              this.loading = false;
          }).catch(e => {
              this.$notify({
                  group: 'cartcheckout',
                  title: 'error',
                  text: e,
                  type: 'error'
              });
              console.log('Error: ' + e)
          })
          this.loading = true;
      }
  },
    created(){
        this.$bus.$on("opensearch", ()=>{
            this.open();
        });
    }
}
</script>

<style lang="scss" scoped>
.ui-feedback {
  position: relative;
  .sendButtonFeedback{
    margin-top: 10px;
    width: 100%;
  }
}
form{
  width: 100%;
  max-width: 500px;
}
input {
  letter-spacing: .09em;
  border: none!important;
  border-bottom: 1px solid!important;
  border-radius: 0;
  max-width: 100%;
  margin-bottom: 10px;
}
.feedback-popup {
  background: rgba(255, 255, 255, 1);
  position: fixed;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
}
.close {
  width: 1.2em;
  height: 1.2em;
  position: absolute;
  right: 2em;
  top: 2em;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    transform: rotate(90deg);
  }
}
</style>
