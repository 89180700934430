<template>
  <transition v-if="width>767" enter-active-class="animated fadeInDown" leave-active-class="animated fadeOutUp">
    <div v-click-outside="close" v-if="opened" class="layout-global-menu abs">
      <div class="container">
        <div class="header">
          <div class="middle-title">
            {{  this.$store.state.langs.MENU }}
          </div>
          <ui-lang-choice class="lang"></ui-lang-choice>
          <div class="close" @click="close">
            <img src="@/assets/icons/close.svg" alt="close" />
          </div>
        </div>
        <div class="columns">
          <div class="left">
            <div class="menu abs">
              <vuescroll :ops="ops">
                <layout-global-menu-row
                        v-for="(menu, index) in menus"
                        :key="index"
                >
                  <layout-global-menu-column
                          v-for="(items, index) in menu.SUB"
                          :key="index"
                          :full-width="menu.SUB.length==1"
                  >
                    <a v-if="items.UF_LINK" :title="items.UF_NAME_LINK" slot="title" :href="url(items.UF_LINK)">{{ items.UF_NAME_LINK }}</a>
                    <span v-else :title="items.UF_NAME_LINK" slot="title">{{ items.UF_NAME_LINK }}</span>

                    <a title="" v-for="(item, index) in items.ELEMENTS"
                       :key="index"
                       :href="url(item.PROPERTY_LINK_VALUE)">
                      {{ item.NAME }}
                    </a>
                  </layout-global-menu-column>
                </layout-global-menu-row>
                <slot name="menu"></slot>
              </vuescroll>
            </div>
          </div>
          <div class="right">
            <div class="contacts">
              <layout-contact-block-row no-row name="phone">
                <a title="" :href="'tel:'+this.$store.state.langs.PHONE">
                  <b>
                    {{ this.$store.state.langs.PHONE }}
                  </b>
                </a>
              </layout-contact-block-row>
              <layout-contact-block-row no-row name="email">
                <b>
                  <a title="" :href="this.$store.state.langs.EMAIL">
                    {{ this.$store.state.langs.EMAIL.replace('mailto:', '') }}
                  </a>
                </b>
              </layout-contact-block-row>
              <layout-contact-block-row no-row name="address">
                <b>
                  {{ this.$store.state.langs.ADRESS }}
                </b>
                <div v-html="this.$store.state.langs.ADRESS_DOP"></div>
              </layout-contact-block-row>
            </div>
            <div class="socials">
              <a title="" :href="this.$store.state.langs.LINK_WHATSAPP" v-if="this.$store.state.langs.LINK_WHATSAPP && this.$store.state.langs.LINK_WHATSAPP != '' && this.$store.state.langs.LINK_WHATSAPP != '/'"><img src="/images/icons/whatsapp.svg" alt="whatsapp"></a>
              <a title="" :href="this.$store.state.langs.LINK_MESSENGER" v-if="this.$store.state.langs.LINK_MESSENGER && this.$store.state.langs.LINK_MESSENGER != '' && this.$store.state.langs.LINK_MESSENGER != '/'"><img src="/images/icons/messenger.svg" alt="messenger"></a>
              <a title="" :href="this.$store.state.langs.LINK_INSTAGRAN" v-if="this.$store.state.langs.LINK_INSTAGRAN && this.$store.state.langs.LINK_INSTAGRAN != '' && this.$store.state.langs.LINK_INSTAGRAN != '/'"><img src="/images/icons/instagram.svg" alt="instagram"></a>
              <a title="" :href="this.$store.state.langs.LINK_TELEGRAM" v-if="this.$store.state.langs.LINK_TELEGRAM && this.$store.state.langs.LINK_TELEGRAM != '' && this.$store.state.langs.LINK_TELEGRAM != '/'"><img src="/images/icons/telegram.svg" alt="telegram"></a>
              <a title="" :href="this.$store.state.langs.LINK_FACEBOOK" v-if="this.$store.state.langs.LINK_FACEBOOK && this.$store.state.langs.LINK_FACEBOOK != '' && this.$store.state.langs.LINK_FACEBOOK != '/'"><img src="/images/icons/facebook.svg" alt="Facebook"></a>
              <a title="" :href="this.$store.state.langs.LINK_PINTRREST" v-if="this.$store.state.langs.LINK_PINTRREST && this.$store.state.langs.LINK_PINTRREST != '' && this.$store.state.langs.LINK_PINTRREST != '/'"><img src="/images/icons/pinterest.svg" alt="Pinterest"></a>
              <a title="" :href="this.$store.state.langs.LINK_YOUTUBE" v-if="this.$store.state.langs.LINK_YOUTUBE && this.$store.state.langs.LINK_YOUTUBE != '' && this.$store.state.langs.LINK_YOUTUBE != '/'"><img src="/images/icons/youtube.svg" alt="YouTube"></a>
            </div>
            <ui-feedback>
              <template slot="button">
                <ui-button fill>
                  {{ this.$store.state.langs.NAME_LINK_CONTACTS }}
                </ui-button>
              </template>
            </ui-feedback>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
    import vuescroll from 'vuescroll/dist/vuescroll-native'
    import uiLangChoice from '@/components/ui/ui-lang-choice'
    import ClickOutside from 'vue-click-outside'
    import layoutGlobalMenuRow from '@/components/layout/global-menu/layout-global-menu-row'
    import layoutGlobalMenuColumn from '@/components/layout/global-menu/layout-global-menu-column'
    import layoutContactBlockRow from '@/components/layout/layout-contact-block-row'
    import uiButton from '@/components/ui/ui-button'
    import uiFeedback from '@/components/ui/ui-feedback'
    export default {
        components: {
            vuescroll,
            uiLangChoice,
            ClickOutside,
            layoutGlobalMenuRow,
            layoutGlobalMenuColumn,
            layoutContactBlockRow,
            uiButton,
            uiFeedback
        },
        props: {},
        data() {
            return {
                opened: false,
                ops: {
                    vuescroll: {},
                    scrollPanel: {},
                    rail: {
                        background: '#000',
                        opacity: 0.1,
                        size: '3px',
                        specifyBorderRadius: '0px'
                    },
                    bar: {
                        background: '#000',
                        opacity: 1,
                        size: '3px'
                    }
                },
                menus: {},
                width: window.innerWidth
            }
        },
        created() {
            //this.menus = this.globalmenutop;
            if(this.width>767) {
                this.menusRequest();
            }
            this.$bus.$on('global-menu', (status = true) => {
                this.opened = status
                document.body.style.overflow = 'hidden'
            });
            window.addEventListener('resize', this.updateWidth);
        },
        methods: {
            async menusRequest() {
                const responseMenus  = (
                    await this.$axios({
                        url: '/ajax/global-menu.php',
                        method: 'post',
                        params: {
                            clear_cache: this.getGet('clear_cache'),
                            SITE_ID: this.$store.state.langs.SITE_ID
                        }
                    })
                )
                if (responseMenus) {
                    this.menus = responseMenus.data
                } else {
                    console.log('responseMenus')
                }
            },
            getGet(name) {
                var searchString = new URLSearchParams(window.location.search);
                var res = searchString.get(name);
                return res;
            },
            close() {
                if( this.opened ) {
                    this.opened = false
                    document.body.style.overflow = 'auto'
                    this.$bus.$emit('menuOpened')
                }
            },
            url(el){
                return this.$store.state.langs.SITE_DIR + el
            },
            updateWidth() {
                this.width = window.innerWidth;
            }
        },
        mounted () {
            this.popupItem = this.$el
        },
        directives: {
            ClickOutside
        }
    }
</script>

<style lang="scss" scoped>
  .layout-global-menu {
    --padding-top: 101px;
    position: fixed;
    z-index: 501;
    background: #fff;
    height: calc(100vh - var(--padding-top));
    top: var(--padding-top);
    overflow: hidden;
    display: flex;
  }
  .container {
    display: flex;
    flex-direction: column;
  }
  .columns {
    flex-grow: 1;
    display: grid;
    grid-template-columns: auto 21em;
    grid-gap: 0 7em;
    & > * {
      overflow: hidden;
      position: relative;
    }
  }
  .menu {
    overflow: scroll;
  }
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 2em;
    grid-column: span 2;
    .close {
      margin-left: 1em;
      cursor: pointer;
    }
  }
  .contacts {
    & > * {
      margin-bottom: 2em !important;
    }
  }
  .socials {
    margin-bottom: 2em;
    & > * {
      margin-right: 3em;
    }
    img {
      width: 15px;
      height: 15px;
    }
  }
  .button {
    .ui-button {
      width: 15em;
    }
  }
  .lang {
    display: none;
  }
  @media screen and (max-width: 767px) {
    .lang {
      display: flex;
    }
    .layout-global-menu {
      padding-top: 30px;
      height: 100%;
      top: 0;
    }
    .columns {
      display: block;
      overflow: scroll;
    }
    .close {
      position: absolute;
      top: -20px;
      right: 10px;
    }
    .header {
      justify-content: space-between;
    }
    .menu {
      position: relative;
      left: auto;
      top: auto;
      width: auto;
      height: auto;
    }
  }
</style>
