<template>
  <div class="ui-finder">
    <layout-header-button unhref reverse icon="search.svg" @click="open">
      {{ this.$store.state.langs.TEXT_BUTTON_SEARCH }}
    </layout-header-button>
    <!--  -->
    <transition enter-active-class="animated zoomIn" leave-active-class="animated zoomOut">
      <div v-if="opened" class="finder-popup abs">
        <img src="@/assets/icons/close.svg" class="close" alt="close" @click="close" />
        <form :action="this.$store.state.langs.SITE_DIR+'/search/'">
          <input autofocus class="finder" name="q" :placeholder="this.$store.state.langs.PLACEHOLDER_SEARCH" type="text" />
        </form>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {

  },
  data() {
    return {
      opened: false
    }
  },
  methods: {
    open() {
      this.opened = true
      document.body.style.overflow = 'hidden'
    },
    close() {
      this.opened = false
      document.body.style.overflow = 'auto'
    }
  },
    created(){
        this.$bus.$on("opensearch", ()=>{
            this.open();
        });
    }
}
</script>

<style lang="scss" scoped>
.ui-finder {
  position: relative;
}
form{
  width: 100%;
}
input {
  letter-spacing: .09em;
  border: none!important;
  border-bottom: 1px solid!important;
  border-radius: 0;
  max-width: 100%;
}
.finder-popup {
  background: rgba(255, 255, 255, 1);
  position: fixed;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
}
.close {
  width: 1.2em;
  height: 1.2em;
  position: absolute;
  right: 2em;
  top: 2em;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    transform: rotate(90deg);
  }
}
</style>
