<template>
    <div :class="[{ reverse }]" class="layout-footer-button" @click="requestF">
        <span class="count" :class="{active:Number(count)}">{{ Number(count) }}</span>
        <slot name="svg"></slot>
        <div class="xxs-title"><slot name="title"></slot></div>
    </div>
</template>

<script>
    export default {
        props: {
            href:"",
            type:"",
            count: {
                type: Number,
                default() {
                    return 0
                }
            },
            unhref: {
                type: Boolean,
                default() {
                    return false
                }
            },
            hreftrue: {
                type: Boolean,
                default() {
                    return false
                }
            },
            reverse: {
                type: Boolean,
                default() {
                    return false
                }
            },
            icon: {
                type: String,
                default() {
                    return ''
                }
            }
        },
        methods: {
            requestF(){
                if( (Number( this.count ) > 0 && this.href) || this.hreftrue ){
                    location.href=this.href;
                }else if(this.unhref){
                    this.$parent.open();
                    return true;
                }else{
                    return false;
                }
            }
        },
        created(){
            this.$bus.$on(this.type, (data)=>{
                this.count = data;
            });
        }
    }
</script>

<style lang="scss" scoped>
    .layout-footer-button {
        cursor: pointer;
        align-items: center;
        position: relative;
        text-align: center;
        &.reverse {
            flex-direction: row-reverse;
            img {
                margin-left: 0;
                margin-right: 0.5em;
            }
        }
        img {
            margin-left: 0.5em;
            width: 1.125em;
            height: 1.125em;
            max-width: none;
            max-height: none;
        }
        .count{
            position: absolute;
            right: -7px;
            top: -6px;
            background: black;
            color: #fff;
            border-radius: 50%;
            padding: 1px 5px;
            font-size: 9px;
            display: none;
        }
        .count.active{
            display: block;
        }
        .icon-footer svg path{
            fill: #4B4F63 !important;
        }
    }
</style>