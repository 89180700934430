<template>
  <div class="layout-product-data">
    <layout-loader v-show="loading"/>
    <notifications group="add_basket" position="top left" animation-name="v-fade-left" width="100%" />
    <notifications group="errors" position="top left" animation-name="v-fade-left" width="100%" />
    <div class="container">
      <div class="left">
        <ui-breadcrumbs-detail :brand="getBrand()" :name="product.NAME" :sect="product.SECTION" :landing="product.LANDING"></ui-breadcrumbs-detail>
        <!--:class="[{ left: !$slots.breadcrumbs }]"-->
        <layout-product-promos
                v-if="width>768"
                :stars="stars"
                :sale="percent"
                :delivery="delivery()"
        ></layout-product-promos>
        <layout-product-promos
                v-else-if="width<769"
                :sale="percent"
        ></layout-product-promos>
        <layout-product-gallery
            :dir3d="product.dir3d"
            :videourl="(product.URL_VIDEO) ? product.URL_VIDEO : null"
            :morephoto="product.MORE_PHOTO"
            :imgdetail="product.DETAIL_PICTURE.SRC"
            :imgdetailsmall="product.DETAIL_PICTURE.SMALL"
            :imgdetailsmallbig="product.DETAIL_PICTURE.SMALL_BIG"
            :imgdetailmobile="product.DETAIL_PICTURE.MOBILE"
            :code="product.CODE"
            :size="selected_size"
            :img="'https://'+this.$store.state.langs.SITE_URL+this.product.DETAIL_PICTURE.SMALL"
            :name="product.NAME"
            :price="formatPrice(price)"
            :oldprice="(oldPrice !== price) ? oldPrice : false"
            :percent="(percent>0) ? percent : false"
        >
          <!--<slot slot="video" name="video"></slot>-->
        </layout-product-gallery>
      </div>
      <div class="right">
        <div class="middle-title">
          <div v-if="width<769" class="small-text mobile">SKU: {{product.PROPERTIES.CML2_ARTICLE.VALUE}}</div>
          <h1>{{product.NAME}}</h1>
          <!-- иконка избранное -->
          <div class="like">
            <layout-favorite-button :ID="product.ID" :IBLOCK_ID="product.IBLOCK_ID"></layout-favorite-button>
            <!--<img v-if="isFavorite === 'false'" src="@/assets/icons/heart.svg" alt="" />
            <img v-else src="@/assets/icons/heart-fill.svg" alt="" />-->
          </div>
        </div>
        <!-- артикул и обратная связь question  -->
        <div class="code-ask">

          <div v-if="width>768" class="code small-text">SKU: {{product.PROPERTIES.CML2_ARTICLE.VALUE}}</div>

          <div class="ask">
            <img src="@/assets/icons/chat.svg" alt="chat" />
            <div class="subtitle">
              <v-popover
                      trigger="manual"
                      :open="isOpenAskQuestiom"
                      offset="16"
                      :auto-hide="true"
                      ariaId="question-block"
                      :popoverClass="{questionSuccess: isOpenAskQuestiomSuccess}"
              >
                <span class="popover-question" @click='isOpenAskQuestiom = !isOpenAskQuestiom, isOpenAskQuestiomSuccess = false' v-html="this.$store.state.langs.ASK_QUESTION"></span>
                <template slot="popover">
                  <div v-if="!isOpenAskQuestiomSuccess" class="popover-question-head">
                    {{ this.$store.state.langs.HEAD_ASK_FORM_CART }}
                  </div>
                  <form v-if="!isOpenAskQuestiomSuccess" class="ask-question" @submit.prevent="onSubmit('isOpenAskQuestiomSuccess')">
                    <div class="popover-questionsubtitle">
                      <textarea v-model="question" maxlength="1000" rows="5" name="question" :placeholder="this.$store.state.langs.ENTER_YOUR_QUESTION" required></textarea>
                    </div>
                    <div class="popover-question-text">
                      <input v-model="email" name="email" type="text" :placeholder="this.$store.state.langs.ENTER_YOUR_EMAIL" />
                    </div>
                    <div class="popover-question-footer">
                      <ui-button fill>
                        <span v-html="this.$store.state.langs.ASK_QUESTION"></span>
                        <img src="/images/icons/question-icon.svg" alt="question"/>
                      </ui-button>
                    </div>
                    <input type="hidden" name="header" :value="this.$store.state.langs.ASK_QUESTION_VALUE" />
                  </form>
                  <div v-if="isOpenAskQuestiomSuccess" class="successQuestion">
                    <div class="popover-head">
                      <img src="/images/ok.svg" alt="ok"/>
                    </div>
                    <div class="popover-subtitle" v-html="this.$store.state.langs.THANKS"></div>
                    <div class="popover-footer">
                      <ui-button @click='isOpenAskQuestiom = !isOpenAskQuestiom' fill>{{ this.$store.state.langs.CLOSE_WINDOW }}</ui-button>
                    </div>
                  </div>
                </template>
              </v-popover>
            </div>
          </div>

          <div v-if="width<769" class="stars mobile" @click="showTestimonials()">
            <div v-for="star in 5" :key="star" :class="[{ active: Number(stars) >= star }]" class="star">
              <img src="@/assets/icons/star.svg" alt="star" />
            </div>
          </div>


        </div>
        <!-- выбор размера offery -->
        <div class="params">

          <!-- вывод коллекции -->
          <div v-if="collection.length>0" class="colors">
            <a :title="collectionItem.NAME" v-for="(collectionItem, index) in collection"
               :key="index"
               :href="collectionItem.DETAIL_PAGE_URL"
               :style="'background-image: url('+collectionItem.MIN_PREVIEW_PICTURE+')'"
               :class='{"current-sku": currentProductName === collectionItem.NAME}'
            >
            </a>
          </div>

          <!-- выбор размера -->
          <template v-if="sizes.length">
            <label class="xs-title">
              {{ this.$store.state.langs.SELECT_SIZE }}
              <small>
                <v-popover
                        trigger="manual"
                        :open="isOpenAskNotifySize"
                        offset="16"
                        :auto-hide="true"
                        ariaId="notifysize-block"
                        :popoverClass="{notifySizeSuccess: isOpenAskNotifySizeSuccess}"
                >
                  <div @click='isOpenAskNotifySize = !isOpenAskNotifySize, isOpenAskNotifySizeSuccess = false'>
                    {{ this.$store.state.langs.TEXT_CLICK_NOTYFE_SIZE }}
                  </div>
                  <template slot="popover">
                    <div v-if="!isOpenAskNotifySizeSuccess" class="popover-head">
                      {{ this.$store.state.langs.HEAD_ASK_FORM_CART }}
                    </div>
                    <form v-if="!isOpenAskNotifySizeSuccess" class="ask-notify" @submit.prevent="onSubmit('isOpenAskNotifySizeSuccess')">
                      <div class="popover-notifysizesubtitle">
                        <input v-model="size" name="size" type="text" :placeholder="this.$store.state.langs.ENTER_YOUR_SIZE" />
                      </div>
                      <div class="popover-text">
                        <input v-model="email" name="email" type="text" :placeholder="this.$store.state.langs.ENTER_YOUR_EMAIL" />
                      </div>
                      <div class="popover-footer popover-notifysize-footer">
                        <ui-button fill>
                          <span>{{ this.$store.state.langs.TEXT_CLICK_NOTYFE }}</span>
                          <img src="/images/icons/noty-icon.svg" alt="noty"/>
                        </ui-button>
                      </div>
                      <input type="hidden" name="header" :value="this.$store.state.langs.HEAD_ASK_FORM_CART" />
                    </form>
                    <div v-if="isOpenAskNotifySizeSuccess" class="successNotifySize">
                      <div class="popover-head">
                        <img src="/images/ok.svg" alt="ok"/>
                      </div>
                      <div class="popover-subtitle" v-html="this.$store.state.langs.THANKS"></div>
                      <div class="popover-footer popover-notifysize-footer">
                        <ui-button @click='isOpenAskNotifySize = !isOpenAskNotifySize' fill>{{ this.$store.state.langs.CLOSE_WINDOW }}</ui-button>
                      </div>
                    </div>
                  </template>
                </v-popover>
              </small>
            </label>
            <select v-model="selected_size" @change="onChangeSelected()">
              <option v-if="sizes" v-for="(size, i) in sizes" :value="size">
                <span>
                  {{ size }}
                </span>
              </option>
            </select>
          </template>

          <!-- выбор матерьяла -->
          <template v-if="categories.length">
                <label class="xs-title">
                    {{ this.$store.state.langs.SELECT_MATERIAL }}
                </label>
                <ui-rich-color-choicer
                        :categories="categories"
                        :fabrics="fabrics"
                        :fabrics_sections="fabrics_sections"
                        :selected_category="selected_category"
                >
                </ui-rich-color-choicer>
            </template>

          <!-- выбор ножек -->
          <template v-if="productNozki.length">
            <ui-right-block
                    :productDop="productNozki"
                    :selected_size="selected_size"
                    :title="this.$store.state.langs.SELECT_NOZHKI"
                    :offerIdCurrent="offerIdNozhkiCurrent"
                    propJs="offerIdNozhkiCurrent"
            ></ui-right-block>
          </template>

          <!-- выбор основания -->
          <template v-if="productOsnovaniya.length">
            <ui-right-block
                    :productDop="productOsnovaniya"
                    :selected_size="selected_size"
                    :title="this.$store.state.langs.SELECT_OSNOVANIYA"
                    :offerIdCurrent="offerIdOsnovaniyaCurrent"
                    propJs="offerIdOsnovaniyaCurrent"
            ></ui-right-block>
          </template>

          <!-- выбор изголовья -->
          <template v-if="productIzgolovya.length">
            <ui-right-block
                    :productDop="productIzgolovya"
                    :selected_size="selected_size"
                    :title="this.$store.state.langs.SELECT_IZGOLOVYA"
                    :offerIdCurrent="offerIdIzgolovyaCurrent"
                    propJs="offerIdIzgolovyaCurrent"
            ></ui-right-block>
          </template>

          <!-- инфо оплата онлайн -->
          <ui-info-right-block
            v-if="this.$store.state.langs.CURRENCY_SING!='$' && this.$store.state.langs.CURRENCY_SING!='AED' && this.$store.state.langs.SITE_ID!='md'"
            :total="price"
          ></ui-info-right-block>

          <ui-info-right-block-tabby
              v-if="this.$store.state.langs.CURRENCY_SING=='AED' && this.$store.state.langs.SITE_ID!='md'"
              :total="price"
          ></ui-info-right-block-tabby>


        </div>

        <div v-if="Number(stock) > 0" class="block-stok"><span class="bulit"></span> {{ this.$store.state.langs.STOCK }} {{ stock }} {{ this.$store.state.langs.PCS }} </div>

        <!-- цена и обратная связь notify  -->
        <div class="price-block">
          <div class="prices">
            <div class="price" :class="{reds:Number(this.percent)>0}" v-html="formatPrice( this.price )"></div>
            <div v-if="Number(this.percent)>0" class="old-price" v-html="formatPrice( this.oldPrice )"></div>
          </div>
          <div class="notify">
            <v-popover
                    trigger="manual"
                    :open="isOpenAskNotify"
                    offset="16"
                    :auto-hide="true"
                    ariaId="notify-block"
                    :popoverClass="{notifySuccess: isOpenAskNotifySuccess}"
            >
              <div class="popover-notify" @click='isOpenAskNotify = !isOpenAskNotify, isOpenAskNotifySuccess = false'>
                <span class="xxs-title">{{ this.$store.state.langs.NOTIFY_ME }}</span>
                <img src="@/assets/icons/notify.svg" alt="notify" />
              </div>
              <template slot="popover">
                <div v-if="!isOpenAskNotifySuccess" class="popover-head">
                  {{ this.$store.state.langs.HEAD_NOTYFE }}
                </div>
                <form v-if="!isOpenAskNotifySuccess" class="ask-notify" @submit.prevent="onSubmit('isOpenAskNotifySuccess')">
                  <div class="popover-text">
                    <input v-model="email" name="email" type="text" :placeholder="this.$store.state.langs.ENTER_YOUR_EMAIL" />
                  </div>
                  <div class="popover-footer popover-notify-footer">
                    <ui-button fill>
                      <span>{{ this.$store.state.langs.NOTIFY_ME }}</span>
                      <img src="/images/icons/noty-icon.svg" alt="notify"/>
                    </ui-button>
                  </div>
                  <input type="hidden" name="header" :value="this.$store.state.langs.NOTIFY_ME" />
                </form>
                <div v-if="isOpenAskNotifySuccess" class="successNotify">
                  <div class="popover-head">
                    <img src="/images/ok.svg" alt="ok"/>
                  </div>
                  <div class="popover-subtitle" v-html="this.$store.state.langs.THANKS"></div>
                  <div class="popover-footer popover-notify-footer">
                    <ui-button @click='isOpenAskNotify = !isOpenAskNotify' fill>{{ this.$store.state.langs.CLOSE_WINDOW }}</ui-button>
                  </div>
                </div>
              </template>
            </v-popover>
          </div>
        </div>
        <!-- кнопка в добавления корзину -->
        <div class="buttons addToCardButton">
          <v-popover
                  trigger="click"
                  :open="isOpen"
                  offset="16"
                  :auto-hide="true"
                  ariaId="addToCard"
          >
            <ui-button @click="addToCard" fill>
              {{ this.$store.state.langs.ADD_TO_CART }}
              <span v-if="selectedDopProduct()" v-html="selectedDopProduct()"></span>
            </ui-button>
            <template slot="popover">
              <a href="#" title="" class="popover-close" @click='isOpen = !isOpen'>&#10006;</a>
              <div class="popover-head">
                <img src="/images/ok.svg" alt="ok"/>
              </div>
              <div class="popover-subtitle">
                {{ this.$store.state.langs.ITEM_ADDED_TO_CART }}
              </div>
              <div style="cursor: pointer" class="popover-text" @click="gotocart()">
                <span><a title="" :href="this.$store.state.langs.SITE_DIR+'/cart/'">{{ this.$store.state.langs.GO_TO_CART }}</a></span>
                <img src="/images/icons/basket.svg" alt="basket"/>
              </div>
              <div class="popover-footer">
                <a href="#" title="" @click='isOpen = !isOpen'>
                  {{ this.$store.state.langs.CONTINUE_SHOPPING }}
                </a>
              </div>
            </template>

          </v-popover>
        </div>

        <!-- Доставка -->
        <ui-info-right-block-delivery
            v-if="this.$store.state.langs.CURRENCY_SING!='$'"
        >
          <div slot="xx-title" v-html="formatPriceDelivery( this.price )"></div>
        </ui-info-right-block-delivery>

        <!-- Возврат -->
        <ui-info-right-block-return
            v-if="this.$store.state.langs.CURRENCY_SING!='$'"
        >
          <div slot="xx-title"> {{ this.$store.state.langs.TITLE_HASSLE_FREE_RETURNS }} </div>
        </ui-info-right-block-return>

        <layout-product-promos
                v-if="width<769"
                :delivery="delivery()"
        ></layout-product-promos>
        <!-- дополнительные товары -->
        <div class="complete" v-if="complete.length">
          <div class="xs-title">
            {{ this.$store.state.langs.COMPLETE_SET }}
          </div>
          <div v-if="complete.length" class="complete-items">
            <label :for="'complete'+i" v-for="(complet, i) in complete">
              <input type="checkbox" name="" :value="complecte_id(selected_size,complet.ID)" v-model="selectedComplecte" :id="'complete'+i">
              <div>
                <div class="small-text">{{ complet.NAME }}</div>
                <div class="small-text"><b v-html="prices(selected_size,complet.ID)"></b></div>
              </div>
              <img class="complecte-img" :src="complet.DETAIL_PICTURE.SRC" :alt="complet.CODE+i">
            </label>
          </div>
        </div>
      </div>
    </div>
    <slot name="viewer360"></slot>
    <slot name="viewerYoutube"></slot>
    <layout-product-details>
      <template slot="text">
        <h2 class="middle-title">{{ this.$store.state.langs.DETAILS }}</h2>
        <div v-html="product.DETAIL_TEXT"></div>
      </template>
      <template slot="sizestab">
        <section-size :offers="product.OFFERS"></section-size>
      </template>

      <template slot="testimonialstab">
        <section-testimonials :scrollcontent="true" :page_detail=true :product_id="product.ID"></section-testimonials>
      </template>
      <template slot="featurestab">
        <section-propsdetail :propsdetail='propdetails'></section-propsdetail>
      </template>

    </layout-product-details>
    <section-products-carousel v-if="viewAlternative && productAlternative.length" :count="4">
      <p slot="title">{{ this.$store.state.langs.ALTERNATIVE_MODEL_HEAD }}</p>
      <p slot="subtitle">{{ this.$store.state.langs.ALTERNATIVE_MODEL_HEAD_SUBTITLE }}</p>
      <layout-product-card :product='prod' v-for="(prod, index) in productAlternative" :key="index"></layout-product-card>
    </section-products-carousel>
    <section-products-carousel v-if="viewARecomend && productRecomends.length" :count="4">
      <p slot="title">{{this.$store.state.langs.RECOMMENDED_MODEL_HEAD}}</p>
      <p slot="subtitle">{{this.$store.state.langs.ECOMMENDED_MODEL_HEAD_SUBTITLE}}</p>
      <layout-product-card :product='prodRecommend' v-for="(prodRecommend, index) in productRecomends" :key="index"></layout-product-card>
    </section-products-carousel>
  </div>
</template>

<script>
    import uiBreadcrumbsDetail from '@/components/ui/ui-breadcrumbs-detail'
    import layoutProductGallery from '@/components/layout/product/layout-product-gallery'
    import layoutProductPromos from '@/components/layout/product/layout-product-promos'
    import sectionProductsCarousel from '@/components/sections/section-products-carousel'
    import layoutProductDetails from '@/components/layout/product/layout-product-details'
    import sectionSize from '@/components/sections/section-size'
    import sectionTestimonials from '@/components/sections/section-testimonials'
    import sectionPropsdetail from '@/components/sections/section-propsdetail'
    import layoutTestimonialCard from '@/components/layout/layout-testimonial-card'
    import layoutFavoriteButton from '@/components/layout/layout-favorite-button'
    import uiRichColorChoicer from '@/components/ui/ui-rich-color-choicer'
    import uiRightBlock from '@/components/ui/ui-right-block'
    import uiInfoRightBlock from '@/components/ui/ui-info-right-block'
    import uiInfoRightBlockTabby from '@/components/ui/ui-info-right-block-tabby'
    import uiInfoRightBlockDelivery from '@/components/ui/ui-info-right-block-delivery'
    import uiInfoRightBlockReturn from '@/components/ui/ui-info-right-block-return'
    import ClickOutside from 'vue-click-outside'

    export default {
        components: {
            uiBreadcrumbsDetail,
            layoutProductGallery,
            layoutProductPromos,
            sectionProductsCarousel,
            layoutProductDetails,
            sectionSize,
            sectionTestimonials,
            sectionPropsdetail,
            layoutTestimonialCard,
            layoutFavoriteButton,
            uiRichColorChoicer,
            uiRightBlock,
            uiInfoRightBlock,
            uiInfoRightBlockTabby,
            uiInfoRightBlockDelivery,
            uiInfoRightBlockReturn,
          ClickOutside
        },
        props: {
            product:{},
            propdetails : [],
            recomended : {},
            currentProductName: null,
        },
        data() {
            return {
                offerIdCurrent: null,
                offerIdNozhkiCurrent: null,
                offerIdOsnovaniyaCurrent: null,
                offerIdIzgolovyaCurrent: null,
                productNozki: [],
                productOsnovaniya: [],
                productIzgolovya: [],
                viewAlternative: false,
                productAlternative: [],
                viewARecomend: false,
                productRecomends: [],
                isOpen: false,
                isOpenAskQuestiom: false,
                isOpenAskQuestiomSuccess: false,
                isOpenAskNotify: false,
                isOpenAskNotifySuccess: false,
                isOpenAskNotifySize: false,
                isOpenAskNotifySizeSuccess: false,
                loading: false,
                selected_size: null,
                selected_category: null,
                price: null,
                oldPrice: null,
                percent: null,
                name: null,
                question: null,
                email: null,
                size: null,
                material: null,
                category: null,
                testimonials: {},
                complete: [],
                selectedComplecte:[],
                sizes: [],
                categories: [],
                fabrics: [],
                fabrics_sections: [],
                collection: [],
                width: 0,
                stars: 4,
                iblock_id: this.product.IBLOCK_ID,
                scrollcontentRecomend: true,
                scrollcontentAlternative: true,
                scrollcontentNozki: true,
                scrollcontentOsnovaniya: true,
                scrollcontentIzgolovya: true,
                stock: 0
            }
        },
        methods: {
            addToCard(){
                this.loading = true;
                var ids = [];
                ids.push(this.offerIdCurrent);
                ids = ids.concat(this.selectedComplecte);
                if (this.offerIdNozhkiCurrent){
                    ids.push(this.offerIdNozhkiCurrent);
                }
                if (this.offerIdOsnovaniyaCurrent){
                    ids.push(this.offerIdOsnovaniyaCurrent);
                }
                if (this.offerIdIzgolovyaCurrent){
                    ids.push(this.offerIdIzgolovyaCurrent);
                }

                var prod = [];
                if( this.offerIdCurrent ){
                    let prodComplete = {}
                    let brand = "";
                    prodComplete = this.product.OFFERS.find(offer => offer.ID === this.offerIdCurrent);
                    if( prodComplete ) {
                        if (this.product.PROPERTIES.BRAND) {
                            brand = this.product.PROPERTIES.BRAND.VALUE;
                        }
                        prod.push({'name': prodComplete.NAME,'id': prodComplete.ID,'price': prodComplete.MIN_PRICE.DISCOUNT_VALUE,'brand': brand,'list': "Мебель",'category': "Мебель", 'quantity': 1});
                    }
                }
                if( this.selectedComplecte ){
                    let prodComplete = {}
                    let brand = "";
                    this.complete.forEach((prop) => {
                        prodComplete = prop.OFFERS.find(offer => offer.ID === this.selectedComplecte);
                        if( prodComplete ) {
                            if( prop.PROPERTIES.BRAND ) {
                                brand = prop.PROPERTIES.BRAND.VALUE;
                            }
                            prod.push({
                              'name': prodComplete.NAME,
                              'id': prodComplete.ID,
                              'price': prodComplete.MIN_PRICE.DISCOUNT_VALUE,
                              'brand': brand,
                              'list': "Мебель",
                              'category': "Мебель",
                              'quantity': 1
                            })

                          return;
                        }
                    });
                }
                if( this.offerIdIzgolovyaCurrent ){
                    let prodComplete = {}
                    let brand = "";
                    this.productIzgolovya.forEach((prop) => {
                        prodComplete = prop.OFFERS.find(offer => offer.ID === this.offerIdIzgolovyaCurrent);
                        if( prodComplete ) {
                            if( prop.PROPERTIES.BRAND ) {
                                brand = prop.PROPERTIES.BRAND.VALUE;
                            }
                          prod.push({
                            'name': prodComplete.NAME,
                            'id': prodComplete.ID,
                            'price': prodComplete.MIN_PRICE.DISCOUNT_VALUE,
                            'brand': brand,
                            'list': "Мебель",
                            'category': "Мебель",
                            'quantity': 1
                          })

                            return;
                        }
                    });
                }
                if( this.offerIdNozhkiCurrent ){
                    let prodComplete = {}
                    let brand = "";
                    this.productNozki.forEach((prop) => {
                        prodComplete = prop.OFFERS.find(offer => offer.ID === this.offerIdNozhkiCurrent);
                        if( prodComplete ) {
                            if( prop.PROPERTIES.BRAND ) {
                                brand = prop.PROPERTIES.BRAND.VALUE;
                            }
                          prod.push({
                            'name': prodComplete.NAME,
                            'id': prodComplete.ID,
                            'price': prodComplete.MIN_PRICE.DISCOUNT_VALUE,
                            'brand': brand,
                            'list': "Мебель",
                            'category': "Мебель",
                            'quantity': 1
                          })

                            return;
                        }
                    });
                }
                if( this.offerIdOsnovaniyaCurrent ){
                    let prodComplete = {}
                    let brand = "";
                    this.productOsnovaniya.forEach((prop) => {
                        prodComplete = prop.OFFERS.find(offer => offer.ID === this.offerIdOsnovaniyaCurrent);
                        if( prodComplete ) {
                            if( prop.PROPERTIES.BRAND ) {
                                brand = prop.PROPERTIES.BRAND.VALUE;
                            }
                          prod.push({
                            'name': prodComplete.NAME,
                            'id': prodComplete.ID,
                            'price': prodComplete.MIN_PRICE.DISCOUNT_VALUE,
                            'brand': brand,
                            'list': "Мебель",
                            'category': "Мебель",
                            'quantity': 1
                          })

                            return;
                        }
                    });
                }

                this.$axios({
                    url: '/ajax/index.php?id='+ids+'&add_basket=Y&CATALOG_ID='+this.$store.state.langs.CATALOG_ID+'&GROUP_USER_ID='+this.$store.state.langs.GROUP_USER_ID+'&SITE_ID='+this.$store.state.langs.SITE_ID, method: 'post'
                }).then(response => {
                    if (response.status == 200) {
                        this.loading = false;
                        this.isOpen = true;
                        //var basketCount = document.querySelector('.linkButtonBasket .count');
                        var count = Number(response.data.productCount);
                        this.$bus.$emit("smallbasket", count);
                        /*
                        if (count > 0) {
                            basketCount.classList.add("active");
                            basketCount.innerHTML = count;
                        }else{
                            basketCount.classList.remove("active");
                        }*/
                    }
                }).catch(e => {
                    this.$notify({
                        group: 'add_basket',
                        title: 'error',
                        text: e,
                        type: 'error'
                    });
                    console.log('Error: ' + e)
                });

                window.dataLayerAddProductBasket(prod);

            },
            onChangeSelected(){
                if(this.selected_size) {
                    var offer = this.product.OFFERS.filter((arr, i) => {
                        if (this.selected_category) {
                            return (arr.sizes.VALUE === this.selected_size && arr.materials.VALUE === this.selected_category) ? arr : '';
                        } else {
                            return (arr.sizes.VALUE === this.selected_size) ? arr : '';
                        }
                    });
                    this.price = offer[0].MIN_PRICE.DISCOUNT_VALUE;
                    this.oldPrice = offer[0].MIN_PRICE.VALUE;
                    this.percent = offer[0].MIN_PRICE.DISCOUNT_DIFF_PERCENT;
                    this.name = offer[0].NAME;
                    this.offerIdCurrent = offer[0].ID;
                    this.stock = offer[0].CATALOG_QUANTITY;

                    if(this.offerIdOsnovaniyaCurrent) {
                        var productOsnovaniyaCurrent = this.productOsnovaniya.find(p => {
                                return p.OFFERS.find(o => o.ID == this.offerIdOsnovaniyaCurrent);
                            }
                        );
                        var idOffer = productOsnovaniyaCurrent.OFFERS.find(c => c.PROPERTIES[this.$store.state.langs.CODE_SIZE].VALUE == this.selected_size).ID;
                        if( idOffer ) {
                            this.offerIdOsnovaniyaCurrent = idOffer;
                        }else{
                            this.offerIdOsnovaniyaCurrent = null;
                        }
                    }

                    if(this.offerIdIzgolovyaCurrent) {
                        var productIzgolovyaCurrent = this.productIzgolovya.find(p => {
                                return p.OFFERS.find(o => o.ID == this.offerIdIzgolovyaCurrent);
                            }
                        );
                        var idOffer = productIzgolovyaCurrent.OFFERS.find(c => c.PROPERTIES[this.$store.state.langs.CODE_SIZE].VALUE == this.selected_size).ID;
                        if( idOffer ) {
                            this.offerIdIzgolovyaCurrent = idOffer;
                        }else{
                            this.offerIdIzgolovyaCurrent = null;
                        }
                    }

                }else{
                    this.price = this.product.OFFERS[0].MIN_PRICE.DISCOUNT_VALUE;
                    this.oldPrice = this.product.OFFERS[0].MIN_PRICE.VALUE;
                    this.percent = this.product.OFFERS[0].MIN_PRICE.DISCOUNT_DIFF_PERCENT;
                    this.name = this.product.OFFERS[0].NAME;
                    this.offerIdCurrent = this.product.OFFERS[0].ID;
                    this.stock = this.product.OFFERS[0].CATALOG_QUANTITY;
                }

                this.dataLayerProductDetail();

            },
            //getOffer(array, id) {
            //    var obj = array.filter(function(arr, i){
            //        return arr.ID === id ? arr : '';
            //    });
            //    return obj;
            //},
            onSubmit(successType) {
                this.loading = true;
                this.$axios({
                    url: '/ajax/index.php?feadback=Y',
                    method: 'post',
                    params: {
                        successType: successType,
                        question: this.question,
                        email: this.email,
                        size: this.selected_size,
                        category: this.selected_category,
                        material: this.material,
                        img: "https://"+this.$store.state.langs.SITE_URL+this.product.DETAIL_PICTURE.SMALL,
                        name: this.product.NAME,
                        price: this.formatPrice(this.price),
                        oldprice: (this.oldPrice !== this.price) ? this.oldPrice : false,
                        percent: (this.percent>0) ? this.percent : false,
                        url: location.href,
                        facebok: this.$store.state.langs.FACEBOOK,
                        pinterest: this.$store.state.langs.PINTEREST,
                        instagram: this.$store.state.langs.INSTAGRAM
                    }
                }).then(response => {
                    if (response.status == 200 && response.data.success) {
                        this[successType] = true;
                        this.question = "";
                        this.email = "";
                        this.size = "";
                        this.material = "";
                        this.category = "";
                    }else{
                        this.$notify({
                            group: 'errors',
                            title: 'error',
                            text: 'Error feedback',
                            type: 'error'
                        });
                        console.log('Error feedback success: false')
                    }
                }).catch(e => {
                    this.$notify({
                        group: 'errors',
                        title: 'error',
                        text: e,
                        type: 'error'
                    });
                    console.log('Error: ' + e)
                })
                this.loading = false;
            },
            prices(id,complecteId){
              if(this.complete) {
                var comlete = this.complete.find(product => product.ID === complecteId);

                if (comlete.OFFERS.find(offer => offer.PROPERTIES[this.$store.state.langs.CODE_SIZE].VALUE === this.selected_size)) {
                  var currentComplecte = comlete.OFFERS.find(offer => offer.PROPERTIES[this.$store.state.langs.CODE_SIZE].VALUE === this.selected_size);
                  return this.formatPrice(currentComplecte.MIN_PRICE.DISCOUNT_VALUE)
                  //return new Intl.NumberFormat('ru-RU', {minimumFractionDigits: 0, style: 'currency', currency: this.$store.state.langs.CURRENCY }).format(Math.round(currentComplecte.MIN_PRICE.DISCOUNT_VALUE))
                }
              }
            },
            complecte_id(id,complecteId){
                if(this.complete) {
                  var comlete = this.complete.find(product => product.ID === complecteId);
                  if (comlete.OFFERS.find(offer => offer.PROPERTIES[this.$store.state.langs.CODE_SIZE].VALUE === this.selected_size)) {
                    var currentComplecte = comlete.OFFERS.find(offer => offer.PROPERTIES[this.$store.state.langs.CODE_SIZE].VALUE === this.selected_size);
                    return currentComplecte.ID;
                  } else {
                    return comlete.OFFERS[0].ID;
                  }
                }
            },
            selectedDopProduct(){
                var countTovar = 0;
                if( this.selectedComplecte.length>0 ) {
                    countTovar = countTovar + this.selectedComplecte.length+1;
                }
                if( this.offerIdNozhkiCurrent ) {
                    countTovar = countTovar + 1;
                }
                if( this.offerIdOsnovaniyaCurrent ) {
                    countTovar = countTovar+1;
                }
                if( this.offerIdIzgolovyaCurrent) {
                    countTovar = countTovar+1;
                }
                return countTovar;
            },
            delivery() {
                return (
                    this.product.PROPERTIES[this.$store.state.langs.TIMINGS_OF_DELIVERY] &&
                    this.product.PROPERTIES[this.$store.state.langs.TIMINGS_OF_DELIVERY].VALUE
                ) ? this.product.PROPERTIES[this.$store.state.langs.TIMINGS_OF_DELIVERY].VALUE : '';
            },
            formatPrice(price) {
                if(price) {
                    //if( this.width<769 ) {
                        return this.$store.state.langs.CURRENCY_SING + " " +Math.ceil(Number(price)).toLocaleString();
                    //}else{
                    //    return Math.ceil(Number(price)).toLocaleString() + " " + this.$store.state.langs.CURRENCY_SING
                    //}
                }
            },
            formatPriceDelivery(price) {
              if( this.$store.state.langs.SITE_ID === "uk" && price ) {
                let priceDelivery = 6;
                if(Number(price) > 50 && Number(price) < 149.99){
                  priceDelivery = 15;
                }
                if(Number(price) > 149.99 && Number(price) < 249.99){
                  priceDelivery = 25;
                }
                if(Number(price) > 249.99 && Number(price) < 399.99){
                  priceDelivery = 40;
                }
                if(Number(price) > 399.99){
                  priceDelivery = 50;
                }
                return this.$store.state.langs.TITLE_INFO_DELIVERY + " " + this.$store.state.langs.CURRENCY_SING + " " + priceDelivery;
              }else if ( this.$store.state.langs.SITE_ID === "ae" && price ){
                let priceDelivery = 29;
                if(Number(220) > 50 && Number(price) < 650.99){
                  priceDelivery = 65;
                }
                if(Number(price) > 650.99 && Number(price) < 1099.99){
                  priceDelivery = 110;
                }
                if(Number(price) > 1099.99 && Number(price) < 1749){
                  priceDelivery = 170;
                }
                if(Number(price) > 1749){
                  priceDelivery = 220;
                }
                return this.$store.state.langs.TITLE_INFO_DELIVERY + " " + this.$store.state.langs.CURRENCY_SING + " " + priceDelivery;
              } else{return "";}
            },
            getBrand(){
                return ( typeof this.product.PROPERTIES[this.$store.state.langs.CODE_BRAND]!="undefined" && this.product.PROPERTIES[this.$store.state.langs.CODE_BRAND].VALUE) ? this.product.PROPERTIES[this.$store.state.langs.CODE_BRAND].VALUE : "";
            },
            updateWidth() {
                this.width = window.innerWidth;
            },
            showTestimonials(){
                let layoutProductDetailsTabs = document.querySelector('.layout-product-details-tabs');
                layoutProductDetailsTabs.scrollIntoView(true);
                window.scrollBy(0, -100);
                this.$bus.$emit('tabs',1);
            },
            apply(cat){
                this.selected_category = cat;
                this.onChangeSelected();
            },
            alternativeRequest(){
                if( this.scrollcontentAlternative && this.product.PROPERTIES && this.product.PROPERTIES.ALTERNATIVE_MODELS && this.product.PROPERTIES.ALTERNATIVE_MODELS.VALUE.length > 1 ){
                  window.addEventListener('scroll', () => {
                    if (pageYOffset > 200 && this.scrollcontentAlternative) {
                      this.requestProductsAlternative( "productAlternative", "ALTERNATIVE_MODELS" );
                      this.scrollcontentAlternative=false;
                    }
                  });
                }
            },
            recomendRequest(){
                if( this.scrollcontentRecomend && this.product.PROPERTIES && this.product.PROPERTIES.RECOMMENDED && this.product.PROPERTIES.RECOMMENDED.VALUE.length > 1 ){

                    window.addEventListener('scroll', () => {
                      if (pageYOffset > 200 && this.scrollcontentRecomend) {
                        this.requestProductsRecomends( "productRecomends", "RECOMMENDED" );
                        this.scrollcontentRecomend=false;
                      }
                    });

                }
            },
            async requestProductsDop(propThis, propCode){
                await this.$axios({
                    url: '/ajax/arproductsxliid.php',
                    method: 'post',
                    data: {
                        prodjson: this.product.PROPERTIES[propCode].VALUE,
                        site_id: this.$store.state.langs.SITE_ID
                    }
                }).then(response => {
                    if (response.status == 200) {
                        this[propThis] = response.data;
                    } else {
                        console.log("request materials error")
                    }
                }).catch(e => {
                    console.log('Error: ' + e);
                })
                this.loading = false;
            },
            async requestProductsNozhki(propThis, propCode){
                await this.$axios({
                    url: '/ajax/products-nozhki.php',
                    method: 'post',
                    data: {
                        prodjson: this.product.PROPERTIES[propCode].VALUE,
                        site_id: this.$store.state.langs.SITE_ID
                    }
                }).then(response => {
                    if (response.status == 200) {
                        this[propThis] = response.data;
                    } else {
                        console.log("request materials error")
                    }
                }).catch(e => {
                    console.log('Error: ' + e);
                })
                this.loading = false;
            },
            async requestProductsOsnovaniya(propThis, propCode){
                await this.$axios({
                    url: '/ajax/products-osnovaniya.php',
                    method: 'post',
                    data: {
                        prodjson: this.product.PROPERTIES[propCode].VALUE,
                        site_id: this.$store.state.langs.SITE_ID
                    }
                }).then(response => {
                    if (response.status == 200) {
                        this[propThis] = response.data;
                    } else {
                        console.log("request Osnovaniya error")
                    }
                }).catch(e => {
                    console.log('Error: ' + e);
                })
                this.loading = false;
            },
            async requestProductsIzgolovya(propThis, propCode){
                await this.$axios({
                    url: '/ajax/products-izgolovya.php',
                    method: 'post',
                    data: {
                        prodjson: this.product.PROPERTIES[propCode].VALUE,
                        site_id: this.$store.state.langs.SITE_ID
                    }
                }).then(response => {
                    if (response.status == 200) {
                        this[propThis] = response.data;
                    } else {
                        console.log("request Osnovaniya error")
                    }
                }).catch(e => {
                    console.log('Error: ' + e);
                })
                this.loading = false;
            },
            async requestProductsAlternative(propThis, propCode){
                await this.$axios({
                    url: '/ajax/products-alternative.php',
                    method: 'post',
                    data: {
                        prodjson: this.product.PROPERTIES[propCode].VALUE,
                        site_id: this.$store.state.langs.SITE_ID
                    }
                }).then(response => {
                    if (response.status == 200) {
                        this[propThis] = response.data;
                    } else {
                        console.log("request Osnovaniya error")
                    }
                }).catch(e => {
                    console.log('Error: ' + e);
                })
                this.loading = false;
            },
            async requestProductsRecomends(propThis, propCode){
                await this.$axios({
                    url: '/ajax/products-recomends.php',
                    method: 'post',
                    data: {
                        prodjson: this.product.PROPERTIES[propCode].VALUE,
                        site_id: this.$store.state.langs.SITE_ID
                    }
                }).then(response => {
                    if (response.status == 200) {
                        this[propThis] = response.data;
                    } else {
                        console.log("request Osnovaniya error")
                    }
                }).catch(e => {
                    console.log('Error: ' + e);
                })
                this.loading = false;
            },
            currentItemDop(propJs, offerId) {
                this[propJs]=offerId;
            },
            dataLayerProductDetail(){
                if (typeof window.dataLayerProductDetail != "undefined") {
                    var brand = (this.product.PROPERTIES.BRAND && this.product.PROPERTIES.BRAND.VALUE) ? this.product.PROPERTIES.BRAND.VALUE : "";
                    window.dataLayerProductDetail(this.name, this.offerIdCurrent, this.price, brand, "");
                }
            },
            gotocart(){
              location.href=this.$store.state.langs.SITE_DIR+'/cart/'
            }
        },
        mounted() {
            this.updateWidth();


            let modelViewer = document.createElement('script');
            modelViewer.setAttribute("type","module");
            modelViewer.setAttribute('src', '//unpkg.com/@google/model-viewer/dist/model-viewer.min.js')
            document.head.appendChild(modelViewer)


            if( this.product.PROPERTIES.UPGRADE_YOU_SLEEP_SYSTEM && this.product.PROPERTIES.UPGRADE_YOU_SLEEP_SYSTEM.VALUE ) {
                this.$axios({
                    url: '/ajax/arproductsxliid.php', method: 'post', data: {prodjson:this.product.PROPERTIES.UPGRADE_YOU_SLEEP_SYSTEM.VALUE}
                }).then(response => {
                    if (response.status == 200) {
                        this.complete = response.data;
                        this.loading = false;
                    }
                }).catch(e => {
                    console.log('Error: ' + e)
                })
            }
            if( this.product.OFFERS ){
                this.product.OFFERS.forEach((offer, index) => {
                    if(index === 0) {
                        this.selected_size = (typeof offer.PROPERTIES[this.$store.state.langs.CODE_SIZE] != "undefined") ? offer.PROPERTIES[this.$store.state.langs.CODE_SIZE].VALUE : null;
                        this.selected_category = (typeof offer.PROPERTIES[this.$store.state.langs.MATERIAL] != "undefined") ? offer.PROPERTIES[this.$store.state.langs.MATERIAL].VALUE : null;
                    }
                    if( typeof offer.PROPERTIES[this.$store.state.langs.CODE_SIZE] != "undefined" ){
                        offer.sizes = offer.PROPERTIES[this.$store.state.langs.CODE_SIZE];
                        if( offer.sizes.VALUE ){
                            this.sizes.push(offer.sizes.VALUE);
                        }
                        if( this.product.OFFER_CURRENT_ID > 0 && offer.ID == this.product.OFFER_CURRENT_ID ){
                            this.selected_size = offer.sizes.VALUE
                        }
                    }
                    if( typeof offer.PROPERTIES[this.$store.state.langs.MATERIAL] != "undefined"){
                        offer.materials = offer.PROPERTIES[this.$store.state.langs.MATERIAL];
                        if( offer.materials.VALUE ) {
                            this.categories.push(offer.materials.VALUE);
                            //this.materials[offer.materials.VALUE]=offer.materials.VALUE;
                        }
                        if( this.product.OFFER_CURRENT_ID > 0 && offer.ID == this.product.OFFER_CURRENT_ID ){
                            this.selected_category = offer.materials.VALUE
                        }
                    }

                });

                this.sizes = [...new Set(this.sizes)];
                this.categories = [...new Set(this.categories)];
                this.sizes.sort();
                this.categories.sort();
            }
            this.onChangeSelected();

            if( typeof this.product.PROPERTIES[this.$store.state.langs.PROP_FABRICS] != "undefined" && this.product.PROPERTIES[this.$store.state.langs.PROP_FABRICS]['VALUE'].length>0){
                this.fabrics = this.product.PROPERTIES[this.$store.state.langs.PROP_FABRICS]['VALUE'];
            }
            if( typeof this.product.PROPERTIES[this.$store.state.langs.PROP_FABRICS_SECTIONS] != "undefined" && this.product.PROPERTIES[this.$store.state.langs.PROP_FABRICS_SECTIONS]['VALUE'].length>0){
                this.fabrics_sections = this.product.PROPERTIES[this.$store.state.langs.PROP_FABRICS_SECTIONS]['VALUE'];
            }
            //collection
            if( this.product.COLLECTION && this.product.COLLECTION.length > 1 ){
                this.collection = this.product.COLLECTION;
            }
            //nozhki
            if( this.scrollcontentNozki && this.product.CODE_NOZHKI && this.product.CODE_NOZHKI.length > 1 ){
              window.addEventListener('scroll', () => {
                if (pageYOffset > 200 && this.scrollcontentNozki) {
                  this.requestProductsNozhki( "productNozki", this.product.CODE_NOZHKI );
                  this.scrollcontentNozki=false;
                }
              });
            }
            //osnovaniya
            if( this.scrollcontentOsnovaniya && this.product.CODE_OSNOVANIYA && this.product.CODE_OSNOVANIYA.length > 1 ){

              window.addEventListener('scroll', () => {
                if (pageYOffset > 200 && this.scrollcontentOsnovaniya) {
                  this.requestProductsOsnovaniya( "productOsnovaniya", this.product.CODE_OSNOVANIYA );
                  this.scrollcontentOsnovaniya=false;
                }
              });
            }
            //izgolovya
            if( this.scrollcontentIzgolovya && this.product.CODE_IZGOLOVYA && this.product.CODE_IZGOLOVYA.length > 1 ){

              window.addEventListener('scroll', () => {
                if (pageYOffset > 200 && this.scrollcontentIzgolovya) {
                  this.requestProductsIzgolovya( "productIzgolovya", this.product.CODE_IZGOLOVYA );
                  this.scrollcontentIzgolovya=false;
                }
              });
            }
            document.querySelector('.doH1').remove();
        },
        created() {
            for (let item of this.product.COLLECTION) {
              if (item.NAME === this.product.NAME) {
                this.currentProductName = item.NAME;
              }
            }
            window.addEventListener('resize', this.updateWidth);
            window.addEventListener('scroll', () => {
                if(!this.viewAlternative && pageYOffset > 200){
                    this.viewAlternative = true;
                    this.alternativeRequest();
                    this.viewARecomend = true;
                    this.recomendRequest();
                }
            });
            setTimeout(() => {
                this.dataLayerProductDetail();
            }, 1000);

        },
        computed: {
            moredetail() {
                return this.$store.state.langs.MORE_DETAILS
            }
        },
        directives: {
          ClickOutside
        }
    }
</script>

<style lang="scss" scoped>
  .layout-product-data {
    padding-bottom: 3em;
  }
  .container {
    display: grid;
    grid-template-columns: auto 20em;
    grid-gap: 4.5em;
  }
  .middle-title {
    position: relative;
    padding-right: 0.5em;
    .like {
      position: absolute;
      right: 0;
      top: -0.4em;
    }
  }
  .right {
    padding-top: 1.8em;
  }
  .code {
    background: var(--bg);
    display: inline-flex;
    padding: 0.2em 0.3em;
    margin-right: 1em;
  }
  .ask {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    img {
      margin-right: 0.5em;
    }
  }
  .code-ask {
    display: flex;
    align-items: center;
    margin-top: 0.5em;
  }
  .params {
    margin-top: 3em;
  }
  .prices {
    display: flex;
    align-items: center;
    .price {
      font-size: 2.1em;
      font-weight: 700;
    }
    .old-price {
      font-size: 0.875em;
      opacity: 0.4;
      text-decoration: line-through;
      font-weight: 600;
      margin-left: 1em;
    }
  }
  .notify {
    display: flex;
    align-items: center;
    img {
      margin-left: 0.5em;
    }
  }
  .price-block {
    margin: 1em 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .buttons {
    .ui-button {
      width: 100%;
      margin: 0.2em 0;
    }
  }
  .complete {
    margin-top: 1em;
  }
  .complete-items {
    margin-top: 0.5em;
    & > * {
      display: grid;
      padding: 0.5em;
      border: solid 1px rgba(0, 0, 0, 0.1);
      grid-template-columns: min-content auto auto;
      grid-gap: 1em;
      cursor: pointer;
      &:not(:last-child) {
        margin-bottom: 0.5em;
      }
    }
  }
  .complecte-img{
    max-height: 90px;
  }


  .layout-product-data {
    .colors {
      display: flex;
      margin-top: 0.3em;
      flex-wrap: wrap;
      & > * {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin: 0.2em;
      }
    }
    .star {
      opacity: 0.2;
      &.active {
        opacity: 1;
      }
    }
  }
  .mobile{
    display: none;
  }

  @media screen and (max-width: 767px) {
    .mobile{
      display: block;
    }
    .container {
      display: block;
    }
    .small-text {
      &.mobile{
        font-size: 12px;
        font-family: futura-pt,Arial,Helvetica,sans-serif;
        padding: .2em .3em;
      }
    }
    .h1{
      font-size: 28px;
      margin-right: 7px;
      font-weight: 700;
    }
    .middle-title .like {
      top: 16px;
    }
    .code-ask{
      justify-content: space-between;
    }
    .stars{
      &.mobile{
        display: flex;
        .star{
          margin: 0 5px;
        }
      }
    }
  }
</style>
<style>
  .tooltip {
    display: block !important;
    z-index: 10000;
  }
  .tooltip .popover-close{
    cursor: pointer;
    opacity: 0.5;
    position: absolute;
    top: 5px;
    right: 10px;
  }
  .tooltip .popover-head{
    text-align: center;
  }
  .tooltip .popover-subtitle{
    font-size: 11px;
    text-transform: uppercase;
    text-align: center;
    padding: 6px 15px;
  }
  .tooltip .popover-text{
    border: 1px solid #fff;
    text-align: center;
    padding: 13px 30px;
  }
  .tooltip .popover-text span{
    cursor: pointer;
    font-size: 14px;
    text-transform: uppercase;
    padding: 0 10px;
  }
  .tooltip .popover-footer{
    cursor: pointer;
    font-size: 11px;
    text-align: center;
    text-transform: uppercase;
    padding: 16px 0 0 0;
  }
  #popover_addToCard.tooltip .tooltip-inner {
    background: black;
    color: white;
  }
  .tooltip .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }
  .tooltip[x-placement^="top"] {
    margin-bottom: 5px;
  }
  .tooltip[x-placement^="top"] .tooltip-arrow {
    border-width: 5px 5px 0 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    bottom: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
  }
  .tooltip[x-placement^="bottom"] {
    margin-top: 5px;
  }
  .tooltip[x-placement^="bottom"] .tooltip-arrow {
    border-width: 0 5px 5px 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
    top: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
  }
  .tooltip[x-placement^="right"] {
    margin-left: 5px;
  }
  .tooltip[x-placement^="right"] .tooltip-arrow {
    border-width: 5px 5px 5px 0;
    border-left-color: transparent !important;
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
    left: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
  }
  .tooltip[x-placement^="left"] {
    margin-right: 5px;
  }
  .tooltip[x-placement^="left"] .tooltip-arrow {
    border-width: 5px 0 5px 5px;
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    right: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
  }
  .tooltip.popover .popover-inner {
    background: #f9f9f9;
    color: black;
    padding: 22px 12px;
    border-radius: 5px;
    box-shadow: 0 5px 30px rgba(0,0,0, 0.1);
  }
  .tooltip.popover .popover-arrow {
    border-color: #090909;
  }
  .tooltip[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }
  .tooltip[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
  #popover_question-block, #popover_notify-block, #popover_notifysize-block, #popover_videoconsult-block{
    max-width: 230px;
  }
  #popover_question-block .popover-inner,
  #popover_notify-block .popover-inner,
  #popover_notifysize-block .popover-inner,
  #popover_videoconsult-block .popover-inner{
    background: #F4F4F4;
  ;
  }
  #popover_question-block .popover-arrow,
  #popover_notify-block .popover-arrow,
  #popover_notifysize-block .popover-arrow,
  #popover_videoconsult-block .popover-arrow {
    border-color: #F4F4F4;
  }
  #popover_question-block .popover-question-head,
  #popover_notifysize-block .popover-head,
  #popover_notify-block .popover-head,
  #popover_videoconsult-block .popover-head {
    text-transform: uppercase;
    font-size: 11px;
    padding-bottom: 10px;
  }
  #popover_question-block textarea {
    height: auto;
    padding-top: 10px;
  }
  #popover_question-block button,
  #popover_notify-block button,
  #popover_notifysize-block button,
  #popover_videoconsult-block button {
    width: 100%;
  }
  #popover_question-block .popover-question-footer button,
  #popover_notify-block .popover-footer button,
  #popover_notifysize-block .popover-footer button,
  #popover_videoconsult-block .popover-footer button  {
    display: flex;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
  }
  #popover_question-block .popover-question-footer button span,
  #popover_notify-block .popover-footer button span,
  #popover_notifysize-block .popover-footer button span,
  #popover_videoconsult-block .popover-footer button span {
    padding-right: 10px;
  }
  #popover_videoconsult-block .popover-videoconsult-inputBlock,
  #popover_notify-block .popover-notifysubtitle,
  #popover_notifysize-block .popover-notifysizesubtitle,
  #popover_question-block .popover-questionsubtitle
  {
    margin-bottom: 8px;
  }
  #popover_videoconsult-block .popover-footer.popover-videoconsult-footer,
  #popover_notify-block .popover-footer.popover-notify-footer,
  #popover_notifysize-block .popover-footer.popover-notifysize-footer
  {
    padding: 0;
  }
  #popover_videoconsult-block .popover-footer button,
  #popover_notify-block .popover-footer button,
  #popover_notifysize-block .popover-footer button
  {
    height: 50px;
  }

  .notify .popover-notify, .notifysize .popover-notifysize{
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .notify .popover-notify {
    white-space: nowrap;
  }
  #popover_notify-block .popover-text, #popover_notifysize-block .popover-text {
    border: none;
    text-align: left;
    padding: 0;
  }
  .addToCardButton .trigger{
    display: block !important;
    width: 100% !important;
  }
  .fatty{
    font-weight: bold;
  }
  .current-sku {
    border: 2px solid #000;
  }
  .block-stok{
    margin-top: 10px;
    color: #1c6b27;
    display: flex;
  }
  .block-stok:before {
    display: block;
    content: "";
    background: url(/images/check.svg) no-repeat 50% #1c6b27;
    background-size: 12px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 0.5em;
  }
</style>
<style lang="scss">
.layout-product-youtube {
  position: relative;
  margin-top: 30px;

  @media screen and (max-width: 767px) {
    iframe {
      height: 300px;
    }
  }
  @media screen and (min-width: 768px) {
    iframe {
      height: 600px;
    }
  }
}
</style>
