<template>
  <transition name="slide-fade">
    <div v-if="value" class="layout-info-content-window-delivery abs">
      <div class="abs" @click="close"></div>
      <div class="window">
        <div class="header">
          <div class="small-title">
            <slot name="headers"></slot>
          </div>
          <div class="close" @click="close">
            <img src="@/assets/icons/close.svg" alt="close" />
          </div>
        </div>
        <div class="content">

          <div class="content-wrapper">
            <vuescroll :ops="ops">
              <div class="content-window">
                <div class="content-header"></div>
                <div class="content-body" v-html="this.$store.state.langs.DELIVERY_INFO"></div>
                <div class="content-footer"></div>
              </div>
            </vuescroll>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
    import vuescroll from 'vuescroll/dist/vuescroll-native'
export default {
    components: {
        vuescroll
    },
  props: {
    value: {
      type: Boolean,
      default() {
        return false
      }
    },
  },
  data() {
      return {
          loading: false,
      }
  },
  methods: {
    close() {
      this.$emit('input', false)
    },
  },
  created(){},
  mounted(){},
  computed: {},
  watch: {}
}
</script>

<style lang="scss" scoped>
.layout-info-content-window-delivery {
  position: fixed;
  z-index: 999;
  text-align: left;
  font-weight: 400;
}
.content-header, .content-footer{
  margin: 17px 0;
}
.window {
  background: #ffffff;
  box-shadow: -170px 4px 124px rgba(0, 0, 0, 0.08);
  width: 32em;
  max-width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: 2.5em;
  display: flex;
  flex-direction: column;
  & > .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    & > .ui-button {
      margin-top: auto;
      align-self: flex-start;
      width: 190px;
    }
  }
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .close {
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      transform: rotate(90deg);
    }
  }
}

.content-wrapper {
  margin-top: 1em;
  height: 35em;
  .colors {
    display: grid;
    /*grid-template-columns: repeat(4, 1fr);*/
    grid-template-columns: repeat(auto-fit, 12em);
    grid-gap: 3em;
    .item{
      padding: 5px 20px 30px;
      &.active{
        border: 1px solid rgba(0,0,0,0.1);
        box-sizing: border-box;
      }
      .color{
        height: 7em;
      }
      label{
        display: grid;
        grid-template-columns: min-content auto;
        grid-gap: 1em;
        align-items: center;
      }
      .price{
        padding-left: 33px;
      }
    }
  }
}

.slide-fade-enter-active {
  transition: all 0.2s cubic-bezier(1, 0.8, 0.5, 1);
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
@media screen and (max-width: 767px) {
  .window {
    width: 100%;
    padding: 2.5em 10px;
  }
  .content-wrapper
    .colors {
      grid-gap: 1em;
  }
}
</style>
