<template>
  <div class="layout-header">
    <div class="header-wrapper">
      <div class="container">
        <div class="left align-left">
          <ui-finder></ui-finder>
          <a title="" :href="'tel:'+phone">
            <layout-header-button reverse icon="phone.svg">
              {{ phone }}
            </layout-header-button>
          </a>
          <ui-lang-choice></ui-lang-choice>
        </div>
        <div class="center align-center">
          <ui-logo></ui-logo>
        </div>
        <div v-if="width>767" class="right align-right">
<!--          <span class="link_compare">
            <layout-header-button type="compare" :href="sitedir+this.$store.state.langs.LINK_COMPARE" :count="countcompare" icon="compare.svg">
              {{ this.$store.state.langs.COMPARE }}
            </layout-header-button>
          </span>-->
          <span class="link_favorite">
            <layout-header-button type="favorite" :href="sitedir+this.$store.state.langs.LINK_FAVORITE" :count="countfavorite" icon="heart.svg">
              {{ this.$store.state.langs.FAVORITES }}
            </layout-header-button>
          </span>
          <span class="linkButtonBasket">
            <layout-header-button type="smallbasket" :href="sitedir+this.$store.state.langs.LINK_CART" :count="countProduct" icon="bag.svg">
              {{ this.$store.state.langs.CART }}
            </layout-header-button>
          </span>
          <ui-auth :authorized="authorized"></ui-auth>
        </div>

        <template v-if="width<768">
          <div class="more" @click="openMenuMobile">
            <img src="@/assets/icons/menu-button.svg" alt="menu" :width="20" :height="15" />
          </div>
        </template>
        <template v-else>
          <div class="more" @click="openMenu">
            <img src="@/assets/icons/menu-button.svg" alt="menu" :width="20" :height="15" />
          </div>
        </template>

      </div>
    </div>
    <div v-if=" ( menuOpened && width<769 ) || ( width>768 ) " class="menu-wrapper">
      <page-layout-menu></page-layout-menu>
    </div>
  </div>
</template>

<script>
import uiLogo from '@/components/ui/ui-logo'
import uiFinder from '@/components/ui/ui-finder'
import uiAuth from '@/components/ui/ui-auth'
import uiLangChoice from '@/components/ui/ui-lang-choice'
export default {
  components: {
    uiLogo,
    uiFinder,
    uiAuth,
    uiLangChoice
  },
  props: {
      phone: null,
      authorized: {
          type: Boolean,
          default() {
              return false
          }
      },
      sitedir: {
        type: String,
        default() {
            return "/"
        }
      },
      siteid: {
        type: String,
        default() {
            return "s1"
        }
      },
  },
  data() {
    return {
      menuOpened: false,
      countProduct: 0,
      countfavorite: 0,
      countcompare: 0,
      menu: {},
      width: window.innerWidth,
    }
  },
  created(){
      this.baskekeCount();
      var cookieFavorite = this.$cookie.get('favorites');
      if( cookieFavorite ){
          var favoriteJson = JSON.parse(cookieFavorite);
          if( favoriteJson[this.siteid] ){
              for (var key in favoriteJson[this.siteid]) {
                  this.countfavorite = this.countfavorite + favoriteJson[this.siteid][key].length;
              }
          }
      }
      var cookieCompare = this.$cookie.get('compare');
      if( cookieCompare ){
          var compareJson = JSON.parse(cookieCompare);
          if( compareJson[this.siteid] ){
              for (var key in compareJson[this.siteid]) {
                  this.countcompare = this.countcompare + compareJson[this.siteid][key].length;
              }
          }
      }
      window.addEventListener('resize', this.updateWidth);
      this.$bus.$on("menuOpened", ()=>{
          this.menuOpened = false;
      });
  },
  mounted() {},
  methods: {
      async baskekeCount() {
          const  countBasketProducts  = (
                  await this.$axios({
                      url: '/ajax/basketline.php',
                      method: 'post',
                      params: {
                          SITE_ID: this.$store.state.langs.SITE_ID
                      }
                  })
              )
              if (countBasketProducts) {
                  this.countProduct = countBasketProducts.data.count
              } else {
                  console.log('countBasketProducts')
              }
      },
      openMenu(){
          this.menuOpened = !this.menuOpened
          this.$bus.$emit('global-menu', this.menuOpened)
      },
      openMenuMobile(){
          this.menuOpened = !this.menuOpened
          this.$bus.$emit('global-menu-mobile', this.menuOpened)
      },
      toggle() {
        //this.menuOpened = !this.menuOpened
        //this.$bus.$emit('global-menu', this.menuOpened)
      },
      updateWidth() {
          this.width = window.innerWidth;
      },
  }
}
</script>

<style lang="scss" scoped>
.layout-header {
  position: fixed;
  left: 0;
  width: 100%;
  top: 0;
  z-index: 500;
  background: #fff;
}
.header-wrapper {
  padding: 2em 0;
}
.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
}
.more {
  display: none;
}
.left {
  display: flex;
  & > * {
    &:not(:last-child) {
      margin-right: 1.5em;
    }
  }
}
.right {
  & > * {
    &:not(:first-child) {
      margin-left: 1.5em;
    }
  }
}
@media screen and (max-width: 767px) {
  .container {
    display: block;
  }
  .left {
    position: absolute;
    top: 3px;
    & > * {
      &:not(:first-child) {
        display: none;
      }
    }
    // display: none;
  }
  .right {
    display: grid;
    grid-template-columns: repeat(4, auto);
    text-align: left;
    justify-items: flex-start;
    gap: 0.5em;
    padding-top: 1em;
  }
  .center {
    margin-right: auto;
  }
  .ui-logo {
    font-size: 1.5em;
  }
  .button {
    flex-direction: row-reverse;
    img {
      margin-right: 0.4em;
    }
    &:not(:last-child) {
      margin-right: 0;
    }
  }
  .more {
    position: absolute;
    right: 5px;
    top: 5px;
    display: block;
    width: 3em;
    height: 3em;
    opacity: 0.6;
    z-index: 10;
  }
}
</style>
