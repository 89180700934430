<template>
  <div class="page-layout-default" :class="[{ flex }]">
      <select v-if="admin" class="adminshtucki" v-model="adminshtuki" @change="onAdminshtuki()">
        <option value default disabled selected hidden>админ штучки</option>
        <option value="clearcache">Сбросить кеш</option>
      </select>
    <layout-header :phone="phone" :authorized="authorized" :sitedir="sitedir" :siteid="siteid"></layout-header>
    <layout-loader v-show="load"/>
    <slot v-if="!load" />
    <layout-popup></layout-popup>
    <portal-target name="popup"></portal-target>
    <layout-city-choice-popup :city="city" :dadata-key="dadataKEY" @update="city = $event"></layout-city-choice-popup>
    <slot name="global-menu"></slot>
  </div>
</template>

<script>
import layoutHeader from '@/components/layout/layout-header'
import layoutPopup from '@/components/layout/layout-popup'
import layoutCityChoicePopup from '@/components/layout/layout-city-choice-popup'
export default {
  components: {
    layoutCityChoicePopup,
    layoutHeader,
    layoutPopup
  },
  props: {
      phone: null,
      clearcacheurl: null,
      admin: {
          type: Boolean,
          default() {
              return false
          }
      },
      noMenu: {
      type: Boolean,
      default() {
        return false
      }
    },
      authorized: {
        type: Boolean,
        default() {
            return false
        }
    },
      sitedir: {
        type: String,
        default() {
            return "/"
        }
    },
      siteid: {
        type: String,
        default() {
            return "s1"
        }
    },
      flex: {
      type: Boolean,
      default() {
        return false
      }
    },
      menutop: [],
  },
  data() {
    return {
      adminshtuchki:'',
      lanses:this.$store,
      city: null,
      dadataKEY: 'b0ee60baf097afc5d01864c91233b0ef69a0d56e',
      load: true,
    }
  },
  created(){
      this.load=false
  },
  mounted() {
      this.$bus.$emit('top-menus', this.menutop.sort((a, b) => { return a.SORT - b.SORT }));

      //document.addEventListener("DOMContentLoaded", () => {
      //    setTimeout(() => this.load=false, 1000);
          //this.load=false;
      //});


     // this.$bus.$emit('top-menus', this.menutop);
    //this.city = this.$cookie.get('vs-city')
    //this.fetchCity()
    //this.fetchViewAutoContent()
  },
  methods: {
      onAdminshtuki(){
        if(this.clearcacheurl && this.adminshtuki == "clearcache"){
            location.href = this.clearcacheurl;
        }
      },
    /*async fetchCity() {
      if (!this.city) {
        const { location } = (
          await this.$axios({
            url: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/iplocate/address',
            method: 'get',
            headers: {
              Authorization: 'Token ' + this.dadataKEY
            }
          })
        ).data
        if (location) {
          this.city = location.data.city
          //this.$cookie.set('vs-city', this.city)
        } else {
          console.log('Запросим город')
        }
      }
    },*/
    /*fetchViewAutoContent() {
        var objectBody = document.querySelector('body');
        var flagActive = false;
        objectBody.onscroll = () => {
            if (pageYOffset > 20 && !flagActive) {
                var elements = document.querySelectorAll(".viewautocontent");
                flagActive = true;
                if (elements.length > 0) {
                    elements.forEach((item) => {
                        var load = item.querySelector('.bloader');
                        if (load != "undefined" && load != null) {
                            var url = item.dataset.url;
                            if(typeof url == "undefined" || url == null || url == ""){
                                item.innerHTML = "";
                                return false;
                            }
                            this.$axios({
                                url: url,
                                method: 'post'
                            }).then(response => {
                                if(response.status==200){
                                    item.innerHTML = response.data;
                                }
                            })
                        }
                    })
                }
            }
        }
    },*/
  }
}
</script>

<style lang="scss" scoped>
.page-layout-default {
  position: relative;
  padding-top: calc(var(--header-height) + 3em);
  overflow: hidden;
  &.flex {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
}
.portal-place {
  position: fixed;
  z-index: 501;
  background: #ccc;
}
.bloader, .bloader:before, .bloader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out; }

.bloader {
  color: #a39f9e;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s; }

.bloader:before, .bloader:after {
  content: '';
  position: absolute;
  top: 0; }

.bloader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s; }

.bloader:after {
  left: 3.5em; }

@-webkit-keyframes load7 {
  0%, 80%, 100% {
    -webkit-box-shadow: 0 2.5em 0 -1.3em;
    box-shadow: 0 2.5em 0 -1.3em; }
  40% {
    -webkit-box-shadow: 0 2.5em 0 0;
    box-shadow: 0 2.5em 0 0; } }

@keyframes load7 {
  0%, 80%, 100% {
    -webkit-box-shadow: 0 2.5em 0 -1.3em;
    box-shadow: 0 2.5em 0 -1.3em; }
  40% {
    -webkit-box-shadow: 0 2.5em 0 0;
    box-shadow: 0 2.5em 0 0; }
}
.adminshtucki{
  position: fixed;
  top: 0;
  left: 0;
  display: inline-block;
  z-index: 100000;
  padding: 5px;
  border-radius: 8px;
  cursor: pointer;
  width: 250px;
  height: 25px;
}
@media screen and (max-width: 767px) {
  .page-layout-default {
    padding-bottom: 10px;
    padding-top: 93px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

</style>
