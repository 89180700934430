// Миксин для работы со всеми страницами
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history'
})

export default {
  router,
  components: {
    'ui-feedback-stat-become-our-supplier': () => import('@/components/ui/ui-feedback-stat-become-our-supplier')
  },
  mounted() {
    setTimeout(() => {
      const allImagesWithDataSrc = document.querySelectorAll('img[data-src]')
      allImagesWithDataSrc.forEach(image => {
        image.setAttribute('src', image.getAttribute('data-src'))
      })
    }, 800)
  }
}
