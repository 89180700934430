<template>
    <svg @click="compares(ID,IBLOCK_ID)" width="21" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <clipPath id="clip0">
                <rect id="svg_1" fill="black" height="18" width="18"/>
            </clipPath>
        </defs>
        <g>
            <rect fill="none" id="canvas_background" height="18" width="18" y="-1" x="-1"/>
        </g>
        <g>
            <g id="svg_2" clip-path="url(#clip0)">
                <path id="svg_3" fill="black" d="m1.50068,15.5997l2.39996,0c0.16567,0 0.3,-0.1343 0.3,-0.3l0,-4.1999c0,-0.1657 -0.13433,-0.3 -0.3,-0.3l-2.39996,0c-0.16567,0 -0.3,0.1343 -0.3,0.3l0,4.1999c0,0.1657 0.13433,0.3 0.3,0.3zm0.29999,-4.1999l1.79998,0l0,3.5999l-1.79998,0l0,-3.5999z"/>
                <path id="svg_4" fill="black" d="m17.7002,16.7997l-1.5552,0c-0.1252,-0.3594 -0.4642,-0.6002 -0.8448,-0.6002c-0.3805,0 -0.7195,0.2408 -0.8447,0.6002l-2.5104,0c-0.1253,-0.3594 -0.4642,-0.6002 -0.8448,-0.6002c-0.3805,0 -0.7195,0.2408 -0.8447,0.6002l-2.51046,0c-0.12524,-0.3594 -0.4642,-0.6002 -0.84476,-0.6002c-0.38056,0 -0.71952,0.2408 -0.84476,0.6002l-2.51041,0c-0.12524,-0.3594 -0.4642,-0.6002 -0.84476,-0.6002c-0.38056,0 -0.71952,0.2408 -0.84477,0.6002l-1.5552,0c-0.16567,0 -0.29999,0.1344 -0.29999,0.3c0,0.1657 0.13432,0.3 0.29999,0.3l1.5552,0c0.12525,0.3595 0.46421,0.6003 0.84477,0.6003c0.38056,0 0.71952,-0.2408 0.84476,-0.6003l2.51041,0c0.12524,0.3595 0.4642,0.6003 0.84476,0.6003c0.38056,0 0.71952,-0.2408 0.84476,-0.6003l2.51046,0c0.1252,0.3595 0.4642,0.6003 0.8447,0.6003c0.3806,0 0.7195,-0.2408 0.8448,-0.6003l2.5104,0c0.1252,0.3595 0.4642,0.6003 0.8447,0.6003c0.3806,0 0.7196,-0.2408 0.8448,-0.6003l1.5552,0c0.1657,0 0.3,-0.1343 0.3,-0.3c0,-0.1656 -0.1343,-0.3 -0.3,-0.3l0,0zm-14.99975,0.6c-0.16568,0 -0.3,-0.1343 -0.3,-0.3c0,-0.1656 0.13432,-0.3 0.3,-0.3c0.16567,0 0.29999,0.1344 0.29999,0.3c0,0.1657 -0.13432,0.3 -0.29999,0.3zm4.19993,0c-0.16567,0 -0.3,-0.1343 -0.3,-0.3c0,-0.1656 0.13433,-0.3 0.3,-0.3c0.16567,0 0.29999,0.1344 0.29999,0.3c0,0.1657 -0.13432,0.3 -0.29999,0.3zm4.19992,0c-0.1657,0 -0.3,-0.1343 -0.3,-0.3c0,-0.1656 0.1343,-0.3 0.3,-0.3c0.1657,0 0.3,0.1344 0.3,0.3c0,0.1657 -0.1343,0.3 -0.3,0.3zm4.1999,0c-0.1656,0 -0.2999,-0.1343 -0.2999,-0.3c0,-0.1656 0.1343,-0.3 0.2999,-0.3c0.1657,0 0.3,0.1344 0.3,0.3c0,0.1657 -0.1343,0.3 -0.3,0.3z"/>
                <path id="svg_5" fill="black" d="m5.40039,3.89985l0,11.39985c0,0.1656 0.13433,0.3 0.3,0.3l2.39997,0c0.16567,0 0.29999,-0.1344 0.29999,-0.3l0,-11.39985c0,-0.16567 -0.13432,-0.3 -0.29999,-0.3l-2.39997,0c-0.16567,0 -0.3,0.13433 -0.3,0.3zm0.59999,0.29999l1.79998,0l0,10.79986l-1.79998,0l0,-10.79986z"/>
                <path id="svg_6" fill="black" d="m9.90058,15.5998l2.39992,0c0.1657,0 0.3,-0.1344 0.3,-0.3l0,-14.9998c0,-0.16568 -0.1343,-0.3 -0.3,-0.3l-2.39992,0c-0.16567,0 -0.29999,0.13432 -0.29999,0.3l0,14.9998c0,0.1656 0.13432,0.3 0.29999,0.3zm0.30002,-14.99981l1.7999,0l0,14.39981l-1.7999,0l0,-14.39981z"/>
                <path id="svg_7" fill="black" d="m14.1003,15.5998l2.3999,0c0.1657,0 0.3,-0.1343 0.3,-0.3l0,-7.19988c0,-0.16567 -0.1343,-0.29999 -0.3,-0.29999l-2.3999,0c-0.1657,0 -0.3,0.13432 -0.3,0.29999l0,7.19988c0,0.1657 0.1343,0.3 0.3,0.3zm0.3,-7.19988l1.7999,0l0,6.59988l-1.7999,0l0,-6.59988z"/>
            </g>
            <rect id="svg_9" height="9.979774" width="0.969695" y="4.412258" x="6.191927" stroke-width="1.5" :stroke="[COMPARE ? '#000' : '']" fill="none"/>
            <rect id="svg_10" height="13.737341" width="1.494946" y="0.695096" x="10.313129" stroke-width="1.5" :stroke="[COMPARE ? '#000' : '']" fill="none"/>
            <rect id="svg_11" height="5.898976" width="1.010099" y="8.573865" x="14.717159" stroke-width="1.5" :stroke="[COMPARE ? '#000' : '']" fill="none"/>
            <rect id="svg_12" height="3.0707" width="1.13131" y="11.523353" x="2.03032" stroke-width="1.5" :stroke="[COMPARE ? '#000' : '']" fill="none"/>
        </g>
    </svg>

</template>
<script>
    export default {
        props: {
            ID: '',
            IBLOCK_ID: ''
        },
        data(){
            return {
                COMPARE: false,
                site_id: ""
            }
        },
        methods: {
            compares(id,iblock_id) {
                this.COMPARE = !this.COMPARE;
                var cookieCompare = this.$cookie.get('compare');
                var compareJson = {};
                if( cookieCompare ){
                    compareJson = JSON.parse(cookieCompare);
                    if( this.COMPARE ) { //add
                        if( !compareJson[this.site_id] ){
                            compareJson[this.site_id]={};
                            compareJson[this.site_id][this.IBLOCK_ID]=[];
                            compareJson[this.site_id][this.IBLOCK_ID].push(this.ID);
                        }
                        if( !compareJson[this.site_id][this.IBLOCK_ID] ){
                            compareJson[this.site_id][this.IBLOCK_ID]=[];
                            compareJson[this.site_id][this.IBLOCK_ID].push(this.ID);
                        }
                        var searchId = compareJson[this.site_id][this.IBLOCK_ID].find(el => el === this.ID);
                        if( !searchId ){
                            compareJson[this.site_id][this.IBLOCK_ID].push(this.ID);
                        }
                    }else{ // remove
                        compareJson[this.site_id][this.IBLOCK_ID] = compareJson[this.site_id][this.IBLOCK_ID].filter((n) => {return n != this.ID});
                    }
                }else{
                    compareJson[this.site_id]={};
                    compareJson[this.site_id][this.IBLOCK_ID]=[];
                    compareJson[this.site_id][this.IBLOCK_ID].push(this.ID);
                }
                this.$cookie.set('compare', JSON.stringify(compareJson))
                var cuntCompare = 0;
                for (var key in compareJson[this.site_id]) {
                    cuntCompare = cuntCompare + compareJson[this.site_id][key].length;
                }
                this.$bus.$emit("compare", cuntCompare);
            }
        },
        created(){
            this.site_id = this.$store.state.langs.SITE_ID;
            var cookieCompare = this.$cookie.get('compare');
            if( cookieCompare ){
                var compareJson = JSON.parse(cookieCompare);
                if(compareJson[this.site_id] && compareJson[this.site_id][this.IBLOCK_ID] && compareJson[this.site_id][this.IBLOCK_ID].find(el => el === this.ID)){
                    this.COMPARE = true;
                }else{
                    this.COMPARE = false;
                }
            }
        },
    }
</script>
<style lang="scss" scoped>

</style>