<template>
    <div class="section-size">

        <div
                v-for="(offer, index) in offers"
                :key="index"
                :class="{hidden: index > 6 && hidden}"
        >
            <div v-if="offer.PROPERTIES[ langs.CODE_SIZE ]">
                {{ offer.PROPERTIES[ langs.CODE_SIZE ].VALUE }}
            </div>
          <div><span v-if="Number(offer.CATALOG_QUANTITY) > 0 "> {{ langs.STOCK }} {{ offer.CATALOG_QUANTITY }} </span></div>
            <div>
                {{ formatPrice( offer.MIN_PRICE.DISCOUNT_VALUE ) }}
                <!--{{  new Intl.NumberFormat('ru-RU', {minimumFractionDigits: 0, style: 'currency', currency: langs.CURRENCY }).format(Math.round(offer.MIN_PRICE.DISCOUNT_VALUE)) }}-->
            </div>
            <div>
                <ui-button @click="addToCard(offer.ID)" small fill>{{ langs.ADD_TO_CART }}</ui-button>
            </div>
        </div>

        <div v-if="offers.length>6 && hidden">
            <span class="showMoreButton" @click="showMoreUp">
                {{ this.$store.state.langs.SHOW_MORE }}
            </span>
        </div>
        <div v-if="offers.length>6 && !hidden">
            <span class="showMoreButton" @click="showMoreDown">
                {{ this.$store.state.langs.SHOW_SMALL }}
            </span>
        </div>

    </div>
</template>

<script>
    export default {
        props: {
            offers: {}
        },
        data() {
            return {
                langs:{},
                hidden:true
            }
        },
        methods:{
            addToCard(id){
                this.loading = true;

                this.$axios({
                    url: '/ajax/index.php?id='+id+'&add_basket=Y'+'&SITE_ID='+this.$store.state.langs.SITE_ID, method: 'post'
                }).then(response => {
                    if (response.status == 200) {
                        this.loading = false;
                        this.$notify({
                            group: 'add_basket',
                            title: 'add to basket',
                            text: this.name,
                            type: 'success'
                        });
                        //var basketCount = document.querySelector('.linkButtonBasket .count');
                        var count = Number(response.data.productCount);
                        this.$bus.$emit("smallbasket", count);
                    }
                }).catch(e => {
                    this.$notify({
                        group: 'add_basket',
                        title: 'error',
                        text: e,
                        type: 'error'
                    });
                    console.log('Error: ' + e)
                })

            },
            formatPrice(price) {
                //return Math.ceil(Number(price)).toLocaleString() + " " + this.$store.state.langs.CURRENCY_SING
                return this.$store.state.langs.CURRENCY_SING + " " +Math.ceil(Number(price)).toLocaleString();
            },
            showMoreUp(){
                this.hidden = false
            },
            showMoreDown(){
                this.hidden = true
            }
        },
        created() {
            this.langs = this.$store.state.langs
        },
        mounted() {
            //console.log("section size", this.offers );
        },
    }
</script>

<style lang="scss" scoped>
    .showMoreButton{
        font-weight: bold;
        text-decoration: underline;
        margin-top: 11px;
        display: inline-block;
        cursor: pointer;
    }
    .section-size {
        & > * {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr auto;
            align-items: center;
            padding: 0.5em 0.5em;
            &:nth-child(2n) {
                background: rgba(0, 0, 0, 0.03);
            }
        }
    }
    .hidden{
        display: none;
    }
</style>
