<template>
    <transition enter-active-class="animated zoomIn" leave-active-class="animated zoomOut">
      <div v-if="opened" class="auth-popup abs">
        <img src="@/assets/icons/close.svg" class="close" alt="close" @click="close" />
        <form action="#" @submit.prevent="submit">

            <h2 v-if="registr"> REGISTR </h2>
            <h2 v-else-if="remind"> REMIND </h2>
            <h2 v-else> AUTH </h2>

            <input v-if="registr || remind" @input="focus" autofocus class="auth" v-model="formdata.email" name="email" :placeholder="this.$store.state.langs.EMAIL_TITLE" type="email" autocomplete="useremail" />
            <input v-else-if="!remind" @input="focus" autofocus class="auth" v-model="formdata.login" name="login" :placeholder="this.$store.state.langs.LOGIN" type="text" autocomplete="userlogin" />

            <input v-if="registr" @input="focus" autofocus class="auth" v-model="formdata.phone" name="phone" :placeholder="this.$store.state.langs.PHONE_TITLE" type="tel" autocomplete="userphone" />

            <input v-if="!remind" @input="focus" autofocus class="auth" v-model="formdata.password" name="password" :placeholder="this.$store.state.langs.PASSWORD" type="password" autocomplete="current-password" />
            <input v-if="registr" @input="focus" autofocus class="auth" v-model="formdata.confirm_password" name="phone" :placeholder="this.$store.state.langs.CONFIRM_PASSWORD" type="password" autocomplete="current-confirm-password" />


            <div class="footer">
                <ui-button v-if="registr" @click="registr=false; focus()" class="bbutton" type="button">auth</ui-button>
                <ui-button v-else @click="registr=true; focus()" class="bbutton" type="button">registr</ui-button>
                <ui-button @click="remind=true; focus()" class="bbutton" type="button">remind</ui-button>


                <ui-button class="bsubmit" type="submit">{{ this.$store.state.langs.COME }}</ui-button>
            </div>

            <div class="blockAuthSoc">
                <a class="ausSocHref" :href="urlAuthFb()">
                    <img src="/images/socicons/facebook.svg" alt="facebook"/>
                </a>

                <a class="ausSocHref" :href="urlAuthGoogle()">
                    <img src="/images/socicons/google.svg" alt="google"/>
                </a>
            </div>
        </form>
          <notifications group="auth" />
      </div>
    </transition>
</template>

<script>
export default {
props: {
    opened: {
        type: Boolean,
        default() {
            return false
        }
    }
},
  data() {
    return {
        formdata: {login: null,password: null,email: null,phone: null,confirm_password: null },
        registr: false,
        remind: false,
        fb_url_auth: this.$store.state.langs.FB_URL_AUTH,
        fb_client_id: this.$store.state.langs.FB_CLIENT_ID,
        fb_redirect: this.$store.state.langs.FB_REDIRECT,

        google_url_auth: this.$store.state.langs.GOOGLE_URL_AUTH,
        google_client_id: this.$store.state.langs.GOOGLE_CLIENT_ID,
        google_redirect: this.$store.state.langs.GOOGLE_REDIRECT

    }
  },
  created(){
    if(this.opened) {
        document.body.style.overflow = 'hidden'
    }

      this.$notify({
          group: 'cartcheckout',
          title: "ssss",
          text: "cartcheckout",
          type: 'error'
      });
  },
  methods: {
    close() {
      this.opened = false
      this.$parent.opened = false
      document.body.style.overflow = 'auto'
    },
    focus(){
        this.errorView(false);
    },
    urlAuthFb(){
        return this.fb_url_auth+"?client_id="+this.fb_client_id+"&redirect_uri="+this.fb_redirect+"&response_type=code&action=fb&scope=email"
    },
      urlAuthGoogle(){
          return this.google_url_auth+"?client_id="+this.google_client_id+"&redirect_uri="+this.google_redirect+"&response_type=code&scope=https://www.googleapis.com/auth/userinfo.email&state=google"
      },
      validEmail: function (email) {
          var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(email);
      },
      errorView(f){
        var authInputList = document.querySelectorAll(".auth");
        if(f) {
            authInputList.forEach(function (authInput) {
                authInput.classList.add("animat", "animated", "shake");
            });
        }else{
            authInputList.forEach(function (authInput) {
                authInput.classList.remove("animat","animated","shake");
            });
        }
      },
    submit: function (e) {
        if( ( !this.remind && !this.registr && this.formdata.login==null ) || ( !this.remind && this.formdata.password==null ) ) {
            this.$notify({group: 'auth',title: this.$store.state.langs.ERROR,text: this.$store.state.langs.ERROR_AUTH,type: 'error'});
            return false;
        }
        if(this.registr && !this.validEmail(this.formdata.email)){
            this.errorView(true);
            return false;
        }
        if(this.registr && this.formdata.password!=this.formdata.confirm_password){
            this.errorView(true);

            this.$notify({
                group: 'auth',
                title: "error",
                text: "Password mismatch",
                type: 'error'
            });

            return false;
        }
        var url = '/ajax/index.php?auth=Y&login='+this.formdata.login+'&password='+this.formdata.password;
        if(this.registr){
            url = '/ajax/index.php?registr=Y&email='+this.formdata.email+'&phone='+this.formdata.phone+'&password='+this.formdata.password;
        }
        if(this.remind){
            url = '/ajax/index.php?remind=Y&email='+this.formdata.email;
        }
      this.$axios.post(url, {})
          .then((response) => {
              if(response.status != 200 || !response.data.success){
                  this.errorView(true);
                  this.$notify({
                      group: 'auth',
                      title: response.data.toast.className,
                      text: response.data.toast.message,
                      type: 'error'
                  });
              }else if (response.data.success==true){
                  document.location.reload();
              }
          }).catch((error) => {
              this.$notify({
                  group: 'auth',
                  title: this.$store.state.langs.ERROR,
                  text: this.$store.state.langs.ERROR_AUTH,
                  type: 'error'
              });
              console.log( error )
          });

      }
  }
}
</script>

<style lang="scss" scoped>
.ui-auth {
    position: relative;
    display: inline-block;
}
.bbutton{
    margin-top: 20px;
}
.footer{
    display: flex;
    justify-content: space-between;
}
.blockAuthSoc{
    display: flex;
    margin-top: 20px;
}
.blockAuthSoc a{
    width: 45px;
    display: inline-block;
    margin-right: 10px;
}
h2{
    text-align: left;
    font-weight: 700;
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
}
.auth-popup input {
  font-family: inherit;
  text-transform: uppercase;
  letter-spacing: 0.09em;
  background: none;
  border: none;
  border-bottom: solid 1px;
  border-radius: 0;
  max-width: 320px;
}
.auth-popup {
  background: rgba(255, 255, 255, 1);
  position: fixed;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
}
.auth-popup form{
    width: 100%;
    max-width: 320px;
}
.close {
  width: 1.2em;
  height: 1.2em;
  position: absolute;
  right: 2em;
  top: 2em;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    transform: rotate(90deg);
  }
}
.hpersonal{}
.hpersonal .hpersonal-icon{}
.bsubmit{
    margin-top: 20px;
}
.auth.animat{
    animation-duration: 1000ms;
    animation-iteration-count: 1;
    color:red;
}
</style>
