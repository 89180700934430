<template>
  <div class="section-categories">
    <div class="container">
      <div class="breadcrumb-sorted-block">
        <ui-breadcrumbs></ui-breadcrumbs>
      </div>
      <div class="header">
        <div class="great-title">
          <h1>{{ this.$store.state.langs.TITLE_CATEGORY }}</h1>
        </div>
      </div>
      <div class="tabs">
        <div
          v-for="(tab, index) in tabs"
          :key="index"
          :class="[{ active: index === current }]"
          class="tab xs-title"
          @click="current = index"
        >
          {{ tab.name }}
        </div>
      </div>
      <div v-for="(tab, index) in tabs" v-show="index === current" :key="index" class="tab-content" :class="[tab.id]">

        <a title="" v-if="category[tab.id] && tab.id=='categories'" v-for="(categoryItem, index) in category[tab.id]"
           :style="'grid-column: span '+sitedir+categoryItem.PROPERTY_COLUMNS_VALUE"
           :href="sitedir+categoryItem.PROPERTY_LINK_VALUE">
          <layout-category-card>
            <img slot="image" v-lazy="categoryItem.PREVIEW_PICTURE" :alt="categoryItem.NAME">
            <span slot="name">{{ categoryItem.NAME }}</span>
            <span slot="subname">{{ categoryItem.PREVIEW_TEXT }}</span>
          </layout-category-card>
        </a>

        <a title="" v-else-if="category[tab.id] && tab.id=='brands'" v-for="(categoryBrand, index) in category[tab.id]"
           :href="sitedir+categoryBrand.DETAIL_PAGE_URL">
          <img v-lazy="categoryBrand.PREVIEW_PICTURE" :alt="categoryBrand.NAME">
        </a>

      </div>
    </div>
  </div>
</template>

<script>
import layoutCategoryCard from '@/components/layout/category/layout-category-card'
export default {
  components: {
      layoutCategoryCard
  },
  props: {
      iblock_id: ''
  },
  data() {
    return {
      current: 0,
      tabs: [
        {
          name: '-',
          id: 'categories'
        }
      ],
      category: {},
      sitedir: '',
      width: 0
    }
  },
    created(){
        window.addEventListener('resize', this.updateWidth);
        this.updateWidth();
        this.sitedir = this.$store.state.langs.SITE_DIR
        this.categories()
    },
    mounted() {
        this.tabs[0].name=this.$store.state.langs.BY_CATEGORY
        //this.tabs[1].name=this.$store.state.langs.BY_BRANDS
    },
    methods: {
        async categories() {
            const responseCategory  = (
                await this.$axios({
                    url: '/ajax/section-categories.php',
                    method: 'post',
                    params: {
                        width: this.width,
                        clear_cache: this.getGet('clear_cache'),
                        SITE_ID: this.$store.state.langs.SITE_ID,
                        iblock_id: this.iblock_id
                    }
                })
            )
            if (responseCategory) {
                this.category = responseCategory.data
            } else {
                console.log('responseCategory')
            }
        },
        getGet(name) {
            var searchString = new URLSearchParams(window.location.search);
            var res = searchString.get(name);
            return res;
        },
        updateWidth() {
            this.width = window.innerWidth;
        }
    }
}
</script>

<style lang="scss" scoped>
.section-categories {
  padding-bottom: 5em;
}
.categories {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1em;
}
.great-title {
  margin-bottom: 0.7em;
}
.brands {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 1em;
  & > * {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 10em;
    position: relative;
    background: var(--bg);
    &:hover {
      img {
        transform: scale(1.05);
      }
    }
    img {
      max-width: 10em;
      max-height: none;
      mix-blend-mode: darken;
      transition: 0.5s;
    }
  }
}
.tabs {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  margin-bottom: 1.5em;
  .tab {
    margin-right: 1.5em;
    margin-bottom: 0.5em;
    transition: 0.5s;
    opacity: 0.3;
    cursor: pointer;
    &.active {
      opacity: 1;
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .categories {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 767px) {
  .categories {
    display: block;
    & > * {
      margin-bottom: 1em;
      display: block;
    }
  }
  .brands {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
