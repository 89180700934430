<template>
  <div class="ui-info-right-block">
    <div class="visible" @click="opened=true">
      <div class="">
        <div class="xx-title Klarna"> or {{ this.$store.state.langs.CURRENCY_SING }}{{ formatPriceKlarna(total) }} pm (no interest) with <b>Klarna</b></div>
      </div>
    </div>

    <layout-info-content-window
            v-model="opened"
            :title="this.$store.state.langs.INFO_TITLE_WINDOW"
            :total="total"
    ></layout-info-content-window>

  </div>
</template>

<script>
import vuescroll from 'vuescroll/dist/vuescroll-native'
import layoutInfoContentWindow from '@/components/layout/layout-info-content-window'
export default {
    components: {
        vuescroll,
      layoutInfoContentWindow
    },
    props: {
      total: 0
    },
    data() {
        return {
          opened: false,
          ops: {
            vuescroll: {},
            scrollPanel: {},
            rail: {
              background: '#000',
              opacity: 0.1,
              size: '3px',
              specifyBorderRadius: '0px'
            },
            bar: {
              background: '#000',
              opacity: 1,
              size: '3px'
            }
          },
        }
    },
    methods: {
      formatPriceKlarna(price) {
        return Math.ceil(Number(price)/3).toLocaleString();
      },
    }
}
</script>

<style lang="scss" scoped>
.ui-info-right-block {
    margin-top: 0.5em;
    margin-bottom: 2em;
}
.visible {
    display: flex;
    align-items: center;
    height: 50px;
    justify-content: space-between;
    padding: 0 10px 0 0;
    cursor: pointer;
}
.xxx-title{
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    /* width: 100px; */
    height: 25px;
}
.xx-title{
  text-decoration: underline;
}
.b-next{
    font-size: 23px;
    font-weight: bold;
}
</style>
