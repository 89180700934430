<template>
  <div v-if="breadcrumbs.length" class="ui-breadcrumbs">
    <div v-html="this.breadcrumbs.join(' / ')"></div>
  </div>
</template>

<script>
export default {
    props: {
      brand: "",
      name: "",
      sect: "",
      landing: "",
    },
    data() {
        return {
            breadcrumbs: []
        }
    },
    created () {
        var arUrl = location.pathname.split('/').filter(function(el) { return el; });
        var arUrlActual = (arUrl[0] == "product") ? '/' : "/"+arUrl[0]+"/";
        this.breadcrumbs[0] = "<a href='"+ arUrlActual +"'>Home</a>";
        this.breadcrumbs[1] = "<a href='" + arUrlActual + this.sect + "/'>" + this.sect + "</a>";
        if (this.landing.length > 0 && this.landing !== this.sect && this.landing != "favorite") {
          this.breadcrumbs[2] = "<a href='" + arUrlActual + this.sect + "/" + this.landing + "/'>" + this.landing + "</a>";
          this.breadcrumbs[3] = this.name;
        } else {
          this.breadcrumbs[2] = this.name;
        }
    },
}
</script>

<style lang="scss" scoped>
.ui-breadcrumbs {
  display: flex;
  margin: 10px 0;
  & > * {
    font-size: 0.75em;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    &:not(:last-child) {
      margin-right: 0.5em;
      &:after {
        content: '/';
        margin-left: 0.5em;
      }
    }
  }
}
</style>
