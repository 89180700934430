<template>
    <div class="section-testimonials">
        <div v-if="testimonialses" :class="{ container: !page_detail }">
            <div :class="{ wrapper: !page_detail }">
                <div v-if="!page_detail" class="great-title">
                    <h2>
                        {{ this.$store.state.langs.TESTIMONIALS }}
                    </h2>
                </div>
                <div v-if="!page_detail" class="subtitle">
                    <span slot="subtitle">
                        {{ this.$store.state.langs.TESTIMONIALS_SUBTITLE }}
                    </span>
                </div>
                <div :class="{ testimonials: page_detail }">
                    <layout-own-carousel ref="slider" :xs-count="1" :count="count" :gap="gap">
                        <layout-testimonial-card :page_detail="page_detail"
                            v-for="(item, index) in testimonialses"
                            :key="index"
                            >
                            <span slot="text" v-html="item.PREVIEW_TEXT"></span>
                            <!--<img slot="pic" :src="item.PREVIEW_PICTURE" :alt="item.NAME+index">-->
                            <span slot="author">
                                {{item.NAME}}</span>
                            <span slot="date">
                                {{item.PROPERTY_DATA_VALUE}}</span>
                        </layout-testimonial-card>
                    </layout-own-carousel>

                    <div v-if="!page_detail" class="arrows abs">
                        <div class="arrow left" @click="$refs.slider.prev()">
                            <svg width="91" height="12" viewBox="0 0 91 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                        d="M0.469673 6.53033C0.176773 6.23744 0.176773 5.76256 0.469673 5.46967L5.24264 0.696699C5.53554 0.403806 6.01041 0.403806 6.3033 0.696699C6.59619 0.989593 6.59619 1.46447 6.3033 1.75736L2.06066 6L6.3033 10.2426C6.59619 10.5355 6.59619 11.0104 6.3033 11.3033C6.01041 11.5962 5.53554 11.5962 5.24264 11.3033L0.469673 6.53033ZM91 6.75H1V5.25H91V6.75Z"
                                        fill="black"
                                />
                            </svg>
                        </div>
                        <div class="arrow right" @click="$refs.slider.next()">
                            <svg width="91" height="12" viewBox="0 0 91 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                        d="M90.5303 6.53033C90.8232 6.23744 90.8232 5.76256 90.5303 5.46967L85.7574 0.696699C85.4645 0.403806 84.9896 0.403806 84.6967 0.696699C84.4038 0.989593 84.4038 1.46447 84.6967 1.75736L88.9393 6L84.6967 10.2426C84.4038 10.5355 84.4038 11.0104 84.6967 11.3033C84.9896 11.5962 85.4645 11.5962 85.7574 11.3033L90.5303 6.53033ZM0 6.75H90V5.25H0V6.75Z"
                                        fill="black"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import layoutOwnCarousel from '@/components/layout/layout-own-carousel'
    import layoutTestimonialCard from '@/components/layout/layout-testimonial-card'
    export default {
        components: {
            layoutTestimonialCard,
            layoutOwnCarousel
        },
        props: {
            page_detail: false,
            product_id: '',
            scrollcontent: {
                type: Boolean,
                default() {
                    return false
                }
            }
        },
        data() {
            return {
                count:3,
                gap:'2em',
                testimonialses: [],
                requests: true
        }
        },
        created () {
            if( this.scrollcontent ) {
                window.addEventListener('scroll', () => {
                    if (pageYOffset > 200 && this.scrollcontent) {
                        this.testimonialRequest();
                        this.scrollcontent=false;
                    }
                });
            }else{
                this.testimonialRequest();
            }
            if(this.page_detail){
                this.count=2;
                this.gap='1em';
            }
            //console.log("section created page_detail", this.page_detail);
        },
        methods: {
            async testimonialRequest() {
                if(this.requests) {
                    this.requests = false;
                    const responseTestimonial = (
                        await this.$axios({
                            url: "/ajax/testimonials.php",
                            method: 'post',
                            data: {
                                prodid: this.product_id,
                                //clear_cache: this.getGet('clear_cache'),
                                SITE_ID: this.$store.state.langs.SITE_ID,
                                IBLOCK_ID: this.$store.state.langs.IBLOCK_ID_TESTIMONIALS,
                            }
                        })
                    )
                    if (responseTestimonial) {
                        this.testimonialses = responseTestimonial.data.testimonials
                        this.page_detail = responseTestimonial.data.page_detail
                    } else {
                        console.log('responseFeatures')
                    }
                }
            },
            getGet(name) {
                var searchString = new URLSearchParams(window.location.search);
                var res = searchString.get(name);
                return res;
            }
        },
    }
</script>

<style lang="scss" scoped>
    .section-testimonials {
        padding-bottom: 5em;
    }
    .wrapper {
        background: var(--bg);
        text-align: center;
        padding: 4em;
    }
    .testimonials {
        margin-top: 3em;
        position: relative;
    }
    .arrows {
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 120%;
        left: -10%;
    }
    .arrow {
        cursor: pointer;
        pointer-events: all;
    }
    @media screen and (max-width: 767px) {
        .arrows {
            display: none;
        }
        .wrapper {
            padding: 2em;
        }
    }
</style>
