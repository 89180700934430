<template>
  <div class="layout-post-card">
    <div class="image">
      <slot name="pic"></slot>
    </div>
    <div class="xs-title"><slot name="title"></slot></div>
    <div class="caption"><slot name="desc"></slot></div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.layout-post-card {
  cursor: pointer;
  &:hover {
    .image {
      img {
        transform: scale(1.1);
      }
    }
  }
}
.image {
  padding-bottom: 100%;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    left: 0;
    transition: 1s;
    position: absolute;
    top: 0;
    object-fit: cover;
  }
}
.xs-title {
  margin: 1.5em 0 0.5em;
}
.caption {
  opacity: 0.6;
}
</style>
