<template>
  <div v-if="opened" class="layout-popup abs">
    <div class="overlay abs" @click="close"></div>
    <div class="window">
      <div class="close" @click="close"><img src="@/assets/icons/close.svg" alt="close" /></div>
      <form>
        <div class="great-text">{{ data.title }}</div>
        <!--  -->
        <div class="fields">
          <div v-for="(field, index) in data.fields" :key="index" class="field">
            <label class="xs-title">{{ field.label }} {{ field.required ? '*' : '' }}</label>
            <input
              :data-mask="field.mask"
              :type="field.type || 'text'"
              :name="field.name"
              :required="field.required"
              :placeholder="field.placeholder"
            />
          </div>
        </div>
        <!--  -->
        <ui-button fill>{{ data.button }}</ui-button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      opened: this.$route.query.popup || false,
      data: {
        title: 'We’re callback',
        fields: [
          {
            label: 'Your name',
            placeholder: 'Enter your name',
            type: 'text',
            required: true
          },
          {
            mask: '+7 (999) 999-99-99',
            label: 'Your phone',
            placeholder: '+7 (___) ___-__-__',
            type: 'phone',
            required: true
          }
        ],
        button: 'call me'
      }
    }
  },
  created() {
    this.$bus.$on('popup', data => {
      this.opened = true
      data ? (this.data = data) : ''
    })
  },
  mounted() {
    //this.opened ? this.initMask() : ''
  },
  methods: {
    close() {
      this.opened = false
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-popup {
  position: fixed;
  z-index: 502;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}
.overlay {
  background: rgba(0, 0, 0, 0.1);
}
.window {
  position: relative;
  z-index: 1;
  background: #fff;
  width: 31.25em;
  max-width: 100%;
  padding: 5em;
  text-align: center;
}
.field {
  margin-bottom: 1.3em;
}
.great-text {
  margin-bottom: 1.5em;
}
.ui-button {
  margin-top: 1.5em;
  width: 100%;
}
.close {
  position: absolute;
  right: 2em;
  top: 2em;
  cursor: pointer;
}
</style>
