<template>
  <div :class="[{ page_detail: 'page_detail' }, 'layout-testimonial-card']">
    <img v-if="!page_detail" class="quote" src="@/assets/big-quote.svg" alt="quote" />
    <div class="text">
      <slot name="text"></slot>
    </div>
    <div class="about">
      <!--<div class="pic">
        <slot name="pic"></slot>
      </div>-->
      <div class="about-content">
        <div class="xs-title">
          <slot name="author"></slot>
        </div>
        <div class="caption">
          <slot name="date"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        page_detail: false,
    },
    created () {
        //console.log("created page_detail", this.page_detail);
    }
}
</script>

<style lang="scss" scoped>
.layout-testimonial-card {
  text-align: left;
}
.text {
  margin-bottom: 2em;
  font-style: italic;
  font-family: 'Cormorant';
  font-size: 1.375em;
  line-height: 170%;
  position: relative;
  z-index: 2;
}
.page_detail .text{
  line-height: 27px;
  font-size: 18px;
}
.pic {
  width: 3.75em;
  height: 3.75em;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 1em;
  img {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
  }
}
.quote {
  height: 12em;
  position: relative;
  margin-bottom: -8em;
}
.about {
  display: flex;
  align-items: center;
}
.caption {
  opacity: 0.6;
}
</style>
