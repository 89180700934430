<template>
  <div :class="[{ noRow }]" class="layout-contact-block-row">
    <div class="subtitle label">{{ $attrs.name }}</div>
    <div class="subtitle value"><slot></slot></div>
  </div>
</template>

<script>
export default {
  props: {
    noRow: {
      type: Boolean,
      default() {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-contact-block-row {
  display: grid;
  grid-template-columns: 5em auto;
  grid-gap: 1em;
  margin: 1em 0;
  &.noRow {
    display: block;
    .label {
      margin-bottom: 0.5em;
    }
  }
  & > * {
    &:nth-child(1) {
      opacity: 0.4;
    }
  }
}
</style>
