<template>
  <button :class="[{ fill }, { small }, { purchase }]" class="ui-button" @click="$emit('click')">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    purchase: {
      type: Boolean,
      default() {
        return false
      }
    },
    fill: {
      type: Boolean,
      default() {
        return false
      }
    },
    small: {
      type: Boolean,
      default() {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-button {
  font-size: 0.875em;
  text-transform: uppercase;
  border: solid 2px black;
  color: black;
  padding: 1em 1em;
  letter-spacing: 0.05em;
  display: inline-block;
  font-family: inherit;
  background: none;
  cursor: pointer;
  transition: 0.5s;
  &.fill {
    background: var(--color);
    color: #fff;
    border-color: var(--color);
  }
  &.small {
    padding: 0.3em 0.5em;
  }
  &:hover {
    transform: scale(1.01);
  }
}
</style>
