<template>
  <div v-if="breadcrumbs" class="ui-breadcrumbs">
    <div v-html="this.breadcrumbs.join(' / ')"></div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            breadcrumbs: []
        }
    },
    created () {

      let pathname = location.pathname;
      const indexOfFilterInpath = pathname.indexOf("/filter");

      if (indexOfFilterInpath != -1) {
        pathname = pathname.substring(0, indexOfFilterInpath);
      }

      var arUrl = pathname.split('/').filter(function(el) { return el; });

      if( arUrl[0].length != 2 ){
          var arUrlReversed = arUrl.reverse();
          arUrlReversed.push("/");
          arUrl = arUrlReversed.reverse();
      } else { 
        arUrl[0] = "/"+arUrl[0]+"/" 
      }
      
      arUrl.forEach((urlItem, index) => {
          if(index === 0){
              this.breadcrumbs[index] = "<a href='"+urlItem+"'>Home</a>";
          }else if(index == arUrl.length-1){
              this.breadcrumbs[index] = urlItem;
          }else{
              var urls = "/";
              arUrl.forEach((urlItems, indexx) => {
                  if(indexx===0){
                      urls = urlItems;
                  }else if(indexx != arUrl.length-1){
                      urls = urls + urlItems + "/";
                  }
              })
              this.breadcrumbs[index] = "<a href='"+urls+"'>"+urlItem+"</a>";
          }
      });
    },
}
</script>

<style lang="scss" scoped>
.ui-breadcrumbs {
  display: flex;
  margin: 10px 0;
  & > * {
    font-size: 0.75em;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    &:not(:last-child) {
      margin-right: 0.5em;
      &:after {
        content: '/';
        margin-left: 0.5em;
      }
    }
  }
}
</style>
