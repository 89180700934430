<template>
  <div class="ui-rich-color-choicer">
    <div class="visible" @click="eventMaterial()">
      <layout-loader class="abs" v-show="loadingCurrent"/>
      <div class="mini-colors">
        <div class="color active">
          <img v-if="current.DETAIL_PICTURE" :src="current.DETAIL_PICTURE_SMALL.SRC" :alt="current.NAME" />
        </div>
        <div
                v-for="(color, index) in colorFree"
                :key="index"
                class="color"
        >
          <img :src="color.DETAIL_PICTURE_SMALL.SRC" :alt="color.NAME" />
        </div>
      </div>
      <!--<span>
        {{ current.NAME_OFFER }} {{ this.$store.state.langs.IN_CATEGORY }} {{ current.CATEGORY }}<br />
        {{ this.$store.state.langs.CHOOSE_FABRIC }}
      </span>-->
      <img src="/images/more-button.svg" alt="more" />
    </div>
   <layout-filter-window v-model="opened" :allTagsArr="allTagsArr" :allPropertiesArr="allPropertiesArr" :current="current" :title="this.$store.state.langs.SELECT_MATERIAL"></layout-filter-window>
  </div>
</template>

<script>
import vuescroll from 'vuescroll/dist/vuescroll-native'
import layoutFilterWindow from '@/components/layout/layout-filter-window'
export default {
  components: {
    layoutFilterWindow,
    vuescroll
  },
  props: {
    categories: {
      type: Array,
      default() {
        return []
      }
    },
      fabrics: {
          type: Array,
          default() {
              return []
          }
      },
      fabrics_sections: {
          type: Array,
          default() {
              return []
          }
      },
  selected_category: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      loading: false,
      loadingCurrent: false,
      current: {
          DETAIL_PICTURE_SMALL: {
              SRC: "/img/no_image_available.svg"
          }
      },
      materials: {},
      reqMaterial: false,
      opened: false,
      filterProperty: '',
      filterTag: '',
      colorFree : [],
      allTagsArr: [],
      allPropertiesArr: [],
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          background: '#000',
          opacity: 0.1,
          size: '3px',
          specifyBorderRadius: '0px'
        },
        bar: {
          background: '#000',
          opacity: 1,
          size: '3px'
        }
      }
    }
  },
  methods: {
      async requestMaterial(){
          this.loading = true;
          this.reqMaterial = true;
          await this.$axios({
              url: '/ajax/materials.php',
              method: 'post',
              data: {
                  category: this.categories,
                  fabrics: this.fabrics,
                  fabrics_sections: this.fabrics_sections,
                  site_id: this.$store.state.langs.SITE_ID
              }
          }).then(response => {
              if (response.status == 200) {
                  let respon = response.data;
                  this.materials = respon.CATEGORY;
                  this.colorFree = respon.CATEGORY.slice(0, 3);
                  this.allProperties();
                  this.allTags();

              } else {
                  console.log("request materials error")
              }
          }).catch(e => {
              console.log('Error: ' + e);
          })
          this.loading = false;
          this.reqMaterial = false;
      },
      eventMaterial(){
          this.opened = true;
          this.loading = true;
          if(this.reqMaterial || Object.keys(this.materials).length>0) {
              //this.allProperties();
              //this.allTags();
          }else{
              this.requestMaterial();
          }
          this.loading = false;
      },
      apply(){
          this.$parent.apply( this.current.CATEGORY );
      },
      currentItem(currentItem){
          this.current = currentItem;
      },
      async oneRequestMaterial(){
          this.loading = true;
          await this.$axios({
              url: '/ajax/one-material.php',
              method: 'post',
              data: {
                  category: this.selected_category,
                  fabrics: this.fabrics,
                  fabrics_sections: this.fabrics_sections,
                  site_id: this.$store.state.langs.SITE_ID
              }
          }).then(response => {
              if (response.status == 200) {
                  this.current = response.data.CATEGORY[this.selected_category][0];
                  this.loadingCurrent=false;
              }
          }).catch(e => {
              console.log('Error: ' + e);
              this.loadingCurrent=false;
          });
          this.loading = false;
      },
      material(){
          return this.materials;
      },
      allProperties() {
          let array = []
          for (let item of this.materials) {
              array.push(item.COLOR)
          }
          this.allPropertiesArr = [...new Set(array)]
          this.allPropertiesArr.sort();
      },
      allTags() {
          let array = []
          for (let item of this.materials) {
              array.push(item.TIP_TKANI)
          }
          this.allTagsArr = [...new Set(array)]
          this.allTagsArr.sort();
      },
  },
  created(){
      this.loadingCurrent=true;
      this.oneRequestMaterial();
     //// this.requestMaterial();
  },
  mounted(){
      //this.currentMaterial(this.selected_category);
  },
  computed: {
    ttt() {
      //return this.materialsOrigin.find(c => c.ID === this.current.ID)

    }
  },
  watch: {
      // эта функция запускается при любом изменении вопроса
      /*opened: function (newOpened, oldOpened) {
          if(newOpened){
              this.loadingColors = true;
          }else{
              this.materials1={}
          }
      }*/
  }
}
</script>

<style lang="scss" scoped>
.ui-rich-color-choicer {
  margin-top: 0.5em;
  margin-bottom: 2em;
}
.visible {
  display: flex;
  cursor: pointer;
  align-items: center;
  position: relative;
  & > img {
    margin-left: 1em;
    cursor: pointer;
  }
}
.mini-colors {
  display: flex;
}
.color {
  width: 2em;
  height: 2em;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  margin-right: 0.5em;
  cursor: pointer;
  transition: 0.3s;
  &.active {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 1);
  }
  &:hover {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.3);
  }
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
    border-radius: inherit;
  }
}
.big-color {
  width: 6.8em;
  height: 6.8em;
  border: 5px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.92);
}
.current-color {
  display: flex;
  align-items: center;
  margin-top: 2em;
  .big-color {
    margin-right: 1em;
  }
}
.current-content {
  width: 12.5em;
  max-width: 100%;
  .caption {
    margin-top: 1em;
    opacity: 0.4;
  }
}
</style>
<style>
    .lds-dual-ring.abs{
        position: absolute !important;
    }
</style>
