<template>
  <div class="ui-info-right-block-delivery">
    <div class="visible" @click="opened=true">
      <div class="">
        <div class="xx-title">
          <slot name="xx-title"></slot>
        </div>
      </div>
    </div>
    <layout-info-content-window-delivery
        v-model="opened"
    >
      <div slot="headers"> <slot name="xx-title"></slot> </div>
    </layout-info-content-window-delivery>
  </div>
</template>

<script>
import vuescroll from 'vuescroll/dist/vuescroll-native'
import layoutInfoContentWindowDelivery from '@/components/layout/layout-info-content-window-delivery'
export default {
    components: {
        vuescroll,
      layoutInfoContentWindowDelivery
    },
    props: {},
    data() {
        return {
          opened: false,
          ops: {
            vuescroll: {},
            scrollPanel: {},
            rail: {
              background: '#000',
              opacity: 0.1,
              size: '3px',
              specifyBorderRadius: '0px'
            },
            bar: {
              background: '#000',
              opacity: 1,
              size: '3px'
            }
          },
        }
    },
    methods: {
      close() {
        this.opened=false;
      },
    }
}
</script>

<style lang="scss" scoped>
.ui-info-right-block-delivery {}
.visible {
    display: flex;
    align-items: center;
    height: 50px;
    justify-content: space-between;
    padding: 0 10px 0 0;
    cursor: pointer;
}
.xxx-title{
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    /* width: 100px; */
    height: 25px;
}
.xx-title{
  text-decoration: underline;
}
.b-next{
    font-size: 23px;
    font-weight: bold;
}
</style>
