<template>
  <div class="ui-arrows">
    <div class="arrow left" @click="$emit('prev')">
      <img src="@/assets/icons/arrow-left-button.svg" alt="arrow left" />
    </div>
    <div class="arrow right" @click="$emit('next')">
      <img src="@/assets/icons/arrow-right-button.svg" alt="arrow right" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    slider: {
      type: Object,
      default() {
        return null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-arrows {
  display: flex;
  align-items: center;
  & > * {
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 0.5em;
    }
  }
}
</style>
