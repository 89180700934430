<template>
  <div
    class="layout-own-carousel"
    :style="{
      '--size': `calc((100% - var(--gap) * ${cCount > 1 ? cCount - 1 : 1}) / ${cCount})`,
      '--gap': `${gap}`,
      '--template': `var(--size)`
    }"
    @mouseenter="mouseenter"
    @mouseleave="mouseleave"
  >
    <div class="slides-wrapper">
      <div
        class="slides"
        :style="{
          transform: `translate3d(calc((var(--size) * ${current} + (var(--gap) * ${current})) * -1),0,0)`
        }"
      >
        <slot></slot>
      </div>
    </div>
    <!--  -->
    <div v-if="slides.length && dots > 1" class="dots">
      <div
        v-for="(slide, index) in dots"
        :key="index"
        :class="[{ active: index === current }]"
        class="dot"
        @click="goTo(index)"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      default() {
        return 1
      }
    },
    xsCount: {
      type: Number,
      default() {
        return 1
      }
    },
    gap: {
      type: String,
      default() {
        return '0px'
      }
    }
  },
  data() {
    return {
      cCount: this.count,
      current: 0,
      slides: [],
      hovered: false
    }
  },
  computed: {
    length() {
      return this.slides.length
    },
    max() {
      return this.cCount > 1 ? this.length - this.cCount : this.length - 1
    },
    dots() {
      return this.max + 1
    },
  },
  mounted() {
    if (window.innerWidth < 768) {
      this.cCount = this.xsCount
    }
    setTimeout(() => {
      this.init()
    },1000);
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    mouseenter() {
      this.hovered = true
      clearInterval(this.interval)
    },
    mouseleave() {
      this.hovered = false
      this.startTimer()
    },
    goTo(index) {
      clearInterval(this.interval)
      this.current = index
      this.startTimer()
    },
    startTimer() {
      this.interval = setInterval(() => {
        this.next()
      }, 3000)
    },
      init() {
      this.slides = [...this.$el.querySelectorAll('.slides > *')]
      this.startTimer()
      this.initSwiper()
    },
    initSwiper() {
      const Hammer = require('hammerjs')
      const stage = this.$el;
      const hammertime = new Hammer(stage);
      hammertime.on('swipeleft', ev => {
        this.next()
      })
      hammertime.on('swiperight', ev => {
        this.prev()
      })
    },
    next() {
      clearInterval(this.interval)
      if (this.current !== this.max && Math.sign(this.max) > 0) {
        this.current++
      } else {
        this.current = 0
      }
      !this.hovered && Math.sign(this.max) > 0 ? this.startTimer() : ''
    },
    prev() {
      clearInterval(this.interval)
      if (this.current !== 0 && Math.sign(this.max) > 0) {
        this.current--
      } else {
        this.current = (Math.sign(this.max) > 0) ? this.max : 0
      }
      !this.hovered ? this.startTimer() : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.slides {
  display: grid;
  grid-auto-columns: var(--template);
  grid-auto-flow: column;
  align-items: center;
  transition: 0.8s;
  gap: var(--gap);
}
.slides-wrapper {
  position: relative;
  overflow: hidden;
}
.dots {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
  height: 10px;
}
.dot {
  width: 4px;
  height: 4px;
  background: currentColor;
  border-radius: 50%;
  margin: 0 4px;
  transition: 0.4s;
  cursor: pointer;
  &:hover {
    opacity: 0.4;
  }
  &.active {
    width: 10px;
    height: 10px;
  }
}
</style>
