<template>
  <div :class="[{ reverse }]" class="layout-header-button" @click="requestS">
      <span class="count" :class="{active:Number(count)}">{{ Number(count) }}</span>
      <div class="xxs-title">
        <slot></slot>
      </div>
      <img :src="require(`@/assets/icons/${icon}`)" :alt="icon" />
  </div>
</template>

<script>
export default {
  props: {
    href:"",
    type:"",
    count: {
        type: Number,
        default() {
            return 0
        }
    },
      unhref: {
      type: Boolean,
      default() {
        return false
      }
    },
      reverse: {
      type: Boolean,
      default() {
        return false
      }
    },
    icon: {
      type: String,
      default() {
        return ''
      }
    }
  },
    methods: {
      requestS(){
          if( (Number( this.count ) > 0 && this.href) || ( this.unhref && this.href ) ){
           location.href=this.href;
          }else if(this.unhref){
              this.$parent.open();
              return true;
          }else{
              return false;
          }
      }
  },
    created(){
        this.$bus.$on(this.type, (data)=>{
            this.count = data;
        });
    }
}
</script>

<style lang="scss" scoped>
.layout-header-button {
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  position: relative;
  &.reverse {
    flex-direction: row-reverse;
    img {
      margin-left: 0;
      margin-right: 0.5em;
    }
  }
  img {
    margin-left: 0.5em;
    width: 1.125em;
    height: 1.125em;
    max-width: none;
    max-height: none;
  }
  .count{
    position: absolute;
    right: -7px;
    top: -6px;
    background: black;
    color: #fff;
    border-radius: 50%;
    padding: 1px 5px;
    font-size: 9px;
    display: none;
  }
  .count.active{
    display: block;
  }
}
</style>
