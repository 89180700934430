<template>
    <div class="section-propsdetail">

        <div
                v-for="(props, index) in propsdetail"
                :key="index"
                class="tabdetail"
                :class="{hidden: index > 6 && hidden}"
        >
            <span class="tabdetailFirst" v-html="replases(props.NAME)"></span>
            <span class="tabdetaillast">
                <span v-if="props.VALUE.TYPE && props.VALUE.TYPE=='HTML'" v-html="props.VALUE.TEXT"></span>
                <span v-else-if="Array.isArray(props.VALUE)">{{ props.VALUE.join(', ') }}</span>
                <span v-else>{{ props.VALUE }}</span>
            </span>
        </div>

        <div v-if="propsdetail && propsdetail.length>6 && hidden">
            <span class="showMoreButton" @click="showMoreUp">
                {{ this.$store.state.langs.SHOW_MORE }}
            </span>
        </div>
        <div v-if="propsdetail && propsdetail.length>6 && !hidden">
            <span class="showMoreButton" @click="showMoreDown">
                {{ this.$store.state.langs.SHOW_SMALL }}
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            propsdetail: [],
            article: 'article',
        },
        data() {
            return {
                hidden:true
            }
        },
        methods:{
            showMoreUp(){
                this.hidden = false
            },
            showMoreDown(){
                this.hidden = true
            },
            replases(value) {
                return value.replace(/Артикул/i, this.$store.state.langs.ARTICLE)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .showMoreButton{
       font-weight: bold;
       text-decoration: underline;
        margin-top: 11px;
        display: inline-block;
        cursor: pointer;
    }
    .tableProps td{
        padding: 5px;
    }
    .tabdetail{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 6em .5em;
        margin-bottom: 18px;
    }
    .tabdetail .tabdetailFirst{
        text-transform: uppercase;
    }
    .tabdetail .tabdetailLast{}
    .hidden{
        display: none;
    }
</style>
