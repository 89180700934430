<template>
  <div class="layout-footer">
    <div class="container">


      <div v-if="width<769" class="footerMobile">

        <div class="link_home">
          <layout-footer-button type="home" :href="this.$store.state.langs.SITE_DIR+'/'" :hreftrue="true">
            <template slot="title">
              {{ this.$store.state.langs.HOME }}
            </template>
            <span slot="svg" class="icon-footer">
                <svg width="16" height="17" viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.1396 16.273H2.72418C1.32509 16.273 0.15918 15.0915 0.15918 13.6769V8.00281C0.15918 7.28772 0.470089 6.58817 1.01418 6.10627L6.22191 1.38045C7.23236 0.509903 8.63145 0.509903 9.64191 1.38045L14.8496 6.10627C15.3937 6.57263 15.7046 7.28772 15.7046 8.00281V13.6769C15.6269 15.1693 14.5387 16.273 13.1396 16.273ZM7.93191 2.32872C7.69873 2.32872 7.46554 2.40645 7.23236 2.5619L2.02463 7.28772C1.79145 7.44317 1.71373 7.75408 1.71373 8.08054V13.7546C1.71373 14.2987 2.18009 14.7806 2.72418 14.7806H13.0619C13.606 14.7806 14.0724 14.3143 14.0724 13.7546V8.08054C14.0724 7.76963 13.9169 7.53645 13.7615 7.28772L8.55373 2.5619C8.39827 2.40645 8.16509 2.32872 7.93191 2.32872Z"/>
                </svg>
            </span>
          </layout-footer-button>
        </div>

        <div class="link_search" @click="openedsearch()">
          <layout-footer-button type="search">
            <template slot="title">
              {{ this.$store.state.langs.SEARCH }}
            </template>
            <span slot="svg" class="icon-footer">
                <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.4942 15.7257L12.5374 11.6105C13.5548 10.4011 14.1122 8.87946 14.1122 7.29534C14.1122 3.59422 11.101 0.583008 7.39984 0.583008C3.69871 0.583008 0.6875 3.59422 0.6875 7.29534C0.6875 10.9965 3.69871 14.0077 7.39984 14.0077C8.78929 14.0077 10.1134 13.5886 11.2454 12.793L15.2323 16.9395C15.3989 17.1126 15.623 17.208 15.8632 17.208C16.0906 17.208 16.3062 17.1213 16.47 16.9637C16.8178 16.629 16.8289 16.0739 16.4942 15.7257ZM7.39984 2.33405C10.1356 2.33405 12.3611 4.55963 12.3611 7.29534C12.3611 10.0311 10.1356 12.2566 7.39984 12.2566C4.66412 12.2566 2.43854 10.0311 2.43854 7.29534C2.43854 4.55963 4.66412 2.33405 7.39984 2.33405Z"/>
                </svg>
            </span>
          </layout-footer-button>
        </div>

        <div class="link_catalog">
          <layout-footer-button type="catalog" :href="sitedir+this.$store.state.langs.LINK_CATALOG" :hreftrue="true">
            <template slot="title">
              {{ this.$store.state.langs.CATALOG }}
            </template>
            <span slot="svg" class="icon-footer">
                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.7734 8.88305H16.7363V3.34167C16.7363 2.03217 15.7375 0.966797 14.5098 0.966797H4.45312C3.2254 0.966797 2.22656 2.03217 2.22656 3.34167V8.88305C0.998836 8.88305 0 9.94842 0 11.2579V15.6119H1.48438V17.0368H2.96875V15.6119H16.0312V17.0368H17.5156V15.6119H19V11.2579C19 9.94842 18.0012 8.88305 16.7734 8.88305ZM3.71094 3.34167C3.71094 2.90517 4.04388 2.55005 4.45312 2.55005H14.5098C14.919 2.55005 15.252 2.90517 15.252 3.34167V8.88305H13.8418V7.69561C13.8418 6.38611 12.843 5.32074 11.6152 5.32074H7.31055C6.08282 5.32074 5.08398 6.38611 5.08398 7.69561V8.88305H3.71094V3.34167ZM12.3574 7.69561V8.88305H6.56836V7.69561C6.56836 7.25911 6.9013 6.90399 7.31055 6.90399H11.6152C12.0245 6.90399 12.3574 7.25911 12.3574 7.69561ZM17.5156 14.0286H1.48438V11.2579C1.48438 10.8214 1.81732 10.4663 2.22656 10.4663H16.7734C17.1827 10.4663 17.5156 10.8214 17.5156 11.2579V14.0286Z"/>
                </svg>
            </span>
          </layout-footer-button>
        </div>

        <div class="link_cart">
          <layout-footer-button type="smallbasket" :href="sitedir+this.$store.state.langs.LINK_CART" :count="countProduct">
            <template slot="title">
              {{ this.$store.state.langs.CART }}
            </template>
            <span slot="svg" class="icon-footer">
                <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.8121 8.68107C15.1732 7.95581 14.2752 7.50684 13.36 7.50684H3.9489C2.94735 7.50684 2.13575 7.95581 1.49682 8.68107C0.857903 9.40633 0.685221 10.4079 0.857903 11.3231L1.75585 15.5883C2.0494 17.0389 3.41359 18.1267 4.86411 18.1267H12.4621C13.999 18.1267 15.2768 17.0389 15.5358 15.5883L16.4338 11.3231C16.641 10.3215 16.451 9.40633 15.8121 8.68107ZM14.6379 10.9605L13.7399 15.2257C13.6536 15.8646 13.0147 16.3136 12.3758 16.3136H4.86411C4.22519 16.3136 3.68988 15.8646 3.49993 15.2257L2.60199 10.9605C2.51564 10.5978 2.60199 10.1489 2.87828 9.78623C3.15457 9.4236 3.5172 9.23365 3.96617 9.23365H13.3946C13.8435 9.23365 14.2062 9.4236 14.4825 9.78623C14.6379 10.1316 14.7242 10.4942 14.6379 10.9605Z" fill="#4B4F63"/>
                  <path d="M3.86269 6.59183H4.03538C4.48435 6.59183 4.84698 6.31554 4.93332 5.86657L5.83127 1.96397C5.91761 1.515 5.65858 1.04876 5.19234 0.876077C4.65703 0.789736 4.19079 1.15237 4.10445 1.60134L3.2065 5.50394C3.05109 5.95291 3.41372 6.50549 3.86269 6.59183Z" fill="#4B4F63"/>
                  <path d="M12.2892 5.86698C12.3755 6.31595 12.7381 6.59224 13.1871 6.59224H13.3598C13.8088 6.5059 14.1714 5.95332 13.9987 5.50435L13.1008 1.60175C13.0144 1.15278 12.4619 0.790147 12.0129 0.962829C11.5639 1.04917 11.2013 1.60175 11.374 2.05072L12.2892 5.86698Z" fill="#4B4F63"/>
                  <path d="M8.7495 11.4102C8.21419 11.4102 7.85156 11.7728 7.85156 12.3254V13.7759C7.85156 14.3285 8.21419 14.6911 8.7495 14.6911C9.28482 14.6911 9.64745 14.3285 9.64745 13.7759V12.3254C9.66471 11.7728 9.19848 11.4102 8.7495 11.4102Z" fill="#4B4F63"/>
                  <path d="M11.9263 11.8584C11.391 11.8584 11.0283 12.221 11.0283 12.7736V13.3262C11.0283 13.8788 11.391 14.2414 11.9263 14.2414C12.4616 14.2414 12.8242 13.8788 12.8242 13.3262V12.7736C12.8242 12.221 12.3752 11.8584 11.9263 11.8584Z" fill="#4B4F63"/>
                  <path d="M5.59033 11.8584C5.05501 11.8584 4.69238 12.221 4.69238 12.7736V13.3262C4.69238 13.8788 5.05501 14.2414 5.59033 14.2414C6.12564 14.2414 6.48827 13.8788 6.48827 13.3262V12.7736C6.48827 12.221 6.0393 11.8584 5.59033 11.8584Z" fill="#4B4F63"/>
                </svg>
            </span>
          </layout-footer-button>
        </div>

        <div class="link_profile">
          <layout-footer-button type="profile" :href="sitedir+this.$store.state.langs.LINK_PERSONAL" :hreftrue="true" :authorized="authorized">
            <template slot="title">
              {{ this.$store.state.langs.PROFILE }}
            </template>
            <span slot="svg" class="icon-footer">
                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.7734 8.88305H16.7363V3.34167C16.7363 2.03217 15.7375 0.966797 14.5098 0.966797H4.45312C3.2254 0.966797 2.22656 2.03217 2.22656 3.34167V8.88305C0.998836 8.88305 0 9.94842 0 11.2579V15.6119H1.48438V17.0368H2.96875V15.6119H16.0312V17.0368H17.5156V15.6119H19V11.2579C19 9.94842 18.0012 8.88305 16.7734 8.88305ZM3.71094 3.34167C3.71094 2.90517 4.04388 2.55005 4.45312 2.55005H14.5098C14.919 2.55005 15.252 2.90517 15.252 3.34167V8.88305H13.8418V7.69561C13.8418 6.38611 12.843 5.32074 11.6152 5.32074H7.31055C6.08282 5.32074 5.08398 6.38611 5.08398 7.69561V8.88305H3.71094V3.34167ZM12.3574 7.69561V8.88305H6.56836V7.69561C6.56836 7.25911 6.9013 6.90399 7.31055 6.90399H11.6152C12.0245 6.90399 12.3574 7.25911 12.3574 7.69561ZM17.5156 14.0286H1.48438V11.2579C1.48438 10.8214 1.81732 10.4663 2.22656 10.4663H16.7734C17.1827 10.4663 17.5156 10.8214 17.5156 11.2579V14.0286Z"/>
                </svg>
            </span>
          </layout-footer-button>
        </div>


      </div>

      <div v-if="width>768" class="header">
        <ui-logo black small></ui-logo>
        <ui-finder></ui-finder>
        <div class="contacts">
          <div class="contact">
            <div class="caption">
              {{ this.$store.state.langs.PHONE_TITLE }}
            </div>
            <a title="" class="xs-title" :href="'tel:'+this.$store.state.langs.PHONE">
              {{ this.$store.state.langs.PHONE }}
            </a>
          </div>
          <div class="contact">
            <div class="caption">
              {{ this.$store.state.langs.EMAIL_TITLE }}
            </div>
            <a title="" class="xs-title" :href="this.$store.state.langs.EMAIL">
              {{ this.$store.state.langs.EMAIL.replace('mailto:', '') }}
            </a>
          </div>
        </div>
      </div>
      <!--  -->
      <div v-if="width>768" class="menu">
        <div
                v-for="(menu, index) in menuBottom"
                :key="index"
        >
          <div class="xs-title">
            {{ menu.NAME }}
          </div>
            <a title="" v-for="(element, index) in menu.ELEMENTS"
                :key="index"
                :href="url(element.PROPERTY_LINK_VALUE)">
              {{ element.NAME }}
            </a>
        </div>
      </div>
      <!--  -->
      <div v-if="width>768" class="socials">
        <div class="links">
          <a title="" :href="this.$store.state.langs.LINK_WHATSAPP" v-if="this.$store.state.langs.LINK_WHATSAPP && this.$store.state.langs.LINK_WHATSAPP != '' && this.$store.state.langs.LINK_WHATSAPP != '/'"><img src="/images/icons/whatsapp.svg" alt="whatsapp"></a>
          <a title="" :href="this.$store.state.langs.LINK_MESSENGER" v-if="this.$store.state.langs.LINK_MESSENGER && this.$store.state.langs.LINK_MESSENGER != '' && this.$store.state.langs.LINK_MESSENGER != '/'"><img src="/images/icons/messenger.svg" alt="messenger"></a>
          <a title="" :href="this.$store.state.langs.LINK_INSTAGRAN" v-if="this.$store.state.langs.LINK_INSTAGRAN && this.$store.state.langs.LINK_INSTAGRAN != '' && this.$store.state.langs.LINK_INSTAGRAN != '/'"><img src="/images/icons/instagram.svg" alt="instagram"></a>
          <a title="" :href="this.$store.state.langs.LINK_TELEGRAM" v-if="this.$store.state.langs.LINK_TELEGRAM && this.$store.state.langs.LINK_TELEGRAM != '' && this.$store.state.langs.LINK_TELEGRAM != '/'"><img src="/images/icons/telegram.svg" alt="telegram"></a>
          <a title="" :href="this.$store.state.langs.LINK_FACEBOOK" v-if="this.$store.state.langs.LINK_FACEBOOK && this.$store.state.langs.LINK_FACEBOOK != '' && this.$store.state.langs.LINK_FACEBOOK != '/'"><img src="/images/icons/facebook.svg" alt="Facebook"></a>
          <a title="" :href="this.$store.state.langs.LINK_PINTRREST" v-if="this.$store.state.langs.LINK_PINTRREST && this.$store.state.langs.LINK_PINTRREST != '' && this.$store.state.langs.LINK_PINTRREST != '/'"><img src="/images/icons/pinterest.svg" alt="Pinterest"></a>
          <a title="" :href="this.$store.state.langs.LINK_YOUTUBE" v-if="this.$store.state.langs.LINK_YOUTUBE && this.$store.state.langs.LINK_YOUTUBE != '' && this.$store.state.langs.LINK_YOUTUBE != '/'"><img src="/images/icons/youtube.svg" alt="YouTube"></a>
        </div>
      </div>
      <!--  -->
      <!--<div class="by param-text">
        <slot name="by"></slot>
      </div>-->



      <div v-if="width>768" class="copyrights param-text">
        <a title="" :href="this.$store.state.langs.SITE_DIR + this.$store.state.langs.URL_POLITICA_SULLA">
          {{ this.$store.state.langs.POLITICA_SULLA }}
        </a>
        <span>
          <!--{{ this.$store.state.langs.COOPIRAYT }}-->
          © {{ getyear }} Myroomy.com
        </span>
      </div>
    </div>
    <div v-if="!cookiesViews" class="cookies-view">
      <span v-html="this.$store.state.langs.COOKIES_VIEW"></span>
      <span class="closeBlock" @click="closeBlock" :style="{ backgroundImage: 'url(' + require('@/assets/icons/close-white.svg') + ')' }" ></span>
      <div class="button-cookie">
        <span @click="closeBlock">ACCEPT ALL COOKIES</span>
      </div>
    </div>
  </div>
</template>

<script>
import uiLogo from '@/components/ui/ui-logo'
import uiFinder from '@/components/ui/ui-finder'

export default {
  components: {
    uiLogo,
    uiFinder
  },
    props: {
        authorized: {
            type: Boolean,
            default() {
                return false
            }
        },
        sitedir: {
            type: String,
            default() {
                return "/"
            }
        },
        scrollcontent: {
            type: Boolean,
            default() {
                return false
            }
        }
    },
    data() {
        return {
            menuBottom: {},
            cookiesViews: false,
            getyear: (new Date()).getFullYear(),
            width: window.innerWidth,
            countProduct: 0,
            requests: true,
            stieid: this.$store.state.langs.SITE_ID
        }
    },
    created () {

        if( this.scrollcontent ) {
            window.addEventListener('scroll', () => {
                if (pageYOffset > 200) {
                    this.menuBottomRequest();
                }
            });
        }else{
            this.menuBottomRequest();
        }

        this.baskekeCount();
        //this.menuBottom = this.bottommenutop;
        //this.menuBottomRequest();
        this.cookie();
        window.addEventListener('resize', this.updateWidth);
    },
    methods: {
        async menuBottomRequest() {
            if(this.requests) {
                this.requests = false;
                const responseMenuBottom = (
                    await this.$axios({
                        url: '/ajax/menu-bottom.php',
                        method: 'post',
                        params: {
                            clear_cache: this.getGet('clear_cache'),
                            SITE_ID: this.$store.state.langs.SITE_ID
                        }
                    })
                )
                if (responseMenuBottom) {
                    this.menuBottom = responseMenuBottom.data
                } else {
                    console.log('responseMenuBottom')
                }
            }
        },
        async baskekeCount() {
            const  countBasketProducts  = (
                await this.$axios({
                    url: '/ajax/basketline.php',
                    method: 'post',
                    params: {
                        SITE_ID: this.$store.state.langs.SITE_ID
                    }
                })
            )
            if (countBasketProducts) {
                this.countProduct = countBasketProducts.data.count
            } else {
                console.log('countBasketProducts')
            }
        },
        getGet(name) {
            var searchString = new URLSearchParams(window.location.search);
            var res = searchString.get(name);
            return res;
        },
        url(el){
            return this.$store.state.langs.SITE_DIR + el
        },
        closeBlock(){
            this.$cookie.set('cookieView', 'yes');
            this.cookiesViews = true;
        },
        cookie(){
            this.cookiesViews = this.$cookie.get('cookieView');
        },
        updateWidth() {
            this.width = window.innerWidth;
        },
        openedsearch(){
            this.$bus.$emit("opensearch");
        }
    },
}
</script>

<style lang="scss" scoped>
.layout-footer {
  background: var(--bg);
  padding-bottom: 2em;
}
.header {
  padding: 3.75em 0;
  display: flex;
  align-items: center;
}
.contacts {
  display: flex;
  align-items: center;
  & > * {
    &:not(:last-child) {
      margin-right: 4em;
    }
  }
}
.ui-finder {
  margin-left: 2.25em;
  width: 12em;
  margin-right: auto;
}
.socials {
  text-align: center;
  position: relative;
  &:after {
    width: 3.75em;
    content: '';
    height: 1px;
    display: inline-block;
    background: currentColor;
    position: relative;
    margin: 2em auto;
  }
  .links {
    & > * {
      margin: 1em;
    }
  }
  img {
    width: 15px;
    height: 15px;
  }
}
.by,
.copyrights {
  text-align: center;
}
.param-text {
  font-size: 0.6875em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.by {
  opacity: 0.5;
}
.copyrights {
  margin-top: 0.4em;
  & > * {
    &:not(:last-child) {
      padding-right: 1em;
      border-right: solid 1px;
      margin-right: 1em;
    }
  }
}
.menu {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-bottom: 2em;
  .xs-title {
    margin-bottom: 1em;
  }
  a {
    display: block;
    opacity: 0.6;
    transition: 0.4s;
    cursor: pointer;
    margin-bottom: 0.5em;
    &:hover {
      opacity: 1;
    }
  }
}
.cookies-view{
  display: block;
  position: fixed;
  bottom: 0;
  text-align: center;
  padding: 15px 15px;
  z-index: 2;
  background: rgba(124,124,124,0.7);
  color: #fff;
  margin: auto;
  left: 0;
  right: 0;
  max-width: 900px;
}
.cookies-view  .closeBlock{
  position: absolute;
  right: 5px;
  top: 5px;
  width: 10px;
  height: 10px;
  z-index: 2;
  cursor: pointer;
  transition: 0.1s;
  &:hover {
    transform: rotate(90deg);
  }
}
.button-cookie{
  text-align: center;
  margin: 10px 0;
}
.button-cookie span{
  border: 1px solid #fff;
  padding: 3px 10px;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .cookies-view{
    bottom: 60px;
  }
  .layout-footer{
    position: fixed;
    bottom: 0;
    padding: 0;
    width: 100%;
    background: #fff;
    z-index: 100;
    .footerMobile{
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 70px;
    }
  }
  .menu {
    grid-template-columns: repeat(2, 1fr);
  }
  .header {
    flex-flow: wrap;
    .contacts {
      margin-top: 1em;
    }
  }
  .ui-finder {
    display: none;
    width: auto;
    margin-right: 0;
    flex-grow: 1;
    margin-left: 1em;
  }
}
</style>
