<template>
  <div class="section-orders">
      <div class="great-title">
        <h1>Orders</h1>
      </div>
      <div class="content">
          <div class="content-wrapper">
              <template v-if="list">
                <layout-loader v-show="loadingList"/>
                <vuescroll :ops="ops">
                  <div class="orders">
                    <div class="order-items" v-for="(item, key, index) in orders.items" @click.prevent="detailVisible(item)">
                      <div>
                          <div class="nOrder">
                              № {{ item.ID }}
                          </div>
                          <div class="countBasket">
                              {{ item.count_basket }} items
                          </div>
                      </div>
                      <div>
                          <div class="pay">
                            <div class="pay-label">Payment: </div>
                            <div class="pay-name">{{ item.PAY_SYSTEM_NAME }}</div>
                          </div>
                          <div class="delivery">
                              <div class="delivery-label">Delivery: </div>
                              <div class="delivery-name">{{ item.DELIVERY_NAME }}</div>
                          </div>
                      </div>
                      <div class="price-data">
                          <div class="price" v-html="formatPrice( item.PRICE )"></div>
                          <div class="data">
                            {{ item.DATE_INSERT }}
                          </div>
                      </div>
                    </div>
                  </div>
              </vuescroll>
              </template>

              <template v-if="detail">
                  <vuescroll :ops="ops">
                    <div class="order-detail">

                      <div class="headBeak" @click.prevent="listVisible()">
                          <span>
                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.7164 8.94676C15.8033 6.00892 12.9051 4.00405 9.43135 3.94449V0.5104C9.43135 0.351598 9.2924 0.212646 9.1336 0.212646C9.07405 0.212646 9.0145 0.232497 8.95495 0.272197L0.121583 6.66398C-0.017369 6.76323 -0.0372192 6.94188 0.0620321 7.08084C0.0818823 7.10069 0.101733 7.12054 0.121583 7.14039L8.95495 13.4925C9.0939 13.5917 9.27255 13.552 9.3718 13.4329C9.4115 13.3734 9.43135 13.3138 9.43135 13.2543V9.84002C11.5752 9.84002 13.3816 10.8524 14.0168 12.3809C14.5527 13.691 14.116 15.1003 12.8456 16.2715C12.7265 16.3906 12.7066 16.5693 12.8257 16.6884C12.8853 16.7479 12.9647 16.7876 13.0441 16.7876H13.2823C13.3419 16.7876 13.4014 16.7678 13.4411 16.7281C16.3591 14.8026 17.6097 11.8052 16.7164 8.94676Z" fill="#95949C"/>
                            </svg>
                          </span>
                          <span>back to orders</span>
                      </div>

                      <div class="headOrder">
                          <div class="orderId">order №{{ detailOrder.ID }}</div>
                          <div class="orderData">{{ detailOrder.DATE_INSERT }}</div>
                      </div>

                      <div class="order-status">
                          <img src="/images/order-status.svg" alt="order status" />
                      </div>

                      <div class="order-basket">

                          <div class="basket-items" v-for="(item, key, index) in detailOrder.baskets">
                              <div>
                                  <div class="image">
                                      <img :src="item.DETAIL_PICTURE['SRC']" :alt="item.NAME" />
                                  </div>
                              </div>
                              <div>
                                  <div @click="redirect(item.DETAIL_PAGE_URL)" class="name">{{item.NAME}}</div>
                              </div>
                              <div>
                                  <div class="price-one" v-html="formatPrice( item.PRICE )"></div>
                              </div>
                              <div>
                                  <div class="quantity">x{{ item.QUANTITY }}</div>
                              </div>

                              <div>
                                  <div class="total-price" v-html="formatPrice( item.PRICE * item.QUANTITY )"></div>
                              </div>
                          </div>

                      </div>

                      <div class="order-footer">

                          <div class="order-about">
                              <div class="head">About</div>
                              <div class="order-about-field">
                                  <div class="label">Address</div>
                                  <div class="value">{{ detailOrder.PROPERTY_VAL_BY_CODE_ADDRESS }}</div>
                              </div>
                              <div class="order-about-field">
                                  <div class="label">Payment</div>
                                  <div class="value">{{ detailOrder.PAY_SYSTEM_NAME }}</div>
                              </div>
                              <div class="order-about-field">
                                  <div class="label">Delivery date</div>
                                  <div class="value">{{ detailOrder.DATE_DEDUCTED }}</div>
                              </div>
                          </div>
                          <div class="order-info">
                              <div class="head">detail info</div>
                              <div class="order-about-field">
                                  <div class="label">Subtotal</div>
                                  <div class="value" v-html="formatPrice( Number(detailOrder.PRICE) - Number(detailOrder.PRICE_DELIVERY) )"></div>
                              </div>
                              <div class="order-about-field">
                                  <div class="label">Shipping</div>
                                  <div class="value" v-html="formatPrice( detailOrder.PRICE_DELIVERY )"></div>
                              </div>
                              <div class="order-about-field">
                                  <div class="label">Promocode</div>
                                  <div class="value">-</div>
                              </div>

                              <div class="order-about-field total-price-order">
                                  <div class="label">Total</div>
                                  <div class="value" v-html="formatPrice( detailOrder.PRICE )"></div>
                              </div>

                          </div>

                      </div>

                  </div>
                  </vuescroll>
              </template>

          </div>
      </div>
  </div>
</template>

<script>
import vuescroll from 'vuescroll/dist/vuescroll-native'
export default {
    components: {
        vuescroll
    },
    props: {},
    data() {
        return {
            loadingList: true,
            list: true,
            detail: false,
            ops: {
                vuescroll: {},
                scrollPanel: {},
                rail: {
                    background: '#000',
                    opacity: 0.1,
                    size: '3px',
                    specifyBorderRadius: '0px'
                },
                bar: {
                    background: '#000',
                    opacity: 1,
                    size: '3px'
                }
            },
            orders: {},
            detailOrder: {}
        }
    },
    methods:{
        async ordersRequest() {
            const responseOrders  = (
                await this.$axios({
                    url: '/ajax/section-orders.php',
                    method: 'post',
                    data: {
                        SITE_ID: this.$store.state.langs.SITE_ID,
                        limit: 100,
                        page: 1
                    }
                })
            )
            if (responseOrders) {
                this.orders = responseOrders.data;
                this.loadingList=false;
            } else {
                console.log('error responseOrders')
            }

        },
        payed(payed){
            return (payed=="N") ? "Не оплачен" : "Оплачен" ;
        },
        formatPrice(price) {
            if(price) {
                return this.$store.state.langs.CURRENCY_SING + " " +Math.ceil(Number(price)).toLocaleString();
            }
        },
        detailVisible(item) {
           this.detailOrder = item;
           this.list = false;
           this.detail = true;
        },
        listVisible() {
            this.detailOrder = {};
            this.list = true;
            this.detail = false;
        },
        redirect(url){
            location.href = url;
        }
    },
    created () {
        this.ordersRequest();
    },
    computed: {}
}
</script>

<style lang="scss" scoped>
.section-orders {
  padding-bottom: 5em;
  .content{
      margin-top: 10px;
  }
  .content-wrapper{
      height: 59vh;
  }
  .orders {
      .order-items {
          display: grid;
          grid-template-columns: minmax(61px, 100px) 1fr 2fr;
          grid-template-rows: 1fr;
          grid-column-gap: 0px;
          grid-row-gap: 0px;
          align-items: center;
          text-align: left;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          text-transform: uppercase;
          padding: 15px 0;
          cursor: pointer;
          .price-data{
              text-align: right;
          }
          .nOrder{}
          .countBasket{ font-weight: 400; }
          .payment, .data, .countBasket, .delivered{
              color: #95949C;
          }
          .pay, .delivery{
              display: flex;
              align-items: center;
          }
          .pay-label, .delivery-label{
              color: #95949c;
          }
          .pay-name, .delivery-name{
              padding: 0 10px;
          }
      }
  }
  .order-detail{
      padding-right: 10px;
      .headBeak{
          font-size: 13px;
          line-height: 120%;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: #95949C;
          display: flex;
          align-items: center;
          cursor: pointer;
          span{
              margin-right: 10px;
          }
      }
      .headOrder{
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 15px;
          .orderId{
              font-family: Futura PT;
              font-style: normal;
              font-weight: normal;
              font-size: 24px;
              line-height: 120%;
              letter-spacing: 0.1em;
              text-transform: uppercase;
              color: #262429;
          }
          .orderData{
              font-family: Futura PT;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 18px;
              color: #6F6B76;
          }
      }
      .order-status{
          margin-top: 15px;
      }
  }
  .order-basket{
      padding-right: 10px;
      .basket-items{
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 2fr;
          grid-template-rows: 1fr;
          grid-column-gap: 0;
          grid-row-gap: 0;
          -webkit-box-align: center;
          align-items: center;
          text-align: left;
          font-weight: 600;
          margin-bottom: 15px;
          & > div{
              padding: 5px 10px;
          }
          .name{
              cursor: pointer;
          }
          .total-price{
              text-align: right;
          }
          .image{
              width: 100px;
          }
      }
  }
  .order-footer{
    display: grid;
    grid-template-rows: 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 0;
    align-items: baseline;
    grid-template-columns: 1fr 1fr;
    .head{
        font-family: Futura PT;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.09em;
        text-transform: uppercase;
        color: #262429;
        margin-bottom: 20px;
    }
    .order-about-field{

        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.02em;
        color: #000000;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

       .label{}
       .value{
           font-weight: 600;
           font-size: 14px;
           line-height: 18px;
           text-align: right;
           letter-spacing: 0.09em;
           text-transform: uppercase;
       }
    }
    .order-about{

    }
    .order-info{

    }
    .total-price-order{
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-align: right;
        letter-spacing: 0.09em;
        text-transform: uppercase;
        margin-top: 25px;
    }
  }
}
@media screen and (max-width: 767px) {
    .section-orders {
        .content {}
        .content-wrapper {}
        .orders {
            .order-items {
                font-size: 12px;
                font-weight:300;
                text-transform: none;
            }
        }
        .order-basket{
            grid-template-columns: 1fr 1fr;
            display: grid;
            .basket-items{
                grid-template-columns: repeat(auto-fill, 150px);
                .total-price{
                    text-align: left;
                }
            }
        }
    }
}
</style>
