<template>
  <div class="layout-product-card" :data-id="product.ID" itemscope itemtype="http://schema.org/Product">
    <span v-if="favoritedelete" class="favoriteRemoveBlock" @click="removeFavorite(product.IBLOCK_ID, product.ID)">
      <img src="@/assets/icons/close-grey.svg" alt="close" />
    </span>
    <div class="image">
<!--      <div v-if="width<769 && product.MORE_PHOTO && product.MORE_PHOTO.length > 0" class="image-wrapper">
        <layout-section-product-gallery
            :morephoto="product.MORE_PHOTO.slice(0,5)"
            :imgdetail="product.DETAIL_PICTURE.SRC"
            :code="product.CODE">
        </layout-section-product-gallery>
      </div>-->
      <a class="image-wrapper" :href="product.DETAIL_PAGE_URL">
        <img itemscope="image" v-lazy="product.DETAIL_PICTURE.SRC" :alt="product.NAME" :width="product.DETAIL_PICTURE.width" :height="product.DETAIL_PICTURE.height">
      </a>
      <div class="zoom abs">
        <!--<img src="@/assets/icons/zoom.svg" alt="zoom" @click="opened = true" />-->
      </div>
      <div class="promos">
        <layout-product-promos
            :sale="isSale(product.OFFERS)"
            :newprop="isNew(product.PROPERTIES)"
            :instock="isStock()"
            :special="isSpesialPromo(product.PROPERTIES)"
        ></layout-product-promos>
      </div>
      <div class="empty3d" v-if="currentOffers.empty3d">
        <svg width="20" height="20" class="svg-icon">
          <path d="M8.499,0L5.583,1.718L6.26,2.893l2.239-1.318l2.242,1.318l0.677-1.174L8.499,0z M15.653,14.217v-2.452h1.346v3.233
	l-3.077,1.811l-0.675-1.175L15.653,14.217z M1.346,14.217v-2.452H0v3.233l3.078,1.811l0.678-1.176L1.346,14.217z M1.346,6.578v2.043
	H0V5.004l2.75-1.618l0.677,1.176L2.011,5.395l2.044,1.203L3.378,7.773L1.346,6.578z M13.57,4.559l1.418,0.835l-2.046,1.204
	l0.678,1.176l2.033-1.196v2.044H17V5.004l-2.751-1.62L13.57,4.559z M8.499,9.213l2.867-1.687l0.677,1.176l-2.872,1.69v3.363H7.825
	v-3.363L4.993,8.724l0.678-1.175L8.499,9.213z M9.173,18.031V15.63H7.826v2.401L6.26,17.109l-0.677,1.173L8.499,20l2.917-1.716
	l-0.677-1.175L9.173,18.031z"></path>
        </svg>
      </div>

      <div class="favorite-icon">
        <layout-favorite-button :ID="product.ID" :IBLOCK_ID="product.IBLOCK_ID"></layout-favorite-button>
      </div>

    </div>
    <div class="content">
      <div class="header">
        <meta itemprop="name" :content="product.NAME" />
        <meta itemprop="description" :content="product.PREVIEW_TEXT" />
        <meta itemprop="mpn" :content="product.ID" />
        <meta itemprop="sku" :content="(product.PROPERTIES && product.PROPERTIES.CML2_ARTICLE) ? product.PROPERTIES.CML2_ARTICLE.VALUE : ''" />
        <a :title="product.NAME" :href="product.DETAIL_PAGE_URL">
          <div class="subtitle name" v-html="product.NAME"></div>
        </a>
<!--        <div class="compare-icon">
          <layout-compare-button :ID="product.ID" :IBLOCK_ID="product.IBLOCK_ID"></layout-compare-button>
        </div>-->
<!--        <div class="favorite-icon">
          <layout-favorite-button :ID="product.ID" :IBLOCK_ID="product.IBLOCK_ID"></layout-favorite-button>
        </div>-->
      </div>
      <div class="bottom">
        <div class="price subtitle minprice" @click="open(product.DETAIL_PAGE_URL)">
          <span :class="{reds:Number(currentOffers.MIN_PRICE.DISCOUNT_DIFF_PERCENT)>0}">{{ formatPrice( currentOffers.MIN_PRICE.DISCOUNT_VALUE ) }}</span>
        </div>
        <div class="price subtitle old" @click="open(product.DETAIL_PAGE_URL)">
          <span v-if="Number(currentOffers.MIN_PRICE.DISCOUNT_DIFF_PERCENT)>0">
            {{ formatPrice( currentOffers.MIN_PRICE.VALUE ) }}
          </span>
        </div>
      </div>
      <!--  -->
      <div v-if="typeCollection && product.MIN_PREVIEW_PICTURE.length>1" class="collection">


        <span class="collectionItem" :class="[{ active: currentOffers.ID === collection.ID }]" v-if="index < 5" title="" v-for="(collection, index) in product.MIN_PREVIEW_PICTURE"
           :key="collection.ID"
           v-on:mouseover = "changeColor(collection.ID)"
           @click = "changeColor(collection.ID)"
           itemtype="https://schema.org/Offer" itemscope
        >
            <span
                :style="'background-image: url('+collection.SRC+')'"
            ></span>
<!--          <link itemprop="url" :href="'/product/'+product.CODE+'/'" />-->
        </span>
        <a :href="product.OFFERS[0].url" v-if="product.MIN_PREVIEW_PICTURE.length>4"> +{{ product.MIN_PREVIEW_PICTURE.length-4 }}</a>


      </div>
      <div class="options">
        <!-- Степень жесткости -->
        <div :dd="hardness" :ss="hardnessPercent" v-if="hardness && hardnessPercent" class="option">
          <div class="hardness-progress">
            <svg
                :style="{ left: `${hardnessPercent}%` }"
                width="8"
                height="6"
                viewBox="0 0 8 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M4 0L7.4641 6H0.535898L4 0Z" fill="black" />
            </svg>
          </div>
          <div class="param-text">{{ hardness }}</div>
        </div>
        <!-- Тог -->
        <div :dd="hardnessTog" :ss="hardnessPercentTog" v-if="hardnessTog && hardnessPercentTog" class="option">
          <div class="hardness-progress-tog">
            <svg
                :style="{ left: `${hardnessPercentTog}%` }"
                width="8"
                height="6"
                viewBox="0 0 8 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M4 0L7.4641 6H0.535898L4 0Z" fill="black" />
            </svg>
            <div :style="{ left: `${hardnessPercentTog}%` }" class="param-text">Tog {{ hardnessTog }}</div>
          </div>
        </div>
        <!-- Свойства иконки -->
        <!--<div v-if="params" class="option params" @click="open(product.DETAIL_PAGE_URL)">-->
        <div v-if="params" class="option params">
          <div v-for="(param, index) in params" :key="index" class="param">
            <img v-lazy="param.icon" :alt="param.name" :width="18" :height="18" />
            <div class="param-text">{{ param.name }}</div>
            <!--  -->
            <div v-if="width>760" class="param-tooltip">
              <!--<img v-lazy="param.icon" alt="" />
              <div class="param-text">{{ param.name }}</div>-->
              <div class="param-desc" v-html="param.desc"></div>
            </div>
          </div>
        </div>
        <!-- Блок линк офферов size -->
        <!--<div v-if="sizes" class="option">
          <div class="param-text">{{ this.$store.state.langs.TEXT_PROP_SIZES_BLOCK }}</div>
          <div class="sizes">
            <a v-for="(size, index) in sizes" :key="index" class="size" :href="size.link" :title="size.size">
              {{ size.size }}
            </a>
          </div>
        </div>-->
      </div>
      <!--  -->
<!--      <div class="bottom">
        <div class="link subtitle">
          <a title="" :href="product.DETAIL_PAGE_URL" :title="product.NAME">
            {{ this.$store.state.langs.MORE_DETAILS }}
          </a>
        </div>
        <div class="price subtitle minprice" @click="open(product.DETAIL_PAGE_URL)">
          <span :class="{reds:Number(currentOffers.MIN_PRICE.DISCOUNT_DIFF_PERCENT)>0}">{{ formatPrice( currentOffers.MIN_PRICE.DISCOUNT_VALUE ) }}</span>
        </div>
        <div class="price subtitle old" @click="open(product.DETAIL_PAGE_URL)">
          <span v-if="Number(currentOffers.MIN_PRICE.DISCOUNT_DIFF_PERCENT)>0">
            {{ formatPrice( currentOffers.MIN_PRICE.VALUE ) }}
          </span>
        </div>
      </div>-->
      <!--  -->
      <!--<portal v-if="opened" to="popup">
        <layout-product-card-preview v-model="opened" :product="product"></layout-product-card-preview>
      </portal>-->
    </div>
  </div>
</template>

<script>
// import vuescroll from 'vuescroll/dist/vuescroll-native'
import layoutProductCardPreview from '@/components/layout/product/layout-product-card-preview'
import layoutSectionProductGallery from '@/components/sections/layout-section-product-gallery'
import layoutCompareButton from '@/components/layout/layout-compare-button'
import layoutFavoriteButton from '@/components/layout/layout-favorite-button'
import layoutProductPromos from '@/components/layout/product/layout-product-promos'
export default {
  components: {
    layoutProductCardPreview,
    layoutSectionProductGallery,
    layoutCompareButton,
    layoutFavoriteButton,
    layoutProductPromos
    // vuescroll
  },
  props: {
    product: {},
    favoritedelete: false
  },
  data() {
    return {
      chars: [
        {"name": "Size", "val": "Queen"},
        {"name": "Brand", "val": "SUMITU"},
        {"name": "Fabric", "val": "Egyptian Cotton"}
      ],
      typeCollection: false,
      hardness: null,
      hardnessPercent: null,
      hardnessTog: null,
      hardnessPercentTog: null,
      sizes: [],
      params: [],
      opened: false,
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          background: '#000',
          opacity: 0.1,
          size: '3px',
          specifyBorderRadius: '0px'
        },
        bar: {
          background: '#000',
          opacity: 1,
          size: '3px'
        }
      },
      site_id: "",
      currentOffers:{},
      width: window.innerWidth,
      getCollection: false
    }
  },
  computed: {
    productParams() {
      return this.$store.state.productParams
    }
  },
  methods: {
    isSale(offers){
      var res = 0;
      //if( offers ) {
      //  offers.forEach((offer, index) => {
          if (this.currentOffers.MIN_PRICE.DISCOUNT_DIFF_PERCENT > 0) {
            res = this.currentOffers.MIN_PRICE.DISCOUNT_DIFF_PERCENT;
            return;
          }
       // })
      //}
      return res;
    },
    isStock(){
      var res = false;
      if (this.currentOffers.QUANTITY > 0) {
        res = true;
      }
      return res;
    },
    isNew(prop){
      var res = false;
      if( typeof prop != "undefined" && typeof this.$store.state.langs.PROP_NEW != "undefined") {
        var propVal = this.$store.state.langs.PROP_NEW;
        if (typeof propVal.NAME != "undefined" && typeof prop[propVal.NAME] != "undefined" && prop[propVal.NAME].VALUE) {
          res = prop[propVal.NAME].VALUE == propVal.VALUE;
        }
      }
      return res;
    },
    isSpesialPromo(prop){
      var res = false;
      if( typeof prop != "undefined" && typeof this.$store.state.langs.PROP_SPECIAL != "undefined") {
        var propVal = this.$store.state.langs.PROP_SPECIAL;
        if(typeof propVal.NAME != "undefined" && typeof prop[propVal.NAME] != "undefined" && prop[propVal.NAME].VALUE ){
          res = prop[propVal.NAME].VALUE == propVal.VALUE;
        }
      }
      return res;
    },
    open(url) {
      window.location = url
    },
    formatPrice(price) {
      //return Math.ceil(Number(price)).toLocaleString() + " " + this.$store.state.langs.CURRENCY_SING
      return this.$store.state.langs.CURRENCY_SING + " " +Math.ceil(Number(price)).toLocaleString();
    },
    removeFavorite(iblock_id, id){
      var cookieFavorite = this.$cookie.get('favorites');
      var favoriteJson = JSON.parse(cookieFavorite);
      favoriteJson[this.site_id][iblock_id] = favoriteJson[this.site_id][iblock_id].filter((n) => {return n != id});
      this.$cookie.set('favorites', JSON.stringify(favoriteJson))
      var cuntFavorites = 0;
      for (var key in favoriteJson[this.site_id]) {
        cuntFavorites = cuntFavorites + favoriteJson[this.site_id][key].length;
      }
      this.$bus.$emit("favorite", cuntFavorites);
      this.$parent.parentremovefavorite(id);
    },
    changeColor(offer_id) {
      if(!this.getCollection){
        this.getCollection=true;
        var arids = this.product.MIN_PREVIEW_PICTURE.slice(0, 5);
        this.$axios({
          url: '/ajax/ar-offers-ids.php', method: 'post', data: {arids:arids}
        }).then(response => {
          if (response.status == 200) {
            this.product.OFFERS = response.data;
            this.currentOffers = this.product.OFFERS.find(offer => offer.ID === offer_id);
            this.product.NAME = this.currentOffers.NAME.replace(/( na\s?)$/i, '');
            this.product.DETAIL_PICTURE = this.currentOffers.DETAIL_PICTURE;
            this.product.DETAIL_PAGE_URL = this.$store.state.langs.SITE_DIR+this.$store.state.langs.LINK_SECTION_DETAIL_PRODUCT+this.currentOffers.CODE_BASE+"/";
          }else{
            console.log('Error status: ' + response.status)
          }
        }).catch(e => {
          console.log('Error: ' + e)
        })
      }else{
        this.currentOffers = this.product.OFFERS.find(offer => offer.ID === offer_id);
        //this.currentOffers = collection;
        this.product.NAME = this.currentOffers.NAME.replace(/( na\s?)$/i, '');
        this.product.DETAIL_PICTURE = this.currentOffers.DETAIL_PICTURE;
        //this.product.MORE_PHOTO = collection.MORE_PHOTO;
        this.product.DETAIL_PAGE_URL = this.$store.state.langs.SITE_DIR+this.$store.state.langs.LINK_SECTION_DETAIL_PRODUCT+this.currentOffers.CODE_BASE+"/";

      }


//      this.currentOffers = collection;
//      this.product.NAME = collection.NAME.replace(/( na\s?)$/i, '');
//      this.product.DETAIL_PICTURE = collection.DETAIL_PICTURE;
//      this.product.MORE_PHOTO = collection.MORE_PHOTO;
//      this.product.DETAIL_PAGE_URL = this.$store.state.langs.SITE_DIR+this.$store.state.langs.LINK_SECTION_DETAIL_PRODUCT+collection.PROPERTIES[this.$store.state.langs.PROP_CODE_BASE_CATALOG].VALUE+"/";
    },
    collecion(){
      //if( this.product.COLLECTION && this.product.OFFERS.length > 1 ){
 /*     var fabric = false;
      if( ( this.product.PROPERTIES.hasOwnProperty('FABRICS_SECTIONS') && this.product.PROPERTIES.FABRICS_SECTIONS.VALUE.length > 0 ) || ( this.product.PROPERTIES.hasOwnProperty('FABRICS') && this.product.PROPERTIES.FABRICS.VALUE.length > 0 ) ){
        fabric = true;
      }
      if( this.product.COLLECTION && !fabric ){
        //todo убрать если 1с-ик исправит, убираем _main с конца
        //this.product.DETAIL_PAGE_URL = this.product.DETAIL_PAGE_URL.replace(/_main/, '');
        //
        this.typeCollection = true;
        this.product.NAME = this.currentOffers.NAME.replace(/( na\s?)$/i, '');
        this.product.OFFFER_ID = this.currentOffers.ID;
        this.product.DETAIL_PICTURE = this.currentOffers.DETAIL_PICTURE;
        this.product.MORE_PHOTO = this.currentOffers.MORE_PHOTO;

        if( this.$store.state.langs.LINK_SECTION_DETAIL_PRODUCT && this.currentOffers.PROPERTIES && this.$store.state.langs.PROP_CODE_BASE_CATALOG && this.currentOffers.PROPERTIES[this.$store.state.langs.PROP_CODE_BASE_CATALOG] &&  this.currentOffers.PROPERTIES[this.$store.state.langs.PROP_CODE_BASE_CATALOG].VALUE) {
          this.product.DETAIL_PAGE_URL = this.$store.state.langs.SITE_DIR + this.$store.state.langs.LINK_SECTION_DETAIL_PRODUCT + this.currentOffers.PROPERTIES[this.$store.state.langs.PROP_CODE_BASE_CATALOG].VALUE + "/";
        }
      }*/
    },
    updateWidth() {
      this.width = window.innerWidth;
    },
  },
  created () {

    // добавляем стрнану к URL страницы товара если это не US сайт
    const arUrl = location.pathname.split('/').filter(function(el) { return el; });
    if (arUrl.length > 0) {
      if (arUrl[0].length == 2 && arUrl.length > 1) {
        this.product.DETAIL_PAGE_URL = "/" + arUrl[0] + this.product.DETAIL_PAGE_URL;
      }
    }


    this.site_id = this.$store.state.langs.SITE_ID;
    this.props_description = this.$store.state.langs.PROPS_DESCRIPTION;
    var prop = this.product.PROPERTIES;
    var offers = this.product.OFFERS;
    ////if( this.product.OFFER_ID_SELECTED && Number(this.product.OFFER_ID_SELECTED) > 0 ) {
    ////  var keyOffers = this.product.OFFER_ID_SELECTED;
    ////  this.currentOffers = this.product.OFFERS.find(offer => offer.ID === keyOffers);
    ////}else{
      this.currentOffers = this.product.OFFERS[0];
    ////}

    //степень жесткости
    if(this.$store.state.langs.PROP_IBLOCK[this.product.IBLOCK_ID] && this.$store.state.langs.PROP_IBLOCK[this.product.IBLOCK_ID].SHESTKOST){
      var codePropShostkost = this.$store.state.langs.PROP_IBLOCK[this.product.IBLOCK_ID].SHESTKOST;
      var shostkostProcent = this.$store.state.langs.PROP_IBLOCK[this.product.IBLOCK_ID].SHESTKOST_PROCENT;
      if( typeof prop[codePropShostkost] != "undefined" && prop[codePropShostkost] && prop[codePropShostkost].VALUE ) {
        this.hardness = prop[codePropShostkost].VALUE;
        this.hardnessPercent = shostkostProcent[this.hardness];
      }
    }
    //tog
    if(this.$store.state.langs.PROP_IBLOCK[this.product.IBLOCK_ID] && this.$store.state.langs.PROP_IBLOCK[this.product.IBLOCK_ID].TOG){
      var codePropShostkostTog = this.$store.state.langs.PROP_IBLOCK[this.product.IBLOCK_ID].TOG;
      var shostkostProcentTog = this.$store.state.langs.PROP_IBLOCK[this.product.IBLOCK_ID].TOG_PROCENT;
      if( typeof prop[codePropShostkostTog] != "undefined" && prop[codePropShostkostTog] && prop[codePropShostkostTog].VALUE ) {
        this.hardnessTog = prop[codePropShostkostTog].VALUE;
        this.hardnessPercentTog = shostkostProcentTog[this.hardnessTog];
      }
    }

    //offers links sizes
    /*let namePropSize = this.$store.state.langs.CODE_SIZE;
    if(namePropSize) {
        offers.forEach((offer, index) => {
            let p = {}
            //p.link = offer.DETAIL_PAGE_URL;
            p.link = "/product/"+offer.CODE+"/";
            try {
                p.size = offer.PROPERTIES[namePropSize].VALUE;
                this.sizes.push(p);
            }catch (e) {}
        })
    }*/

    //берем из свойства FILLING 2 значения
    var propFILLING = (prop.FILLING && prop.FILLING.VALUE ) ? prop.FILLING.VALUE : null;
    /*if(propFILLING) {
        propFILLING.forEach((prop, index) => {
            if (index == 2) return;
            var p = {};
            var propName = prop.replace(' ', '-');
            p.name = prop;
            p.icon = "/images/icons/" + propName.toLowerCase() + ".svg";
            p.desc = prop.toLowerCase();
            this.params.push(p);
        })
    }*/

    //берем из свойства SLEEP_POSITION 1 значения
    var propSLEEP_POSITION = (prop.SLEEP_POSITION && prop.SLEEP_POSITION.VALUE ) ? prop.SLEEP_POSITION.VALUE : null;
    /*if( propSLEEP_POSITION ){
        propSLEEP_POSITION.forEach((prop, index) => {
            if( index == 1 ) return;
            var p = {};
            var propName = prop.replace(' ', '-');
            p.name = prop;
            p.icon = "/images/icons/"+propName.toLowerCase()+".svg";
            p.desc = prop.toLowerCase();
            this.params.push(p);
        })
    }*/

    //берем из свойства OPTIONS 1 значения - теперь берем 2
    var propOPTIONS = (prop[this.$store.state.langs.PROP_OPTIONS] && prop[this.$store.state.langs.PROP_OPTIONS].VALUE ) ? prop[this.$store.state.langs.PROP_OPTIONS] : null;
    if( propOPTIONS){
      propOPTIONS.VALUE.forEach((prop, index) => {
        if( index == 2 ) return;
        var p = {};
        // var propName = prop.replace(' ', '-');
        p.name = prop;
        p.icon = "/images/icons/"+prop.toLowerCase()+".svg";
        p.desc = ( this.props_description.hasOwnProperty(prop.toLowerCase()) ) ? this.props_description[prop.toLowerCase()] : '';
        this.params.push(p);
      })
    }

    //берем из свойства SEASON
    var propSEASON = (prop[this.$store.state.langs.PROP_SEASON] && prop[this.$store.state.langs.PROP_SEASON].VALUE ) ? prop[this.$store.state.langs.PROP_SEASON] : null;
    if( propSEASON){
      propSEASON.VALUE.forEach((prop, index) => {
        if( index == 1 ) return;
        var p = {};
        // var propName = prop.replace(' ', '-');
        p.name = prop;
        p.icon = "/images/icons/"+prop.toLowerCase()+".svg";
        p.desc = ( this.props_description.hasOwnProperty(prop.toLowerCase()) ) ? this.props_description[prop.toLowerCase()] : '';
        this.params.push(p);
      })
    }
    //берем из свойства TOG
    var propTOG = (prop[this.$store.state.langs.PROP_TOG] && prop[this.$store.state.langs.PROP_TOG].VALUE ) ? prop[this.$store.state.langs.PROP_TOG] : null;
    if( propTOG){
        var p = {};
        p.name = "Tog " + propTOG.VALUE;
        p.icon = "/images/icons/"+propTOG.VALUE.toLowerCase()+".svg";
        p.desc = ( this.props_description.hasOwnProperty(propTOG.VALUE.toLowerCase()) ) ? this.props_description[propTOG.VALUE.toLowerCase()] : '';
        this.params.push(p);
    }

    if(this.product.COLLECTION){
      this.typeCollection = true;
    }


    //color
    //this.collecion();

    /*
    if(this.$store.state.langs.PROP_IBLOCK[this.product.IBLOCK_ID] && this.$store.state.langs.PROP_IBLOCK[this.product.IBLOCK_ID].COLLOR){
        var idsXmlArray = this.$store.state.langs.PROP_IBLOCK[this.product.IBLOCK_ID].COLLOR;

        this.$axios({
            url: '/ajax/arproductsxliid.php', method: 'post', data: {prodjson:this.product.PROPERTIES[idsXmlArray].VALUE}
        }).then(response => {
            if (response.status == 200) {
                this.colors = response.data;
                //this.colors.unshift(this.product);
                //this.product = this.colors[0];
            }else{
                console.log('Error status: ' + response.status)
            }
        }).catch(e => {
            console.log('Error: ' + e)
        })


    }
*/
    this.product.DETAIL_PAGE_URL = this.product.DETAIL_PAGE_URL.replace(/_main/, '');
    window.addEventListener('resize', this.updateWidth);
  },
  mounted() {},
  filters: {
    currensy: function (value) {
      //if(value == "EUR") {
      //    return "€"
      //}
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-product-card {
  position: relative;
  cursor: pointer;
  &:before {
    content: '';
    width: calc(100% + 2em);
    height: calc(100% + 2em);
    left: -1em;
    top: -1em;
    display: block;
    position: absolute;
    // border: solid 1px;
    background: #fff;
    transform: scale(0.95);
    opacity: 0;
    transition: 0.5s;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    z-index: -1;
  }
  &:hover {
    z-index: 2;
    &::before {
      opacity: 1;
      transform: none;
    }
  }
  .promos{
    position: absolute;
    bottom: 0;
    left: 0;

    .layout-product-promos{
      margin: 0;
    }
  }
  .favoriteRemoveBlock{
    position: absolute;
    right: 5px;
    cursor: pointer;
    z-index: 2;
  }
}
.image {
  /*padding-bottom: 100%;*/
  /*background: var(--bg);*/
  text-align: center;
  position: relative;
  cursor: pointer;
  margin-bottom: 1.25em;
  &:hover {
    .zoom {
      opacity: 1;
      visibility: visible;
    }
  }
  & > img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: contain;
    mix-blend-mode: darken;
  }
  .favorite-icon{
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
.zoom {
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
  pointer-events: none;
  & > img {
    pointer-events: all;
  }
}
.header {
  display: flex;
  img, svg {
    cursor: pointer;
  }
  & > a {
    /*min-height: 4em;*/
  }
  & > * {
    &:not(:last-child) {
      margin-right: 0.5em;
    }
    &:first-child {
      margin-right: auto;
      width: 12em;
    }
  }
}
.hardness-progress {
  width: 100%;
  background: linear-gradient(90deg, #a7bc96 0%, #c28b98 100%);
  border-radius: 20px;
  height: 7px;
  position: relative;
  margin-bottom: 8px;
  svg {
    position: absolute;
    top: 100%;
  }
}
.hardness-progress-tog {
  width: 100%;
  background: linear-gradient(90deg, #122fe2 0%, #fe2457 100%);
  border-radius: 20px;
  height: 7px;
  position: relative;
  margin-bottom: 20px;
  svg, .param-text {
    position: absolute;
    top: 100%;
  }
  .param-text {
    padding-top: 6px;
    margin-left: -15px;
  }
}
.options {
  margin-top: 1em;
  text-transform: uppercase;
  /*display: grid;*/
  grid-template-columns: 1.2fr 1.2fr;
  gap: 1em;
}
.param-text {
  font-size: 0.6875em;
  white-space: nowrap;
  letter-spacing: 0.05em;
}
.sizes {
  display: flex;
  flex-flow: wrap;
}
.size {
  font-size: 7px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 500;
  display: inline-flex;
  border: solid 1px;
  padding: 2px 3px;
  border-radius: 2px;
  margin-right: 2px;
  margin-bottom: 2px;
  position: relative;
  transition: 0.3s;
  &:hover {
    background: var(--color);
    color: #fff;
  }
}
.params {
  margin-top: 10px;
  /* grid-row: span 2; */
  display: grid;
  grid-template-columns: repeat(3,min-content);
  gap: 10px;
}
.params img{
  max-width: 18px;
  max-height: 18px;
}
.bottom {
  display: flex;
  align-items: center;
  margin-top: 1.6em;
  .link {
    font-weight: 600;
    text-decoration: underline;
    margin-right: auto;
  }
}
.price {
  font-weight: 600;
  &.minprice{
    font-size: 1.2em;
  }
  &.old {
    font-size: .875em;
    opacity: 0.4;
    margin-left: 0.5em;
    text-decoration: line-through;
  }
}
.param {
  position: relative;
  &:hover {
    .param-tooltip {
      opacity: 1;
      visibility: visible;
      transform: translate(0, 0);
    }
  }
}
.param-tooltip {
  position: absolute;
  left: -1em;
  top: calc(100%);
  width: 12.5em;
  background: #ffffff;
  box-shadow: 0px 14px 74px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transform: translate(0, 1em);
  transition: 0.3s;
  padding: 1em;
  &::before {
    content: '';
    position: absolute;
    left: 1em;
    top: -10px;
    border: 5px solid transparent;
    border-bottom: 5px solid #fff;
  }
  .param-desc {
    margin-top: 1em;
    font-size: 0.75em;
  }
}
@media screen and (min-width: 768px) {
  .params::-webkit-scrollbar {
    width: 12px; /* ширина всей полосы прокрутки */
  }
}
@media screen and (max-width: 767px) {
  .layout-product-card{
    /*max-width: 160px;*/
  }
  .options {
    display: block;
    & > * {
      &:not(:last-child) {
        margin-bottom: 1em;
      }
    }
  }
  .header {
    align-items: flex-start;
    & > * {
      &:first-child {
        margin-right: auto;
        width: 10em;
        position: relative;
        top: -0.2em;
        line-height: 100%;
      }
    }
  }
  .bottom {
    flex-flow: wrap;
    position: relative;
    .price {
      &.old {
/*        position: absolute;
        right: 0;
        bottom: 100%;*/
        font-size: 0.75em;
      }
    }
  }
  .sizes {
    margin-top: 0.5em;
    flex-flow: unset;
    overflow: scroll;
    margin-left: -15px;
    padding: 0 15px;
    margin-right: 0;
    // padding-left: 15px;
    // padding-right: 0px;
  }
  .size {
    font-size: 1em;
    margin-right: 0.5em;
    margin-bottom: 0.5em;
    border-radius: 5px;
    flex-shrink: 0;
  }
  .params {
    margin-left: -15px;
    padding: 0 15px;
    overflow-x: scroll;
    overflow-y: hidden;
    .param {
      font-size: 1.5em;
      flex-shrink: 0;
      /*margin-right: 2em;*/
    }
  }
}
</style>

<style lang="scss">
.layout-product-card {
  .chars {
    margin-top: 1em;
    & > * {
      & > * {
        display: flex;
        justify-content: space-between;
        padding: 0.3em 0;
        & > * {
          &:nth-child(1) {
            font-size: 0.75em;
            text-transform: uppercase;
            font-weight: 600;
            letter-spacing: 0.01em;
          }
          &:nth-child(2) {
            font-size: 0.75em;
            // text-transform: uppercase;
            // font-weight: 600;
            letter-spacing: 0.01em;
          }
        }
      }
    }
  }
  .collection {
    display: flex;
    margin-top: 0.3em;
    .collectionItem {
      margin-right: 5px;
      &.active {
        position: relative;
        &:before {
          content: "";
          width: 10px;
          border-bottom: 2px solid #000;
          position: absolute;
          bottom: 0;
          left: 6px;
          right: 0;
        }
      }
      span {
        width: 17px;
        height: 17px;
        border-radius: 50%;
        margin: 0.2em;
        display: inline-block;
      }
    }
  }
}
.hiddenDisplayNone{
  display: none;
  visibility: hidden;
  font-size: 0;
  position: absolute;
}
.reds{
  color: rgb(208, 55, 64);
}
.empty3d{
  display: flex;
  position: absolute;
  top: 10px;
  right: 35px;
}
@media screen and (max-width: 767px) {
  .collection {
    .collectionItem {
      margin-right: 5px;
    }
  }
}

</style>