import Vue from 'vue' // Импортируем Vue
import store from '@/store/index' // Импортируем Store
import Notifications from 'vue-notification'
import axios from '@/plugins/axios'
import PortalVue from 'portal-vue'
import bus from '@/plugins/bus'

import Autocomplete from 'element-ui/lib/autocomplete';

import vtooltip, { defaultOptions, state } from './directives/v-tooltip'
import vclosepopover from './directives/v-close-popover'
import Popover from './components/Popover.vue'
export { createTooltip, destroyTooltip } from './directives/v-tooltip'
Vue.directive('tooltip', vtooltip)
Vue.directive('close-popover', vclosepopover)
Vue.component('VPopover', Popover)
export const VTooltip = vtooltip
export const VClosePopover = vclosepopover
export const VPopover = Popover

import VueCookie from 'vue-cookie'
//import VTooltip from 'v-tooltip'
import Vuebar from 'vuebar';
import VueLazyload from 'vue-lazyload'

import Multiselect from 'vue-multiselect';
// register globally
Vue.component('multiselect', Multiselect);

// Подключаем глобально компоненты
import components from '@/plugins/components'
components(Vue)

//import 'viewerjs/dist/viewer.css'
require('@/assets/css/viewer.css')
import VueViewer from 'v-viewer'
Vue.use(VueViewer)

Vue.use(VueCookie)
Vue.use(PortalVue)
Vue.use(Notifications)
//Vue.use(VTooltip)
Vue.use(Vuebar)
Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: '/images/icons/krest-icon.svg',
    loading: '/images/icons/loading-spin.svg',
    attempt: 2
})
Vue.use(Autocomplete);
//тач
import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents)

import VAnimateCss from 'v-animate-css';
Vue.use(VAnimateCss);

Vue.config.productionTip = false // Отключаем предупреждение о режиме разработки
Vue.config.devtools = true // Отключаем дебагер

// Подключаем глобально стили
require('@/assets/css/animate.css')
// Подключаем Axios
Vue.prototype.$axios = axios

// Создаем event bus
Vue.prototype.$bus = bus

// Вшиваем в this Store
Vue.prototype.$store = store

//Vue.config.errorHandler = function(err, vm, info) {
//    console.log(`Error: ${err.toString()}\nInfo: ${info}`);
//}

//Vue.config.warnHandler = function(msg, vm, trace) {
//    console.log(`Warn: ${msg}\nTrace: ${trace}`);
//}


export default Vue
