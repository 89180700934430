<template>
    <div class="ui-auth">
        <a title="" v-if="authorized" :href="this.$store.state.langs.SITE_DIR+this.$store.state.langs.LINK_PERSONAL" class="hpersonal">
            <layout-header-button class="hpersonal-icon" icon="user.svg">
                {{ this.$store.state.langs.PERSONAL }}
            </layout-header-button>
        </a>
        <span v-else @click="open">
            <layout-header-button icon="user.svg">
              {{ this.$store.state.langs.SING_JOIN }}
            </layout-header-button>
        </span>
        <!--  -->
        <ui-auth-view :opened="opened"></ui-auth-view>
    </div>
</template>

<script>
import uiAuthView from '@/components/ui/ui-auth-view'
export default {
    components: {
        uiAuthView
    },
props: {
    authorized: {
        type: Boolean,
        default() {
            return false
        }
    }
},
  data() {
    return {
        opened: false
    }
  },
  methods: {
    open() {
      this.opened = true
      document.body.style.overflow = 'hidden'
    },
    close() {
      this.opened = false
      document.body.style.overflow = 'auto'
    },
  }
}
</script>

<style lang="scss" scoped>
.ui-auth {
    position: relative;
    display: inline-block;
}
</style>
