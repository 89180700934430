<template>
<!--    <svg @click="favorites()" width="21" height="18" xmlns="http://www.w3.org/2000/svg">
        <g class="layer">
            <path d="m9,16.9986c-0.25626,0 -0.50331,-0.0928 -0.69585,-0.2615c-0.72715,-0.6358 -1.42822,-1.2333 -2.04675,-1.7604l-0.00316,-0.0027c-1.81343,-1.5454 -3.37939,-2.88 -4.46896,-4.1946c-1.21797,-1.46972 -1.78528,-2.8632 -1.78528,-4.38549c0,-1.47904 0.50716,-2.84354 1.42795,-3.84234c0.93178,-1.0106 2.21031,-1.56719 3.60049,-1.56719c1.03903,0 1.99059,0.32849 2.82816,0.97627c0.4227,0.32698 0.80585,0.72716 1.1434,1.19394c0.33769,-0.46678 0.7207,-0.86696 1.1435,-1.19394c0.8376,-0.64778 1.7892,-0.97627 2.8282,-0.97627c1.39,0 2.6687,0.55659 3.6005,1.56719c0.9208,0.9988 1.4278,2.3633 1.4278,3.84234c0,1.52229 -0.5672,2.91577 -1.7851,4.38529c-1.0896,1.3148 -2.6554,2.6493 -4.4686,4.1945c-0.6196,0.5279 -1.3218,1.1264 -2.05059,1.7637c-0.1924,0.1684 -0.43959,0.2612 -0.69571,0.2612l0,0zm-3.97156,-14.95981c-1.09218,0 -2.0955,0.43588 -2.82541,1.22745c-0.74075,0.80351 -1.14875,1.91423 -1.14875,3.12767c0,1.28031 0.47584,2.42536 1.54275,3.71269c1.0312,1.2443 2.56503,2.5514 4.34097,4.0649l0.0033,0.0028c0.62086,0.5291 1.32468,1.129 2.05719,1.7695c0.73691,-0.6418 1.44181,-1.2426 2.06391,-1.7725c1.7758,-1.5135 3.3095,-2.8204 4.3407,-4.0647c1.0668,-1.28733 1.5426,-2.43238 1.5426,-3.71269c0,-1.21344 -0.408,-2.32416 -1.1487,-3.12767c-0.7298,-0.79157 -1.7333,-1.22745 -2.8253,-1.22745c-0.8001,0 -1.5347,0.25433 -2.1833,0.75586c-0.578,0.44714 -0.98063,1.01239 -1.2167,1.4079c-0.1214,0.20338 -0.33508,0.32478 -0.5717,0.32478c-0.23662,0 -0.4503,-0.1214 -0.5717,-0.32478c-0.23593,-0.39551 -0.63858,-0.96076 -1.21674,-1.4079c-0.6486,-0.50153 -1.38318,-0.75586 -2.18312,-0.75586l0,0z" :fill="[FAVORITE ? 'white' : 'black']" id="svg_1"/>
            <path d="m7.83871,7c-0.335398,-0.916215 -0.317498,-1.855952 -0.775828,-2.559484c-0.462213,-0.709491 -1.289814,-1.19504 -2.140291,-1.360322c-0.874117,-0.169877 -1.979631,0.026062 -2.405118,0.792118c-0.430118,0.774396 -0.067785,1.762694 -0.100441,2.650269c-0.032263,0.876883 0.282612,1.679068 0.802323,2.374194c0.51979,0.69523 1.073098,1.380074 1.590538,2.023914c0.548092,0.68198 1.034057,1.407038 1.786839,1.793806c0.783634,0.40262 1.654701,0.710354 2.151656,1.42329c0.467066,0.670055 0.612045,0.571631 1.088429,-0.131419c0.499527,-0.737205 1.051514,-1.406523 1.692216,-1.992517c0.647907,-0.592583 1.442952,-0.94809 2.007828,-1.600946c0.569202,-0.657857 1.093919,-1.367505 1.474408,-2.133849c0.382565,-0.770525 0.952916,-1.556808 0.90486,-2.344602c-0.050521,-0.82821 -0.238541,-1.78588 -0.872774,-2.288344c-0.657939,-0.521244 -1.6409,-0.42028 -2.524538,-0.452559c-0.889019,-0.032476 -1.858682,-0.019586 -2.382645,0.698409c-0.509821,0.698614 -0.713198,1.640383 -1.415742,2.086538c-0.724144,0.459872 -1.597075,0.29314 -2.381839,-0.091226c-0.762824,-0.373621 -1.846305,-0.956238 -2.37085,-0.478667c-0.65146,0.593121 0.495289,1.592402 1.053763,2.258064c0.561563,0.669343 1.284229,1.215628 1.638108,1.989764c0.35182,0.769632 1.007142,1.299193 1.824602,1.559871c0.854071,0.272352 1.500603,-0.198719 1.99071,-0.910065c0.499764,-0.725361 1.306353,-1.17956 1.842581,-1.871688c0.532565,-0.6874 1.02943,-1.441253 1.25772,-2.286161c0.224622,-0.831333 -0.600246,-1.420813 -1.478452,-1.20215c-0.831926,0.207139 -1.507294,0.889397 -1.741936,1.72043c-0.233662,0.82756 -0.86602,1.379754 -1.609548,1.804301c-0.760671,0.434337 -0.078452,0.878108 0.387698,1.603957l-0.530666,-0.01686l-0.312,-0.771774" fill="none" id="svg_6" :stroke="[FAVORITE ? '#000000' : '#ffffff']" stroke-width="3"/>
        </g>
    </svg>-->

  <svg @click="favorites()" title="favorite" width="21" height="21" viewBox="0 0 24 24" :fill="[FAVORITE ? '#000000' : '#ededed']" :stroke="[FAVORITE ? '#ededed' : '#000000']" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg>
</template>
<script>
    export default {
        props: {
            ID: '',
            IBLOCK_ID: ''
        },
        data(){
            return {
                FAVORITE: false,
                site_id: ""
            }
        },
        methods: {
            favorites() {
                this.FAVORITE = !this.FAVORITE;
                var cookieFavorite = this.$cookie.get('favorites');
                var favoriteJson = {};
                if( cookieFavorite ){
                    favoriteJson = JSON.parse(cookieFavorite);
                    if( this.FAVORITE ) { //add
                        if( !favoriteJson[this.site_id] ){
                            favoriteJson[this.site_id]={};
                            favoriteJson[this.site_id][this.IBLOCK_ID]=[];
                            favoriteJson[this.site_id][this.IBLOCK_ID].push(this.ID);
                        }
                        if( !favoriteJson[this.site_id][this.IBLOCK_ID] ){
                            favoriteJson[this.site_id][this.IBLOCK_ID]=[];
                            favoriteJson[this.site_id][this.IBLOCK_ID].push(this.ID);
                        }
                        var searchId = favoriteJson[this.site_id][this.IBLOCK_ID].find(el => el === this.ID);
                        if( !searchId ){
                            favoriteJson[this.site_id][this.IBLOCK_ID].push(this.ID);
                        }
                    }else{ // remove
                        favoriteJson[this.site_id][this.IBLOCK_ID] = favoriteJson[this.site_id][this.IBLOCK_ID].filter((n) => {return n != this.ID});
                    }
                }else{
                    favoriteJson[this.site_id]={};
                    favoriteJson[this.site_id][this.IBLOCK_ID]=[];
                    favoriteJson[this.site_id][this.IBLOCK_ID].push(this.ID);
                }
                this.$cookie.set('favorites', JSON.stringify(favoriteJson))
                var cuntFavorites = 0;
                for (var key in favoriteJson[this.site_id]) {
                    cuntFavorites = cuntFavorites + favoriteJson[this.site_id][key].length;
                }
                this.$bus.$emit("favorite", cuntFavorites);
            }
        },
        created(){
            this.site_id = this.$store.state.langs.SITE_ID;
            var cookieFavorite = this.$cookie.get('favorites');
            if( cookieFavorite ){
                var favoriteJson = JSON.parse(cookieFavorite);
                if(favoriteJson[this.site_id] && favoriteJson[this.site_id][this.IBLOCK_ID] && favoriteJson[this.site_id][this.IBLOCK_ID].find(el => el === this.ID)){
                    this.FAVORITE = true;
                }else{
                    this.FAVORITE = false;
                }
            }
        },
    }
</script>
<style lang="scss" scoped>
    svg{
        cursor: pointer;
    }
</style>