<template>
  <transition leave-active-class="animated fadeOut">
    <div v-if="opened" class="layout-city-choice-popup abs">
      <div class="overlay abs animated fadeIn"></div>
      <div class="window-wrapper">
        <div class="window animated fadeInUp" style="animation-delay: 0.3s;">
          <div class="close" @click="close">
            <img src="@/assets/icons/close.svg" alt="close" />
          </div>
          <div class="title small-title animated fadeInUp" style="animation-delay: 0.9s;">
            Ваш населенный пункт {{ city }}?
          </div>
          <div class="xs-text animated fadeInUp" style="animation-delay: 1.1s;">
            Если нет, введите верный виже.
          </div>
          <div class="inputs animated fadeInUp" style="animation-delay: 1.2s;">
            <input v-model="query" placeholder="Введите Ваш город" @input="fetch" />
            <div class="suggestions" :class="[{ active: suggestions.length }]">
              <div
                v-for="(suggestion, index) in suggestions"
                :key="index"
                class="suggestion"
                :class="[{ active: choicedCity === suggestion.data.city }]"
                @click="choice(suggestion)"
              >
                <span>{{ suggestion.value }}</span>
              </div>
            </div>
          </div>
          <div class="button-wrapper" :class="[{ active: choicedCity }]">
            <ui-button class="animated fadeInUp button" style="animation-delay: 1.5s;" @click="save">
              Сохранить
            </ui-button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    dadataKey: {
      type: String,
      default() {
        return ''
      }
    },
    city: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      opened: false,
      choicedCity: '',
      query: '',
      suggestions: []
    }
  },
  computed: {
    ui() {
      return this.$store.state.ui.ui
    }
  },
  mounted() {
    this.$bus.$on('city', data => {
      this.opened = true
      document.body.style.overflow = 'hidden'
    })
  },
  methods: {
    choice(suggestion) {
      this.choicedCity = suggestion.data.city
      this.query = suggestion.value
      this.suggestions = []
    },
    async fetch() {
      this.choicedCity ? (this.choicedCity = '') : ''
      const { suggestions } = (
        await this.$axios({
          url: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
          method: 'post',
          headers: {
            Authorization: 'Token ' + this.dadataKey,
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {
            query: this.query,
            count: 5,
            from_bound: { value: 'city' },
            to_bound: { value: 'city' }
          }
        })
      ).data
      this.suggestions = suggestions
    },
    close() {
      this.opened = false
      document.body.style.overflow = 'auto'
    },
    save() {
      this.$emit('update', this.choicedCity)
      //this.$cookie.set('vs-city', this.choicedCity)
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-city-choice-popup {
  position: fixed;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--window-padding);
}
.overlay {
  background: rgba(255, 255, 255, 0.96);
}
.icon {
  svg {
    width: 100px;
    height: 100px;
  }
}
.window {
  width: 31.25em;
  height: 45em;
  max-height: 100%;
  position: relative;
  background: none;
  border-radius: 10px;
  text-align: center;
  padding: 4em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  --icon-color: currentColor;
}
.window-wrapper {
  transition: 0.5s;
  &.fly {
    transform: translate3d(0, 100vh, 0);
  }
}
.title {
  margin-top: 2em;
  margin-bottom: 0.5em;
  font-size: 1.875em;
  font-weight: 700;
  text-transform: uppercase;
}
.inputs {
  margin-top: 3em;
  position: relative;
  z-index: 10;
}
.suggestions {
  position: absolute;
  width: 100%;
  background: rgba(0, 0, 0, 0.01);
  border-radius: 10px;
  overflow: hidden;
  & > * {
    padding: 0.5em;
    cursor: pointer;
    transition: 0.4s;
    &:hover {
      background: var(--main);
      color: #fff;
    }
    &:not(:last-child) {
      border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    }
  }
}
.ui-input {
  background: none;
  color: #fff;
}
.button-wrapper {
  transform: translate(0, 2em);
  visibility: hidden;
  opacity: 0;
  transition: 1s;
  margin-top: 3em;
  &.active {
    opacity: 1;
    visibility: visible;
    transform: none;
  }
}
.ui-input {
  text-align: center;
  margin-bottom: 1.5em;
}
.close {
  position: absolute;
  right: 1.5em;
  top: 1.5em;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    transform: rotate(90deg);
  }
  svg {
    width: 1em;
    height: 1em;
    --icon-color: var(--secondary);
  }
}
.before-send {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.after-send {
  margin: auto;
  .ui-button {
    margin-top: 5em;
  }
}
@media screen and (max-width: 767px) {
  .window {
    width: auto;
  }
}
</style>
