<template>
  <div class="section-products-carousel-simple">
    <div class="container">
      <div class="wrapper">
        <div class="header">
          <div>
            <div class="middle-title"><slot name="title"></slot></div>
            <div class="subtitle"><slot name="subtitle"></slot></div>
          </div>
          <ui-arrows @prev="$refs.slider.prev()" @next="$refs.slider.next()"></ui-arrows>
        </div>
        <div class="items">
          <layout-own-carousel ref="slider" xs-count="2" :count="count" gap="2em">
            <slot></slot>
          </layout-own-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import layoutOwnCarousel from '@/components/layout/layout-own-carousel'
    import uiArrows from '@/components/ui/ui-arrows'
    export default {
        components: {
            layoutOwnCarousel,
            uiArrows
        },
        props: {
            count: {
                type: Number,
                default() {
                    return 3
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
  .section-products-carousel-simple {
    padding-bottom: 5em;
  }
  .header {
    display: flex;
    justify-content: space-between;
  }
  .items {
    margin-top: 2em;
  }
</style>
