<template>
  <!-- <div v-if="!noMenu" class="page-layout-menu"> -->
  <div class="page-layout-menu">
    <div class="container">
      <div class="menu">
        <div class="menu-button" @click="openGlobalMenu()">
          <img src="@/assets/icons/menu-button.svg" alt="menu" :width="20" :height="15" />
        </div>

        <div class="menu-item" v-if="view(items)" v-for="(items, index) in menu" :key="index">
          <a v-if="items.UF_LINK" :title="items.NAME" :href="sitedir+items.UF_LINK">{{ items.NAME }}</a>
          <span v-else :title="items.NAME">{{ items.NAME }}</span>
          <div v-if="items.SUB && items.SUB.length>0" class="submenu">
            <div class="container">
              <div v-if="view(item)" v-for="(item, indexSub) in items.SUB" :key="indexSub">
                <div class="parentSubmenu" @click="clickMenuParent(item.UF_LINK)">{{ item.NAME }}</div>
                <div class="items columns">
                  <template v-for="(element, elemenyIndex) in item.ELEMENT" :key="elemenyIndex">
                    <a v-if="element.PROPERTY_LINK_VALUE" :title="element.NAME" :href="sitedir+element.PROPERTY_LINK_VALUE">{{ element.NAME }}</a>
                    <span v-else :title="element.NAME" >{{ element.NAME }}</span>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            sitedir:'',
            menu: {}
        }
    },
  created(){
      this.$bus.$on('top-menus', (data) => {
          this.menu = data;
      });
      //this.menuTop();
      //this.position()
      //window.addEventListener('resize', this.position());
  },
  mounted() {
    this.sitedir = this.$store.state.langs.SITE_DIR;
    setTimeout(() => {
      //this.position()
    }, 1000)
    window.onresize = () => {
      //this.position()
    }
  },
  methods: {
      /*async menuTop() {
          const responseMenu  = (
              await this.$axios({
                  url: '/ajax/menu.php',
                  method: 'post',
                  params: {
                      clear_cache: this.getGet('clear_cache'),
                      SITE_ID: this.$store.state.langs.SITE_ID
                  }
              })
          )
          if (responseMenu) {
              this.menu = responseMenu.data
          } else {
              console.log('menuTop')
          }
      },*/
    openGlobalMenu(){
        setTimeout(() => {
            this.$bus.$emit('global-menu')
        }, 500)
    },
    position() {
      const submenus = this.$el.querySelectorAll('.submenu')
      for (const submenu of submenus) {
        submenu.style.left = 'auto'
      }
      for (const submenu of submenus) {
        //console.dir(submenu)
        const coords = submenu.getBoundingClientRect()
        submenu.style.left = `${(Math.round(coords.x) - 20 + 4) * -1}px`
      }
    },
    getGet(name) {
        var searchString = new URLSearchParams(window.location.search);
        var res = searchString.get(name);
        return res;
    },
    clickMenuParent(url){
          if( url.length > 1 ){
              location.href = this.sitedir+url;
          }
    },
    view(items){
        return ( Number(items.UF_ADMIN)<1 ) || ( Number(items.UF_ADMIN) && this.$store.state.admin );
    }
  },
  computed : {
      noMenu() {
          return this.$parent.noMenu
      }
  }
}
</script>

<style lang="scss" scoped>
.page-layout-menu {
  margin-bottom: 1em;
  position: relative;
}
.parentSubmenu{
  cursor:pointer;
}
.menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  & > * {
    padding: 1em;
    &:hover {
      background: #000;
      color: #fff;
      .submenu {
        display: block;
        /*opacity: 1;
        visibility: visible;*/
      }
    }
    & > a {
      text-transform: uppercase;
      letter-spacing: 0.05em;
      transition: 0.4s;
      position: relative;
      &:hover {
        text-decoration: underline;
/*        & ~ .submenu {
          opacity: 1;
          visibility: visible;
        }*/
      }
    }
    & > span {
      text-transform: uppercase;
      letter-spacing: 0.05em;
      transition: 0.4s;
      position: relative;
      &:hover {
        text-decoration: underline;
        /*        & ~ .submenu {
                  opacity: 1;
                  visibility: visible;
                }*/
      }
    }
  }
  .menu-item{
    height: 53px;
    padding: 0 1em;
    align-items: center;
    vertical-align: middle;
    display: flex;
  }
}
.submenu {
  position: absolute;
  top: 100%;
  left: 0;
  background: #000;
  color: #fff;
  /*width: 100vw;*/
  width: 100%;
  display: none;
  /*opacity: 0;
  visibility: hidden;*/
  box-shadow: 0px 74px 64px rgba(0, 0, 0, 0.25);
  padding: 0 50px;
  & > .container {
    padding: 2.5em 0;
    display: flex;
    & > * {
      flex-grow: 1;
      // column
      & > :nth-child(1) {
        font-size: 0.9375em;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        margin-bottom: 1.5em;
      }
      .items {
        &.columns {
          display: grid;
          grid-template-rows: repeat(10, 1fr);
          grid-auto-flow: column;
        }
        & > * {
          display: block;
          font-size: 0.9375em;
          margin-bottom: 0.5em;
          opacity: 0.5;
          transition: 0.5s;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}
.menu-button {
  cursor: pointer;
  position: relative;
  top: 0.15em;
  transition: 0.5s;
  &:hover {
    transform: scale(1.02);
  }
}
@media screen and (max-width: 767px) {
  .page-layout-menu {
    display: none;
  }
}
</style>
