<template>
  <div class="layout-header-personal">
        <div class="left align-left">

            <div class="menu">
                <div class="menu-button">
                    <img v-if="width < 768" src="@/assets/icons/menu-button.svg" alt="menu" @click="openLeft()" :width="20" :height="15" />
                </div>
            </div>

          <div class="notyfication-block">
            <div class="icon-notyfication">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.2739 16.8692L15.8431 14.4842C15.1823 13.3834 14.8331 12.1225 14.8331 10.8392V8.75C14.8331 6.1125 13.0731 3.88168 10.6664 3.1625V1.66668C10.6664 0.7475 9.9189 0 8.99972 0C8.08054 0 7.33304 0.7475 7.33304 1.66668V3.1625C4.9264 3.88168 3.1664 6.1125 3.1664 8.75V10.8392C3.1664 12.1225 2.81722 13.3825 2.15722 14.4834L0.7264 16.8684C0.6489 16.9975 0.64722 17.1575 0.7214 17.2884C0.795579 17.4192 0.933079 17.5 1.08308 17.5H16.9164C17.0664 17.5 17.2047 17.4192 17.2789 17.2892C17.3531 17.1592 17.3506 16.9975 17.2739 16.8692Z" fill="#262429"/>
                </svg>
            </div>

            <div class="text-notyfication">Notifications</div>
          </div>
        </div>
        <div class="right align-right">
            <span v-if="width > 767" class="link_compare">
                <layout-header-button type="compare" :href="sitedir+this.$store.state.langs.LINK_COMPARE" :count="countcompare" icon="compare.svg">
                  {{ this.$store.state.langs.COMPARE }}
                </layout-header-button>
            </span>
            <span class="link_favorite">
                <layout-header-button type="favorite" :href="sitedir+this.$store.state.langs.LINK_FAVORITE" :count="countfavorite" icon="heart.svg">
                  {{ this.$store.state.langs.FAVORITES }}
                </layout-header-button>
            </span>
            <span class="linkButtonBasket">
                <layout-header-button type="smallbasket" :href="sitedir+this.$store.state.langs.LINK_CART" :count="countProduct" icon="bag.svg">
                  {{ this.$store.state.langs.CART }}
                </layout-header-button>
            </span>
        </div>

  </div>
</template>

<script>
import uiAuth from '@/components/ui/ui-auth'
export default {
  components: {
    uiAuth
  },
  props: {
      authorized: {
          type: Boolean,
          default() {
              return false
          }
      },
      sitedir: {
        type: String,
        default() {
            return "/"
        }
      },
      siteid: {
        type: String,
        default() {
            return "s1"
        }
      },
  },
  data() {
    return {
        width: window.innerWidth,
        countProduct: 0,
        countfavorite: 0,
        countcompare: 0,
    }
  },
  created(){
      this.baskekeCount();
      var cookieFavorite = this.$cookie.get('favorites');
      if( cookieFavorite ){
          var favoriteJson = JSON.parse(cookieFavorite);
          if( favoriteJson[this.siteid] ){
              for (var key in favoriteJson[this.siteid]) {
                  this.countfavorite = this.countfavorite + favoriteJson[this.siteid][key].length;
              }
          }
      }
      var cookieCompare = this.$cookie.get('compare');
      if( cookieCompare ){
          var compareJson = JSON.parse(cookieCompare);
          if( compareJson[this.siteid] ){
              for (var key in compareJson[this.siteid]) {
                  this.countcompare = this.countcompare + compareJson[this.siteid][key].length;
              }
          }
      }
      window.addEventListener('resize', this.updateWidth);
  },
  mounted() {},
  methods: {
      async baskekeCount() {
          const  countBasketProducts  = (
                  await this.$axios({
                      url: '/ajax/basketline.php',
                      method: 'post',
                      params: {
                          SITE_ID: this.$store.state.langs.SITE_ID
                      }
                  })
              )
              if (countBasketProducts) {
                  this.countProduct = countBasketProducts.data.count
              } else {
                  console.log('countBasketProducts')
              }
      },
      updateWidth() {
          this.width = window.innerWidth;
      },
      openLeft(){
          this.$parent.beakLeft();
      }
  }
}
</script>

<style lang="scss" scoped>
.layout-header-personal {
  padding: 2em 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  .menu-button {
      width: 25px;
      height: 25px;
      display: flex;
  }
}
.layout-header-personal .notyfication-block{
    display: flex;
    align-items: center;
}
.layout-header-personal .notyfication-block .text-notyfication{
    padding: 0 10px;
    text-transform: uppercase;
}
.left {
    display: flex;
    & > * {
        &:not(:last-child) {
          margin-right: 1.5em;
        }
    }
}
.right {
    white-space: nowrap;
    & > * {
        &:not(:first-child) {
          margin-left: 1.5em;
        }
    }
}
@media screen and (max-width: 767px) {

}
</style>
