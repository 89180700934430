<template>
  <div class="layout-product-details-tabs">
    <div class="tabs">
      <div
              v-for="(tab, index) in tabs"
              :key="index"
              :class="[{ active: index === current }]"
              class="tab"
              @click="current=index"
      >
        <div class="subtitle">{{ tab.name }}</div>
      </div>
    </div>
    <div class="contents">
      <div v-for="(tab, index) in tabs" v-show="index === current" :key="index" class="content" :class="[tab.id]">
        <slot :name="tab.id" :key="ind"></slot>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        data() {
            return {
                current: 0,
                tabs: [
                    {
                      id: this.$store.state.langs.TABS_DETAIL_ID_FEATURES,
                      name: this.$store.state.langs.TABS_HEAD_FEATURES
                    },
                    {
                        id: this.$store.state.langs.TABS_DETAIL_ID_SIZE,
                        name: this.$store.state.langs.TABS_HEAD_SIZES
                    },
                    {
                        id: this.$store.state.langs.TABS_DETAIL_ID_TESTIMONIALS,
                        name: this.$store.state.langs.TABS_HEAD_TESTIMONIALS
                    },
                    /*
                    {
                      id: 'showrooms',
                      name: 'Showrooms'
                    },
                    */
                    /*
                    ,
                    {
                      id: 'warranty',
                      name: 'Warranty&Care'
                    }
                    */
                ]
            }
        },
        methods: {
            requestContent(index){
                this.current=index;
                var classContent = this.tabs[index].id;
                var contentBlock = document.querySelector("."+classContent+" .data-tabs");
                if( contentBlock ) {
                    var contentBlockLoader = contentBlock.querySelector(".loading-item");
                    if( contentBlockLoader ){
                        var url = contentBlock.dataset.url;
                        var productId = contentBlock.dataset.id;

                        this.$axios({
                            url: url,
                            method: 'post',
                            params: {id:productId,testimonials:true}
                        }).then(response => {
                            if (response.status == 200) {
                                contentBlock.innerHTML=response.data;
                            }
                        })
                        this.$forceUpdate();
                        this.ind = 1;
                    }
                }
            }
        },
        created(){
            this.$bus.$on('tabs', (tabsCurrent) => {
                this.current = tabsCurrent
            })
        }
    }
</script>

<style lang="scss" scoped>
  .layout-product-details-tabs {
  }
  .tabs {
    display: flex;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    & > * {
      flex-grow: 1;
      border-bottom: solid 1px;
      text-align: center;
      padding: 0.5em 0;
      border-color: transparent;
      cursor: pointer;
      &.active {
        font-weight: 600;
        border-color: currentColor;
      }
    }
  }
  .contents {
    margin-top: 2em;
  }
</style>
