<template>
  <div class="ui-info-right-block">
    <div class="visible" @click="opened=true">
      <div class="">
        <div class="xx-title Tabby"> Pay in 4 with Tabby. No interest, no fees.
          <div class="styles__item-text--5063b styles__item-text_flex--4f618">
            <svg viewBox="0 0 100 40" fill="none" xmlns="http://www.w3.org/2000/svg" class="styles__tabby-info__logo--ec151 styles__logo--0ef56">
              <path d="M92.2524 0H7.74757C3.46871 0 0 3.48347 0 7.78054V32.2195C0 36.5165 3.46871 40 7.74757 40H92.2524C96.5313 40 100 36.5165 100 32.2195V7.78054C100 3.48347 96.5313 0 92.2524 0Z" fill="url(#paint0_linear_99_113_0.06742761728975522)"></path>
              <path d="M86.9113 12.6893L81.5394 33.1972L81.5274 33.2434H85.7106L91.1016 12.6893H86.9113Z" fill="#292929"></path>
              <path d="M20.4447 24.3575C19.817 24.6651 19.1268 24.8233 18.4279 24.8198C16.9206 24.8198 16.0655 24.5796 15.9731 23.3607V23.2793C15.9731 23.2301 15.967 23.1788 15.967 23.1306V19.5946L15.9731 19.1765V16.6805H15.967V15.6073L15.9731 15.1863V12.7746L12.237 13.269C14.7673 12.7787 16.2173 10.778 16.2173 8.7864V7.56049H12.015V13.3032L11.7778 13.3695V24.0018C11.9165 26.9892 13.888 28.7648 17.1146 28.7648C18.2708 28.762 19.4146 28.5251 20.4768 28.0684L20.4979 28.0594V24.3294L20.4447 24.3575Z" fill="#292929"></path>
              <path d="M21.1069 11.9165L9.32401 13.7332V16.7216L21.1069 14.9049V11.9165Z" fill="#292929"></path>
              <path d="M21.1069 16.2876L9.32401 18.1044V20.9582L21.1069 19.1424V16.2876Z" fill="#292929"></path>
              <path d="M34.3287 17.6622C34.1619 14.3462 32.0878 12.3767 28.7075 12.3767C26.7652 12.3767 25.1634 13.1273 24.0762 14.5481C22.9889 15.969 22.4202 18.046 22.4202 20.5702C22.4202 23.0944 22.993 25.1784 24.0762 26.5993C25.1594 28.0201 26.7652 28.7698 28.7075 28.7698C32.0878 28.7698 34.1619 26.7932 34.3287 23.4561V28.4573H38.5269V12.7264L34.3287 13.3735V17.6622ZM34.5467 20.5702C34.5467 23.5104 33.0033 25.4095 30.6147 25.4095C28.1519 25.4095 26.6818 23.6008 26.6818 20.5702C26.6818 17.5215 28.1519 15.7007 30.6147 15.7007C31.8125 15.7007 32.8133 16.167 33.5077 17.0502C34.188 17.9134 34.5467 19.1313 34.5467 20.5702Z" fill="#292929"></path>
              <path d="M50.773 12.3767C47.3917 12.3767 45.3177 14.3452 45.1529 17.6673V8.14732L40.9536 8.79545V28.4523H45.1529V23.4451C45.3177 26.7863 47.3917 28.7648 50.773 28.7648C54.7301 28.7648 57.0925 25.702 57.0925 20.5703C57.0925 15.4385 54.7301 12.3767 50.773 12.3767ZM48.8638 25.4096C46.4743 25.4096 44.9308 23.5105 44.9308 20.5703C44.9308 19.1313 45.2905 17.9134 45.9708 17.0533C46.6652 16.17 47.666 15.7038 48.8638 15.7038C51.3267 15.7038 52.7968 17.5246 52.7968 20.5733C52.7998 23.6009 51.3297 25.4096 48.8668 25.4096H48.8638Z" fill="#292929"></path>
              <path d="M68.5306 12.3767C65.1493 12.3767 63.0743 14.3452 62.9095 17.6673V8.14732L58.7113 8.79545V28.4523H62.9035V23.4451C63.0683 26.7863 65.1433 28.7648 68.5246 28.7648C72.4807 28.7648 74.8431 25.702 74.8431 20.5703C74.8431 15.4385 72.4817 12.3767 68.5306 12.3767ZM66.6214 25.4096C64.2319 25.4096 62.6885 23.5105 62.6885 20.5703C62.6885 19.1313 63.0472 17.9134 63.7275 17.0533C64.4228 16.17 65.4237 15.7038 66.6214 15.7038C69.0833 15.7038 70.5534 17.5246 70.5534 20.5733C70.5514 23.6009 69.0813 25.4096 66.6214 25.4096Z" fill="#292929"></path>
              <path d="M74.8531 12.6893H79.3368L82.9794 28.4232H78.96L74.8531 12.6893Z" fill="#292929"></path>
              <defs><linearGradient id="paint0_linear_99_113_0.06742761728975522" x1="0" y1="19.9995" x2="100" y2="19.9995" gradientUnits="userSpaceOnUse">
                <stop stop-color="#3BFF9D"></stop>
                <stop offset="1" stop-color="#3BFFC8"></stop></linearGradient>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    </div>

    <layout-info-content-window-tabby
            v-model="opened"
            :title="this.$store.state.langs.INFO_TITLE_WINDOW"
            :total="total"
    ></layout-info-content-window-tabby>

  </div>
</template>

<script>
import vuescroll from 'vuescroll/dist/vuescroll-native'
import layoutInfoContentWindowTabby from '@/components/layout/layout-info-content-window-tabby'
export default {
    components: {
        vuescroll,
      layoutInfoContentWindowTabby
    },
    props: {
      total: 0
    },
    data() {
        return {
          opened: false,
          ops: {
            vuescroll: {},
            scrollPanel: {},
            rail: {
              background: '#000',
              opacity: 0.1,
              size: '3px',
              specifyBorderRadius: '0px'
            },
            bar: {
              background: '#000',
              opacity: 1,
              size: '3px'
            }
          },
        }
    },
    methods: {
      formatPriceKlarna(price) {
        return Math.ceil(Number(price)/3).toLocaleString();
      },
    }
}
</script>

<style lang="scss" scoped>
.ui-info-right-block {
    margin-top: 0.5em;
    margin-bottom: 2em;
}
.visible {
    display: flex;
    align-items: center;
    height: 50px;
    justify-content: space-between;
    padding: 0 10px 0 0;
    cursor: pointer;
}
.xxx-title{
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    /* width: 100px; */
    height: 25px;
}
.xx-title{
  text-decoration: underline;
}
.b-next{
    font-size: 23px;
    font-weight: bold;
}
.xx-title.Tabby{
  display: flex;
  align-items: end;
}
.styles__item-text--5063b.styles__item-text_flex--4f618{
  align-items: center;
  font-size: 14px;
  margin: 0 5px;
  display: inline-block;
  width: 39px;
  height: 18px;
}
</style>
