<template>
  <div class="language ui-lang-choice">
    <div class="current-lang lang" @click="toggleLandList">
      <div class="icon">
        <img :src="require(`@/assets/img/${currentLang.icon}`)" alt="currentLang.name" />
      </div>
      <div v-if="!titleshow" class="xxs-title">{{ currentLang.name }}</div>
      <svg v-if="!titleshow" width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.5 4.5L4.66506 0.75H0.334936L2.5 4.5Z" fill="black" />
      </svg>
    </div>
    <!--  -->
    <div class="language-list" :class="[{ hidden: !langListOpened }]">
      <div
        v-for="(lang, index) in languages"
        :key="index"
        :class="[{ active: langIndex === index }]"
        class="lang"
        @click="choiceLang(index, lang.url)"
      >
        <div class="icon">
          <img :src="require(`@/assets/img/${lang.icon}`)" :alt="lang.icon" />
        </div>
        <div class="xxs-title">{{ lang.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
      titleshow: false
  },
  data() {
    return {
      langListOpened: false
    }
  },
  computed: {
    langIndex() {
      //return this.$store.state.lang
        var langs = this.$store.state.languages;
        var dir = this.$store.state.langs.SITE_DIR + "/";
        return langs.findIndex(el => el.url === dir);
    },
    currentLang() {
      return this.languages[this.langIndex]
    },
    languages() {
      return this.$store.state.languages
    }
  },
  methods: {
    toggleLandList() {
      this.langListOpened = !this.langListOpened
    },
    choiceLang(index,url) {

      location.href = url;

/*
      let newUrl = '';
      let arPath = document.location.pathname.split("/").filter(el => el.length > 0);

      // убираем код страны из url
      if (arPath.length > 1 && (url == "/" || arPath[0].length == 2)) {
        arPath.shift();
      }

      newUrl = (url != "/") ? url + arPath.join('/') : "/" + arPath.join('/');

      this.$store.state.lang = index
      this.toggleLandList()

      this.$axios({
        url: newUrl,
        method: 'post' 
      }).then(response => {

        if (response.status == 200) {
          location.href = newUrl;
        }

      }).catch((err) => {
        
        let axios = this.$axios;
        let response = function(url) {
          
          let result = null;

          axios({
            url: url,
            method: 'post',
          }).then(response => {

            if (response.status == 200) {
              result = response.status;
            }

          }).catch((err) => {});

          return result;
        };

        let status = null;

        while (status != 200) {

          let arUrl = newUrl.split("/").filter(el => el.length > 0);
          arUrl.pop();

          if (arUrl.length > 0) {
            newUrl = "/" + arUrl.join("/");
          } else {
            break;
          }
          
          status = response(newUrl);

        }

        location.href = (status == 200) ? newUrl : "/";
        
      });
*/
    }
  }
}
</script>

<style lang="scss" scoped>
.language {
  position: relative;
}
.lang {
  display: flex;
  align-items: center;
  height: 1.5em;
  cursor: pointer;
  transition: 0.3s;
  svg {
    margin-left: 0.5em;
  }
  .icon {
    width: 19px;
    height: 13px;
    position: relative;
    margin-right: 6px;
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.language-list {
  padding: 0.625em;
  position: absolute;
  top: calc(100% + 1em);
  z-index: 10;
  width: 13.75em;
  left: 50%;
  transform: translate(-50%, 0);
  background: #fff;
  box-shadow: 0px 17px 54px rgba(0, 0, 0, 0.17);
  border-radius: 6px;
  transition: 0.3s;
  &.hidden {
    opacity: 0;
    transform: translate(-50%, 2em);
    visibility: hidden;
  }
  .lang {
    background: #f9f9f9;
    border-radius: 2px;
    justify-content: center;
    padding: 1.2em;
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
    &:not(:last-child) {
      margin-bottom: 0.5em;
    }
    &.active {
      background: var(--color);
      color: #fff;
    }
  }
}
@media screen and (max-width: 767px) {
  .language-list {
    transform: translate(0, 0);
    left: auto;
    right: 0;
    &.hidden {
      transform: translate(0%, 2em);
    }
  }
}
</style>
